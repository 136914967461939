import { useRef, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { BiEditAlt } from "react-icons/bi";
import Card from "../Common/Card";
import Modal from "../Modal/Modal";
import Tooltip from "../Tooltip/Tooltip";
import { shortenAddress } from "lib/legacy";
import EmptyMessage from "./EmptyMessage";
import InfoCard from "./InfoCard";
import { getTierIdDisplay, getUSDValue, tierDiscountInfo } from "./referralsHelper";
import { ReferralCodeForm } from "./JoinReferralCode";
import { getExplorerUrl } from "config/chains";
import { formatAmount } from "lib/numbers";
import { getNativeToken, getToken } from "config/tokens";
import { formatDate } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useMedia } from "react-use";

function TradersStats({ referralsData, traderTier, chainId, userReferralCodeString, setPendingTxns, pendingTxns }) {
  const isMobile = useMedia("(max-width: 600px)");

  return (
    <div className="rebate-container">
      <div className="referral-stats">
        <InfoCard
          label={`Total Trading Volume`}
          tooltipText={`Volume traded by this account with an active referral code.`}
          data={getUSDValue(referralsData?.referralTotalStats?.volume)}
        />
        <InfoCard
          label={`Total Rebates`}
          tooltipText={`Rebates earned by this account as a trader.`}
          data={getUSDValue(referralsData?.referralTotalStats?.discountUsd, 4)}
        />
      </div>

      {!isMobile ? (
        <>
          {referralsData?.discountDistributions.length > 0 ? (
            <div className="reward-history">
              <div className="title">Rebate Distribution History</div>

              <div className="header">
                <div className="child">Day</div>
                <div className="child">Amount</div>
                <div className="child">Transaction</div>
              </div>

              {referralsData?.discountDistributions.map((rebate, index) => {
                let tokenInfo;
                try {
                  tokenInfo = getToken(chainId, rebate.token);
                } catch {
                  tokenInfo = getNativeToken(chainId);
                }
                const explorerURL = getExplorerUrl(chainId);
                return (
                  <div className="content">
                    <div className="child">{formatDate(rebate.timestamp)}</div>
                    <div className="child">
                      {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {tokenInfo.symbol}
                    </div>
                    <div className="child Transaction">
                      <ExternalLink href={explorerURL + `tx/${rebate.transactionHash}`}>
                        {shortenAddress(rebate.transactionHash, 20)}
                      </ExternalLink>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="reward-history">
              <div className="title">Rebate Distribution History</div>

              <EmptyMessage message={`No Rebate Distribution History yet.`} tooltipText={``} />
            </div>
          )}
        </>
      ) : (
        <>
          {referralsData?.discountDistributions.length > 0 ? (
            <div className="reward-history">
              <div className="title">Rebate Distribution History</div>

              {referralsData?.discountDistributions.map((rebate, index) => {
                let tokenInfo;
                try {
                  tokenInfo = getToken(chainId, rebate.token);
                } catch {
                  tokenInfo = getNativeToken(chainId);
                }
                const explorerURL = getExplorerUrl(chainId);
                return (
                  <div className="content mobile">
                    <div className="mobile-wrapper">
                      <div className="mobile-title">Day</div>
                      <div className="mobile-value">{formatDate(rebate.timestamp)}</div>
                    </div>

                    <div className="mobile-wrapper">
                      <div className="mobile-title">Amount</div>
                      <div className="mobile-value">
                        {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {tokenInfo.symbol}
                      </div>
                    </div>

                    <div className="mobile-wrapper">
                      <div className="mobile-title">Transaction</div>
                      <div className="mobile-value">
                        <ExternalLink href={explorerURL + `tx/${rebate.transactionHash}`}>
                          {shortenAddress(rebate.transactionHash, 20)}
                        </ExternalLink>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="reward-history">
              <div className="title">Rebate Distribution History</div>

              <EmptyMessage message={`No Rebate Distribution History yet.`} tooltipText={``} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TradersStats;
