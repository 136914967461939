import useSWR from "swr";
import axios from "axios";
import { isObject } from "lodash";
import styled from "styled-components";

import btcIcon from "img/ic_btc_40.svg";
import ethIcon from "img/ic_eth_40.svg";
import ftmIcon from "img/ic_ftm_40.svg";
import opIcon from "img/ic_op_40.svg";
import fxsIcon from "img/ic_fxs_32.svg";
import linkIcon from "img/ic_link_32.svg";
import snxIcon from "img/ic_snx_32.svg";
import bitFi from "img/icons/ic_bitfinex.svg";
import binance from "img/icons/ic_binance.svg";
import kucoin from "img/icons/ic_kucoin.svg";
import bnbIcon from "img/icons/ic_binance.svg";
import arbIcon from "img/ic_arb_24.svg";
import gmxIcon from "img/ic_gmx_24.svg";
import uniIcon from "img/ic_uni_24.svg";
import rdntIcon from "img/ic_rdnt_24.svg";

import coinbase from "img/icons/ic_coinbase.svg";
import crypto from "img/icons/ic_crypto.svg";
import okx from "img/icons/ic_okx.svg";
import buybit from "img/icons/ic_bybit.svg";
import median from "img/icons/ic_median.svg";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import FlashValue from "components/FlashValue";
import moment from "moment";
import { API_PATH, getApiAnalytics } from "../config";
import { ARBITRUM } from "config/chains";
const TableHeaders = [
  { name: "Bitfinex", icon: bitFi },
  { name: "Binance", icon: binance },
  { name: "KuCoin", icon: kucoin },
  { name: "Coinbase", icon: coinbase },
  { name: "Crypto.com", icon: crypto },
  { name: "OKX", icon: okx },
  { name: "Bybit", icon: buybit },
  { name: "MedianPrice", icon: median },
];

const TokenIcons = [
  { tokenName: "BTC", icon: btcIcon },
  { tokenName: "ETH", icon: ethIcon },
  { tokenName: "FTM", icon: ftmIcon },
  { tokenName: "OP", icon: opIcon },
  { tokenName: "FXS", icon: fxsIcon },
  { tokenName: "LINK", icon: linkIcon },
  { tokenName: "SNX", icon: snxIcon },
  { tokenName: "BNB", icon: bnbIcon },
  { tokenName: "ARB", icon: arbIcon },
  { tokenName: "GMX", icon: gmxIcon },
  { tokenName: "UNI", icon: uniIcon },
  { tokenName: "RDNT", icon: rdntIcon },
];



const fetcher = (url) => fetch(url).then((r) => r.json());
const SOURCES = ["bitfinex", "binance", "kucoin", "coinbase", "cryptocom", "okx", "bybit"];

export default function Oracle({ chainId }) {
  const [prices, setPrices] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(+new Date());
  const { data } = useSWR(`${getApiAnalytics(chainId)}api/price-sources`, fetcher, {
    refreshInterval: 1000,
  });
  const HIDE_TOKEN = chainId === ARBITRUM ? ["FXS", "CRV", "LINK", "UNI"] : ["FXS", "CRV"]
  useEffect(() => {
    setLastUpdated(+new Date());
    if (isObject(data)) {
      let arr = [];
      Object.values(data)
        .filter((item) => !HIDE_TOKEN.includes(item.tokenName))
        .forEach((item) => {
          arr.push({
            tokenName: item.tokenName,
            sources: SOURCES.map((src) => {
              return { name: src, info: item.sources[src] || { updated: +new Date(), price: null } };
            }),
            medianPrice: item.medianPrice,
          });
        });
      setPrices(arr);
    }
  }, [data]);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <Wrapper>
      {!isSmallScreen ? (
        <div className="table-oracle">
          <div className="oracle-header">
            <div className="oracle-header-content"></div>
            {TableHeaders.map((item) => (
              <div className="oracle-header-content" key={item.name}>
                <img alt="" src={item?.icon} />
                <div className="header-name">{item.name}</div>
              </div>
            ))}
          </div>
          {prices.length > 0 &&
            prices.map((price) => (
              <div className="price-content-container">
                <div className="price-content" style={{ maxWidth: "calc(100% / 8)" }}>
                  <img alt="" src={TokenIcons.find((token) => token.tokenName === price.tokenName)?.icon} />{" "}
                  <div className="token-info">
                    <div className="name">{price.tokenName}</div>
                    <div className="timestamp">Updated {moment(Number(lastUpdated || "")).fromNow()}</div>
                  </div>
                </div>
                {price.sources.length > 0 &&
                  price.sources.map((source) => {
                    return (
                      <FlashValue
                        decimal={price.tokenName === "FTM" ? 4 : 2}
                        value={source.info.price}
                        style={{ color: "#fff" }}
                        className="price-content"
                      />
                    );
                  })}
                <FlashValue
                  decimal={price.tokenName === "FTM" ? 4 : 2}
                  value={price.medianPrice}
                  style={{ color: "#03F5AE" }}
                  className="price-content"
                />
              </div>
            ))}
        </div>
      ) : (
        <div className="table-oractle-mobile">
          {prices.length > 0 &&
            prices.map((price) => (
              <div className="price-content-container">
                <div className="price-content header">
                  <img alt="" src={TokenIcons.find((token) => token.tokenName === price.tokenName)?.icon} />{" "}
                  <div className="token-info">
                    <div className="name">{price.tokenName}</div>
                    <div className="timestamp">Updated {moment(Number(lastUpdated || "")).fromNow()}</div>
                  </div>
                </div>
                {price.sources.length > 0 &&
                  price.sources.map((source, index) => {
                    return (
                      <div className="flex-row">
                        <img src={TableHeaders[index]?.icon} alt={TableHeaders[index].name} />
                        <StyledFlashValue
                          value={source.info.price}
                          data-source={TableHeaders[index].name}
                          style={{ color: "#fff" }}
                          className="price-content"
                        />
                      </div>
                    );
                  })}
                <div className="flex-row">
                  <img
                    src={TableHeaders[TableHeaders.length - 1].icon}
                    alt={TableHeaders[TableHeaders.length - 1].name}
                  />

                  <StyledFlashValue
                    value={price.medianPrice}
                    data-source={TableHeaders[TableHeaders.length - 1].name}
                    style={{ color: "#03F5AE" }}
                    className="price-content"
                  />
                </div>
              </div>
            ))}
        </div>
      )}
    </Wrapper>
  );
}

const StyledFlashValue = styled(FlashValue)`
  width: 100% !important;
  margin: auto;

  &::before {
    content: attr(data-source);
    color: #ffffff99;
  }

  span {
    margin-left: auto;
  }
`;

const Wrapper = styled.div`
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  margin: auto;
  margin-bottom: 286px;
  position: relative;
  .oracle-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    .oracle-header-content {
      img {
        width: 32px;
        height: 32px;
      }
      display: flex;
      width: calc((100% - 160px) / 8);
      flex-direction: column;
      align-items: center;

      &:nth-child(1) {
        min-width: 160px;
      }
      .header-name {
        margin-top: 4px;
      }
    }
  }

  .price-content-container {
    margin-top: 12px;
    &:nth-child(1) {
      margin-top: 4px;
    }
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    > div:not(:first-child) {
      width: calc((100% - 160px) / 8);
    }
    padding: 12px 24px;
    background: #191b2e;
    border-radius: 16px;
    .price-content {
      display: flex;
      align-items: center;
      justify-content: center;
      &:nth-child(1) {
        min-width: 160px;
        justify-content: flex-start;
        img {
          margin-right: 4px;
          width: 32px;
          height: 32px;
        }
      }

      .token-info {
        .timestamp {
          font-size: 12px;
          color: #ffffff99;
        }
      }
    }
  }

  .table-oractle-mobile {
    display: flex;
    flex-direction: column;

    .flex-row {
      width: 100% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      padding: 0 16px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }

    .price-content {
      align-self: flex-start;

      &.header {
        width: 100%;
        padding: 12px 16px;
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.062745098) !important;
      }
    }

    .price-content-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0;
      padding-bottom: 12px;
    }
  }
`;
