import SEO from "components/Common/SEO";
import Footer from "components/Footer/Footer";
import link from "img/icons/link.svg";
import ic1 from "img/icons/mm-usd.svg";
import bg from "img/lock/img_right.svg";
import { getPageTitle, shortenAddress } from "lib/legacy";
import { useLqLocked } from "domain/legacy";
import moment from "moment";

import { bigNumberify, formatAmount, } from "lib/numbers";

import styled from "styled-components";
const Lock = () => {
  const data = useLqLocked()
  const addressList = [
    {
      address: shortenAddress("0x2a6538a456650cd454dcd8f0b4665183dba0bb27", 13),
      label: "LP token",
      link: "https://ftmscan.com/address/0x2a6538a456650cd454dcd8f0b4665183dba0bb27",
    },
    {
      address: shortenAddress("0x8ac2cc7af1517e6f58a31a43773ca6c80bf3edb9", 13),
      label: "Time lock",
      link: "https://ftmscan.com/address/0x8ac2cc7af1517e6f58a31a43773ca6c80bf3edb9",
    },
    {
      address: shortenAddress("0xcd6fc0f32eee391ec4685c6c2e5c20a19960a804", 13),
      label: "Liquidity lock",
      link: "https://ftmscan.com/address/0xcd6Fc0f32Eee391Ec4685c6c2E5c20A19960A804",
    },
  ];

  return (
    <SEO title={getPageTitle("PoLL")}>
      <Wrapper>
        <div className="container">
          <LeftContent>
            <div className="title">Proof of Locked Liquidity</div>
            <div className="des">
            Liquidity is locked by renouncing the ownership of liquidity pool (LP) tokens for a fixed time period, by sending them to a time-lock smart contract.
            </div>
            <div className="link-wrapper">
              {addressList.map((item) => {
                return (
                  <div key={item.label}>
                    <div className="label">{item.label}</div>
                    <div className="info"> {item.address}</div>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <img alt="link" src={link} />
                    </a>
                  </div>
                );
              })}
            </div>
            <div className="liquididy-wrap">
              <div className="liquidity-boxes">
                <div className="liquidity-box">
                  <div className="liquidity-label">Total liquidity locked</div>
                  <div className="liquidity-box-amount txt-green ">${formatAmount(data?.tvl,0,0,true)}</div>
                </div>
                <div className="liquidity-box">
                  <div className="liquidity-label">Lock until</div>
                  <div className="liquidity-box-amount">
                  {data && data?.lockUntil ? moment(data?.lockUntil * 1000).format("MMM DD, YYYY") : '--'}
                    {/* May 23, 2023 */}
                    </div>
                </div>
              </div>
              <div className="liquidity-pool-wrap">
                <div>
                  <div className="liquidity-label">Pair</div>
                  <div className="liquidity-pool-amount">
                    {" "}
                    <img src={ic1} alt="img" /> MMY/USDC
                  </div>
                </div>
                <div>
                  <div className="liquidity-label">Pooled MMY</div>
                  <div className="liquidity-pool-amount">
                    {" "}
                    {formatAmount(data?.pooledMMY,18,0,true)} <span className="to-usd">${formatAmount(data?.pooledMMYDolar,18,0,true)}</span>
                  </div>
                </div>
                <div>
                  <div className="liquidity-label">Pooled USDC</div>
                  <div className="liquidity-pool-amount">
                    {" "}
                    {formatAmount(data?.pooledUSDC,6,0,true)} <span className="to-usd">${formatAmount(data?.pooledUSDC,6,0,true)}</span>
                  </div>
                </div>
              </div>
            </div>
          </LeftContent>
          <RightContent>
            <img src={bg} alt="bg" />
          </RightContent>{" "}
        </div>
        <Footer />
      </Wrapper>
    </SEO>
  );
};

const LeftContent = styled.div`
  margin-bottom: 120px;
  z-index: 2;
  @media screen and (max-width: 991px) {
    order: 2;
  }
  max-width: 670px;
  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    margin-bottom: 8px;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 8px;
  }
  .link-wrapper {
    margin: 8px 0 24px 0;
    display: flex;
    align-items: center;

    gap: 8px;
    @media screen and (max-width: 767px) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .label {
      margin-right: 2px;
    }
    > div {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      background: rgba(255, 213, 104, 0.1);
      border-radius: 8px;
      color: rgba(255, 255, 255, 0.6);
      .info {
        color: #ffd568;
      }
      padding: 4px 8px;
      a {
        display: flex;
        align-items: center;
      }
      img {
        margin-left: 12px;
      }
      @media screen and (max-width: 767px) {
        a {
          margin-left: auto;
        }
        width: 100%;
      }
    }
  }
  .txt-green {
    color: #03f5ae;
  }
  .liquididy-wrap {
    border-radius: 24px;
    background: #151631;
    padding: 16px;
    .liquidity-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 4px;
    }
    @media screen and (max-width: 767px) {
      padding: 16px;
    }
  }
  .liquidity-box-amount {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }
  .liquidity-boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 16px;
    .liquidity-box {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 16px;
      padding: 16px;
    }
    @media screen and (max-width: 767px) {
      gap: 12px;
      .liquidity-box {
        padding: 8px 12px;
      }
      .liquidity-label {
        font-size: 12px;
      }
      .liquidity-box-amount {
        font-size: 16px;
      }
    }
  }
  .liquidity-pool-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    @media screen and (max-width: 767px) {
      gap: 12px;
      grid-template-columns: 1fr;
      > div {
        display: flex;
        justify-content: space-between;
        .liquidity-label {
          margin-bottom: 0;
        }
      }
    }
    .liquidity-pool-amount {
      display: flex;
      align-items: center;

      img {
        height: 24px;
        margin-right: 12px;
      }
      .to-usd {
        color: rgba(255, 255, 255, 0.6);
        margin-left: 4px;
      }
    }
  }
`;

const Wrapper = styled.div`
  margin-top: 100px;

  .container {
    position: relative;
    min-height: 70vh;
    display: flex;
    width: 100%;
    max-width: 1144px;
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
    @media (max-width: 767px) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  .Footer-wrapper {
    position: unset;
    width: 100%;
    transform: none;
  }
  @media screen and (max-width: 991px) {
    margin-top: 60px;
  }

  /* @media screen and (max-width: 991px) {
    flex-direction: column;
  } */
`;

const RightContent = styled.div`
  position: relative;

  img {
    position: absolute;

    left: -80px;
    z-index: 1;
  }

  @media screen and (max-width: 1024px) {
    img {
      margin-left: -300px;
    }
  }
  @media screen and (max-width: 991px) {
    img {
      position: unset;
      margin-left: 0;
      order: 1;
      max-width: 33%;
      margin-bottom: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    img {
      max-width: 60%;
      margin: 0 auto;
      display: block;
      margin-bottom: 12px;
    }
  }
`;
export default Lock;
