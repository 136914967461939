import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { switchNetwork } from "lib/wallets";
import useSWR from "swr";
import { ethers } from "ethers";
import { t, Trans } from "@lingui/macro";

import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import { useGmxPrice, useTotalGmxStaked, useTotalGmxSupplyAll } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { BASIS_POINTS_DIVISOR, GLP_DECIMALS, GMX_DECIMALS, USD_DECIMALS } from "lib/legacy";
import { contractFetcher } from "lib/contracts";
import { ARBITRUM, FANTOM, OP } from "config/chains";
import { getWhitelistedTokens } from "config/tokens";

import TooltipComponent from "components/Tooltip/Tooltip";

import { getContract } from "config/contracts";
import GlpManager from "abis/GlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import Token from "abis/Token.json";

import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import APRLabel from "components/APRLabel/APRLabel";

import arb24Icon from "img/ic_arbitrum_24.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import glp40Icon from "img/ic_mlp_40.svg";
import gmx40Icon from "img/ic_mmy_40.svg";
import op24Icon from "img/ic_op_24.svg";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getSkeletonClassName } from "utils/skeletonHelper";

const { AddressZero } = ethers.constants;

const Tokens = ({ chainId, active, library }) => {
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const readerAddress = getContract(chainId, "Reader");
  const readerAddressFantom = getContract(FANTOM, "Reader");
  const readerAddressOp = getContract(OP, "Reader");
  const readerAddressArbitrum = getContract(ARBITRUM, "Reader");
  const glpManagerAddress = getContract(chainId, "GlpManager");

  const gmxAddress = getContract(chainId, "GMX");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");

  const gmxAddressFantom = getContract(FANTOM, "GMX");
  const glpAddressFantom = getContract(FANTOM, "GLP");
  const usdgAddressFantom = getContract(FANTOM, "USDG");

  const gmxAddressOp = getContract(OP, "GMX");
  const glpAddressOp = getContract(OP, "GLP");
  const usdgAddressOp = getContract(OP, "USDG");

  const gmxAddressArbitrum = getContract(ARBITRUM, "GMX");
  const glpAddressArbitrum = getContract(ARBITRUM, "GLP");
  const usdgAddressArbitrum = getContract(ARBITRUM, "USDG");

  const glpVesterAddress = getContract(chainId, "GlpVester");
  const gmxVesterAddress = getContract(chainId, "GmxVester");

  const glpManagerAddressFANTOM = getContract(FANTOM, "GlpManager");
  const glpManagerAddressOP = getContract(OP, "GlpManager");
  const glpManagerAddressARB = getContract(ARBITRUM, "GlpManager");

  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];
  const tokensForSupplyQueryFantom = [gmxAddressFantom, glpAddressFantom, usdgAddressFantom];
  const tokensForSupplyQueryOp = [gmxAddressOp, glpAddressOp, usdgAddressOp];
  const tokensForSupplyQueryArbitrum = [gmxAddressArbitrum, glpAddressArbitrum, usdgAddressArbitrum];

  const { data: gmxSupplyOfGlpVester } = useSWR(
    [`Dashboard:gmxSupplyOfGlpVester:${active}`, chainId, gmxAddress, "balanceOf", glpVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: gmxSupplyOfGmxVester } = useSWR(
    [`Dashboard:gmxSupplyOfGmxVester:${active}`, chainId, gmxAddress, "balanceOf", gmxVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { data: totalSuppliesFantom } = useSWR(
    [
      `Dashboard:totalSuppliesFantom:${active}`,
      FANTOM,
      readerAddressFantom,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQueryFantom]),
    }
  );
  const { data: totalSuppliesOP } = useSWR(
    [`Dashboard:totalSuppliesOP:${active}`, OP, readerAddressOp, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOp]),
    }
  );
  // console.log("?????", {readerAddressOp, tokensForSupplyQueryOp,totalSuppliesOP });
  const { data: totalSuppliesArbitrum } = useSWR(
    [
      `Dashboard:totalSuppliesArb:${active}`,
      ARBITRUM,
      readerAddressArbitrum,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQueryArbitrum]),
    }
  );
  // console.log("?????", { totalSuppliesArbitrum, totalSuppliesOP, totalSuppliesFantom });
  const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, glpManagerAddressOP, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });
  // console.log("?????", {glpManagerAddressOP, aumsOP});
  const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, glpManagerAddressFANTOM, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });

  const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, glpManagerAddressARB, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });

  let {
    op: totalGmxSupplyOP,
    fantom: totalGmxSupplyFantom,
    arb: totalGmxSupplyArb,
    total: totalGmxSupply,
  } = useTotalGmxSupplyAll();
  const { gmxPrice } = useGmxPrice(chainId, {}, active);
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const { gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum } = useGmxPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );

  let {
    op: opStakedGmx,
    fantom: fantomStakedGmx,
    arbitrum: arbitrumStakedGmx,
    total: totalStakedGmx,
  } = useTotalGmxStaked();

  let fantomStakedGmxUsd;
  if (fantomStakedGmx && gmxPriceFromFantom) {
    fantomStakedGmxUsd = fantomStakedGmx.mul(gmxPriceFromFantom).div(expandDecimals(1, GMX_DECIMALS));
  }

  let opStakedGmxUsd;
  if (opStakedGmx && gmxPriceFromOP) {
    opStakedGmxUsd = opStakedGmx.mul(gmxPriceFromOP).div(expandDecimals(1, GMX_DECIMALS));
  }

  let arbitrumStakedGmxUsd;
  if (arbitrumStakedGmx && gmxPriceFromArbitrum) {
    arbitrumStakedGmxUsd = arbitrumStakedGmx.mul(gmxPriceFromArbitrum).div(expandDecimals(1, GMX_DECIMALS));
  }

  let gmxMarketCap;
  let gmxMarketCapFantom;
  let gmxMarketCapOp;
  let gmxMarketCapArbitrum;
  if (
    gmxPriceFromFantom &&
    totalGmxSupplyFantom &&
    gmxPriceFromOP &&
    totalGmxSupplyOP &&
    gmxPriceFromArbitrum &&
    totalGmxSupplyArb
  ) {
    gmxMarketCap = gmxPriceFromFantom
      .mul(totalGmxSupplyFantom)
      .add(gmxPriceFromOP.mul(totalGmxSupplyOP))
      .add(gmxPriceFromArbitrum.mul(totalGmxSupplyArb))
      .div(expandDecimals(1, GMX_DECIMALS));

    gmxMarketCapFantom = gmxPriceFromFantom.mul(totalGmxSupplyFantom).div(expandDecimals(1, GMX_DECIMALS));
    gmxMarketCapOp = gmxPriceFromOP.mul(totalGmxSupplyOP).div(expandDecimals(1, GMX_DECIMALS));
    gmxMarketCapArbitrum = gmxPriceFromArbitrum.mul(totalGmxSupplyArb).div(expandDecimals(1, GMX_DECIMALS));
  }

  let stakedGmxSupplyUsd;
  if (
    gmxPriceFromFantom &&
    fantomStakedGmx &&
    gmxPriceFromOP &&
    opStakedGmx &&
    gmxPriceFromArbitrum &&
    arbitrumStakedGmx
  ) {
    stakedGmxSupplyUsd = fantomStakedGmx
      .mul(gmxPriceFromFantom)
      .add(opStakedGmx.mul(gmxPriceFromOP))
      .add(arbitrumStakedGmx.mul(gmxPriceFromArbitrum))
      .div(expandDecimals(1, GMX_DECIMALS));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }
  let aumOP;
  if (aumsOP && aumsOP.length > 0) {
    aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  }

  let aumFANTOM;
  if (aumsFANTOM && aumsFANTOM.length > 0) {
    aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  }

  let aumArb;
  if (aumsArb && aumsArb.length > 0) {
    aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  let glpPriceFantom;
  let glpPriceOp;
  let glpPriceArb;
  let glpSupplyFantom;
  let glpSupplyOp;
  let glpSupplyArbitrum;
  let glpMarketCapFantom;
  let glpMarketCapOp;
  let glpMarketCapArbitrum;
  let totalGlpSupply;
  if (aum && totalSupplies && totalSupplies[3]) {
    glpSupply = totalSupplies[3];

    glpPrice =
      aum && aum.gt(0) && glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (aumFANTOM && totalSuppliesFantom && totalSuppliesFantom[3]) {
    glpSupplyFantom = totalSuppliesFantom[3];

    glpPriceFantom =
      aumFANTOM && aumFANTOM.gt(0) && glpSupplyFantom.gt(0)
        ? aumFANTOM.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupplyFantom)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCapFantom = glpPriceFantom.mul(glpSupplyFantom).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (aumOP && totalSuppliesOP && totalSuppliesOP[3]) {
    glpSupplyOp = totalSuppliesOP[3];

    glpPriceOp =
      aumOP && aumOP.gt(0) && glpSupplyOp.gt(0)
        ? aumOP.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupplyOp)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCapOp = glpPriceOp.mul(glpSupplyOp).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (aumArb && totalSuppliesArbitrum && totalSuppliesArbitrum[3]) {
    glpSupplyArbitrum = totalSuppliesArbitrum[3];

    glpPriceArb =
      aumArb && aumArb.gt(0) && glpSupplyArbitrum.gt(0)
        ? aumArb.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupplyArbitrum)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCapArbitrum = glpPriceArb.mul(glpSupplyArbitrum).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (glpSupplyArbitrum && glpSupplyFantom && glpSupplyOp) {
    totalGlpSupply = glpSupplyArbitrum.add(glpSupplyFantom).add(glpSupplyOp);
  }
  if (glpMarketCapArbitrum && glpMarketCapFantom && glpMarketCapOp) {
    glpMarketCap = glpMarketCapArbitrum.add(glpMarketCapFantom).add(glpMarketCapOp);
  }
  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  let stableGlp = 0;
  let totalGlp = 0;

  tokenList.forEach((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
      if (tokenInfo.isStable) {
        stableGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
    }
  });

  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : "0.0";

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  return (
    <Wrapper>
      <Title>
        <span>Tokens</span>
      </Title>
      <TokenCardContainer>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={gmx40Icon} alt="MMY Token Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">MMY</div>
                <div className="token-price">
                  {" "}
                  {!gmxPrice && <div className="skeleton-box" />}
                  {gmxPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(gmxPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div className="customTooltip">
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(gmxPriceFromFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(gmxPriceFromOP, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(gmxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
              <ExternalLink
                href="https://docs.mummy.finance/tokenomics"
                target={"_blank"}
                className="btn-readmore border-btn"
              >
                Read more
              </ExternalLink>
            </TokenInfo>
          </CardTitle>

          <TokenCardContent>
            <TokenCardInfo>
              <div className="label">
                <span>Supply</span>
              </div>
              <div className={`value ${getSkeletonClassName(totalGmxSupply)}`}>
                {totalGmxSupply ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`${formatAmount(totalGmxSupply, GMX_DECIMALS, 0, true)} MMY`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Supply on Fantom`}
                          value={formatAmount(totalGmxSupplyFantom, GMX_DECIMALS, 0, true)}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Optimism`}
                          value={formatAmount(totalGmxSupplyOP, GMX_DECIMALS, 0, true)}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Arbitrum`}
                          value={formatAmount(totalGmxSupplyArb, GMX_DECIMALS, 0, true)}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow label={t`Total`} value={formatAmount(totalGmxSupply, GMX_DECIMALS, 0, true)} />
                      </>
                    )}
                  />
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Total Staked</span>
              </div>
              <div className="value">
                {stakedGmxSupplyUsd ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`$${formatAmount(stakedGmxSupplyUsd, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={`Staked on Fantom`}
                          value={formatAmount(fantomStakedGmxUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={`Staked on Optimism`}
                          value={formatAmount(opStakedGmxUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Staked on Arbitrum`}
                          value={formatAmount(arbitrumStakedGmxUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={`Total`}
                          value={formatAmount(stakedGmxSupplyUsd, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Market Cap</span>
              </div>
              <div className={`value ${getSkeletonClassName(gmxMarketCap)}`}>
                {gmxMarketCap ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`$${formatAmount(gmxMarketCap, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Marketcap on Fantom`}
                          value={formatAmount(gmxMarketCapFantom, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Optimism`}
                          value={formatAmount(gmxMarketCapOp, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Arbitrum`}
                          value={formatAmount(gmxMarketCapArbitrum, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={formatAmount(gmxMarketCap, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </TokenCardInfo>
          </TokenCardContent>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                Fantom APR:{" "}
                <span>
                  <APRLabel chainId={FANTOM} label="gmxAprTotal" key="FANTOM" />
                </span>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy default-btn" network={FANTOM}>
                Buy on Fantom
              </BuyLink>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                Optimism APR:{" "}
                <span>
                  <APRLabel chainId={OP} label="gmxAprTotal" key="OP" />
                </span>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy default-btn" network={OP}>
                Buy on Optimism
              </BuyLink>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                Arbitrum APR:{" "}
                <span>
                  <APRLabel chainId={ARBITRUM} label="gmxAprTotal" key="ARBITRUM" />
                </span>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy default-btn" network={ARBITRUM}>
                Buy on Arbitrum
              </BuyLink>
            </div>
          </TokenAPR>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={glp40Icon} alt="mlp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">MLP</div>
                {/* <div className="token-price">
                  {glpPrice ? `$${formatAmount(glpPrice, USD_DECIMALS, 3, true)}` : <div className="skeleton-box" />}
                </div> */}
                <div className="token-price">
                  {!glpPrice && <div className="skeleton-box" />}
                  {glpPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(glpPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div className="customTooltip">
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(glpPriceFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(glpPriceOp, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(glpPriceArb, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>{" "}
              <ExternalLink href="https://docs.mummy.finance/mlp" target={"_blank"} className="btn-readmore border-btn">
                Read more
              </ExternalLink>
            </TokenInfo>
          </CardTitle>

          <TokenCardContent>
            <TokenCardInfo>
              <div className="label">
                <span>Supply</span>
              </div>
              <div className={`value ${getSkeletonClassName(totalGlpSupply)}`}>
                {totalGlpSupply ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`${formatAmount(totalGlpSupply, GLP_DECIMALS, 0, true)} MLP`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Supply on Fantom`}
                          value={formatAmount(glpSupplyFantom, GLP_DECIMALS, 0, true)}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Optimism`}
                          value={formatAmount(glpSupplyOp, GLP_DECIMALS, 0, true)}
                        />
                        <StatsTooltipRow
                          label={t`Supply on Arbitrum`}
                          value={formatAmount(glpSupplyArbitrum, GLP_DECIMALS, 0, true)}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow label={t`Total`} value={formatAmount(totalGlpSupply, GLP_DECIMALS, 0, true)} />
                      </>
                    )}
                  />
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Total Staked</span>
              </div>
              <div className="value">
                {glpMarketCap ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`$${formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Staked on Fantom`}
                          value={formatAmount(glpMarketCapFantom, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Staked on Optimism`}
                          value={formatAmount(glpMarketCapOp, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Staked on Arbitrum`}
                          value={formatAmount(glpMarketCapArbitrum, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Market Cap</span>
              </div>
              <div className="value">
                {glpMarketCap ? (
                  <TooltipComponent
                    position="right-bottom"
                    className="nowrap"
                    handle={`$${formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}`}
                    renderContent={() => (
                      <>
                        <StatsTooltipRow
                          label={t`Marketcap on Fantom`}
                          value={formatAmount(glpMarketCapFantom, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Optimism`}
                          value={formatAmount(glpMarketCapOp, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <StatsTooltipRow
                          label={t`Marketcap on Arbitrum`}
                          value={formatAmount(glpMarketCapArbitrum, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                        <div className="divider"></div>
                        <StatsTooltipRow
                          label={t`Total`}
                          value={formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}
                          showDollar={true}
                        />
                      </>
                    )}
                  />
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label">
                <span>Stablecoin Pct.</span>
              </div>
              <div className="value">{stablePercentage}%</div>
            </TokenCardInfo>
          </TokenCardContent>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                Fantom APR:{" "}
                <span>
                  <APRLabel chainId={FANTOM} label="glpAprTotal" key="FANTOM" />
                </span>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy default-btn" network={FANTOM}>
                Buy on Fantom
              </BuyLink>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                Optimism APR:{" "}
                <span>
                  <APRLabel chainId={OP} label="glpAprTotal" key="OP" />
                </span>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy default-btn" network={OP}>
                Buy on Optimism
              </BuyLink>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                Arbitrum APR:{" "}
                <span>
                  <APRLabel chainId={ARBITRUM} label="glpAprTotal" key="ARBITRUM" />
                </span>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy default-btn" network={ARBITRUM}>
                Buy on Arbitrum
              </BuyLink>
            </div>
          </TokenAPR>
        </TokenCard>
      </TokenCardContainer>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  overflow: hidden;

  > .row {
    width: 100%;
    padding: 18px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-image: linear-gradient(to right, #13b5ec1a, #13b5ec00);

    &.op {
      background-image: linear-gradient(to right, #ff04201a, #ff042000);
      border-top: 1px solid #ffffff1a;
    }

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      margin-right: auto;
      display: flex;
      align-items: center;
      gap: 8px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #03f5ae;
      }
    }

    .btn-buy {
      border-radius: 8px;
      width: 140px;
      /* background-color: #03f5ae; */
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      /* color: #080715; */
      text-decoration: none;
    }

    @media (max-width: 767px) {
      flex-direction: column;

      .btn-buy {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
`;

const TokenCardInfo = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  text-align: left;
  .label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .value {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }
  .Tooltip-popup {
    left: 0;
    right: unset;
  }

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    .Tooltip-popup {
      left: unset;
      right: 0;
    }
  }
`;

const TokenCardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin: 72px 0 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  .token-symbol {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 4px;
    font-weight: 400;
  }
  .token-price {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }
  .btn-readmore {
    height: 40px;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #fff;
  }
`;

const TokenImg = styled.div`
  padding: 16px;
  background-image: linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);
  border-radius: 0px 0px 12px 12px;

  img {
    width: 48px;
    height: 48px;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 24px;
  right: 24px;

  @media (max-width: 767px) {
    left: 16px;
    right: 16px;
  }
`;

const TokenCard = styled.div`
  padding: 24px;
  background-color: #191b2e;
  border-radius: 12px;
  position: relative;

  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .content-mlp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 4px;
`;

const Wrapper = styled.div`
  margin: 64px 0;

  @media (max-width: 767px) {
    margin: 36px 0;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }
`;

export default Tokens;
