import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { createContext, useMemo } from "react";
import useSWR from "swr";

import { getServerUrl } from "config/backend";
import { ARBITRUM, FANTOM, OP } from "config/chains";
import { getContract } from "config/contracts";
import { getWhitelistedTokens } from "config/tokens";
import { useGmxPrice, useTotalGmxStaked } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import { ACTIVE_CHAIN_IDS, GLP_DECIMALS, GMX_DECIMALS, USD_DECIMALS, arrayURLFetcher, getStatsInfo } from "lib/legacy";
import { bigNumberify, expandDecimals } from "lib/numbers";

import GlpManager from "abis/GlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import Token from "abis/Token.json";

const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 10000,
    }
  );

  const { gmxPrice } = useGmxPrice(chainId, {}, active);
  const { gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum } = useGmxPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );
  const currentStats = getStatsInfo(dataStatsAll);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const glpManagerAddress = getContract(chainId, "GlpManager");
  const glpManagerAddressFANTOM = getContract(FANTOM, "GlpManager");
  const glpManagerAddressOP = getContract(OP, "GlpManager");
  const glpManagerAddressARB = getContract(ARBITRUM, "GlpManager");

  const readerAddress = getContract(chainId, "Reader");
  const gmxAddress = getContract(chainId, "GMX");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const gmxVesterAddress = getContract(chainId, "GmxVester");
  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  const { data: aums } = useSWR([`Dashboard:getAums`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 5000,
    }
  );

  const { data: gmxSupplyOfGmxVester } = useSWR(
    [`Dashboard:gmxSupplyOfGmxVester:${active}`, chainId, gmxAddress, "balanceOf", gmxVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 5000,
    }
  );

  const { data: gmxSupplyOfGlpVester } = useSWR(
    [`Dashboard:gmxSupplyOfGlpVester:${active}`, chainId, gmxAddress, "balanceOf", glpVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 5000,
    }
  );

  const {
    total: totalGmxStaked,
    fantom: fantomGmxStaked,
    op: opGmxStaked,
    arbitrum: arbitrumStakedGmx,
  } = useTotalGmxStaked();
  let currentChainGmxStaked = fantomGmxStaked;
  if (chainId === OP) currentChainGmxStaked = opGmxStaked;
  if (chainId === ARBITRUM) currentChainGmxStaked = arbitrumStakedGmx;

  const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, glpManagerAddressOP, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });

  const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, glpManagerAddressFANTOM, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });

  const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, glpManagerAddressARB, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });

  let aumOP;
  if (aumsOP && aumsOP.length > 0) {
    aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  }

  let aumFANTOM;
  if (aumsFANTOM && aumsFANTOM.length > 0) {
    aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  }

  let aumArb;
  if (aumsArb && aumsArb.length > 0) {
    aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    glpSupply = totalSupplies[3];
    if (gmxSupplyOfGmxVester && gmxSupplyOfGlpVester) {
      glpSupply = glpSupply.sub(gmxSupplyOfGmxVester).sub(gmxSupplyOfGlpVester);
    }

    glpPrice =
      aum && aum.gt(0) && glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }

  let tvl;
  if (currentChainGmxStaked && aum && gmxPrice) {
    tvl = gmxPrice.mul(currentChainGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aum);
  }

  let fantomTvl;
  if (fantomGmxStaked && aumFANTOM && gmxPriceFromFantom) {
    fantomTvl = gmxPriceFromFantom.mul(fantomGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aumFANTOM);
  }

  let opTvl;
  if (opGmxStaked && aumOP && gmxPriceFromOP) {
    opTvl = gmxPriceFromOP.mul(opGmxStaked).div(expandDecimals(1, GMX_DECIMALS)).add(aumOP);
  }

  let arbTvl;
  if (arbitrumStakedGmx && aumArb && gmxPriceFromArbitrum) {
    arbTvl = gmxPriceFromArbitrum.mul(arbitrumStakedGmx).div(expandDecimals(1, GMX_DECIMALS)).add(aumArb);
  }
  let totalTvl;
  if (glpMarketCap && gmxPrice && totalGmxStaked && aumOP && aumFANTOM && aumArb && fantomTvl && opTvl && arbTvl) {
    totalTvl = fantomTvl.add(opTvl).add(arbTvl)
  }

  const valueProvider = useMemo(() => {
    return {
      glpPrice,
      gmxPrice,
      totalValueLocked: {
        total: totalTvl,
        [FANTOM]: fantomTvl,
        [OP]: opTvl,
        [ARBITRUM]: arbTvl,
      },
      totalFees: {
        total: currentStats.totalFees,
        [FANTOM]: currentStats[FANTOM]?.totalFees,
        [OP]: currentStats[OP]?.totalFees,
        [ARBITRUM]: currentStats[ARBITRUM]?.totalFees,
      },
      totalUsers: {
        total: currentStats.totalUser,
        [FANTOM]: currentStats[FANTOM]?.totalUser,
        [OP]: currentStats[OP]?.totalUser,
        [ARBITRUM]: currentStats[ARBITRUM]?.totalUser,
      },
      totalTradingVolume: {
        total: currentStats.totalVolume,
        [FANTOM]: currentStats[FANTOM]?.totalVolume,
        [OP]: currentStats[OP]?.totalVolume,
        [ARBITRUM]: currentStats[ARBITRUM]?.totalVolume,
      },
      longOpenInterest: {
        total: currentStats.longOpenInterest,
        [FANTOM]: currentStats[FANTOM]?.longOpenInterest,
        [OP]: currentStats[OP]?.longOpenInterest,
        [ARBITRUM]: currentStats[ARBITRUM]?.longOpenInterest,
      },
      shortOpenInterest: {
        total: currentStats.shortOpenInterest,
        [FANTOM]: currentStats[FANTOM]?.shortOpenInterest,
        [OP]: currentStats[OP]?.shortOpenInterest,
        [ARBITRUM]: currentStats[ARBITRUM]?.shortOpenInterest,
      },
      feeSinceToNow: {
        total: currentStats.feeSinceToNow,
        [FANTOM]: {
          value: currentStats[FANTOM]?.feeSinceToNow,
          timestamps: currentStats[FANTOM]?.feeSinceTimestamp,
        },
        [OP]: {
          value: currentStats[OP]?.feeSinceToNow,
          timestamps: currentStats[OP]?.feeSinceTimestamp,
        },
        [ARBITRUM]: {
          value: currentStats[ARBITRUM]?.feeSinceToNow,
          timestamps: currentStats[ARBITRUM]?.feeSinceTimestamp,
        },
      },
    };
  }, [totalTvl, fantomTvl, opTvl, arbTvl, currentStats]);

  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
