import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import icLeveraged from "img/home/ic_leveraged.svg";
import icYeild from "img/home/ic_yeild.svg";
import icCustody from "img/home/ic_custody.svg";
import icFriendly from "img/home/ic_friendly.svg";
import icTransprency from "img/home/ic_transprency.svg";
import icLowFee from "img/home/ic_lowFee.svg";

const Boundries = () => {
  return (
    <Wrapper className="container">
      <Heading>
        <Title>Breaking Boundaries in DEX</Title>
        <Description>Welcome to the new era of perpetual trading.</Description>
      </Heading>
      <Content>
        <Fade cascade bottom>
          <BoundryItem>
            <img src={icLeveraged} alt="boundryIcon" />
            <Detail>
              <div className="title">Leveraged Trading</div>
              <div className="desc">
                Innovative trading solution for BTC, ETH, FTM, OP, BNB, LINK, SNX, RDNT, ARB and GMX with up to 100x
                leverage.
              </div>
            </Detail>
          </BoundryItem>
          <BoundryItem>
            <img src={icYeild} alt="boundryIcon" />
            <Detail>
              <div className="title">Real Yield</div>
              <div className="desc">Stake and mine real income in FTM or ETH.</div>
            </Detail>
          </BoundryItem>
          <BoundryItem>
            <img src={icCustody} alt="boundryIcon" />
            <Detail>
              <div className="title">Self-Custody</div>
              <div className="desc">Total control of your funds.</div>
            </Detail>
          </BoundryItem>
          <BoundryItem>
            <img src={icLowFee} alt="boundryIcon" />
            <Detail>
              <div className="title">Lowest Fees</div>
              <div className="desc">Enter and exit positions with minimal spread and zero price impact.</div>
            </Detail>
          </BoundryItem>
          <BoundryItem>
            <img src={icTransprency} alt="boundryIcon" />
            <Detail>
              <div className="title">Transparency</div>
              <div className="desc">Fully transparent open-source smart contracts.</div>
            </Detail>
          </BoundryItem>
          <BoundryItem>
            <img src={icFriendly} alt="boundryIcon" />
            <Detail>
              <div className="title">User-Friendly</div>
              <div className="desc">Trade effortlessly through a simple interface.</div>
            </Detail>
          </BoundryItem>
        </Fade>
      </Content>
    </Wrapper>
  );
};

const Detail = styled.div`
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }

  .desc {
    color: #ffffff99;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-top: 12px;
  }

  @media (max-width: 1023px) {
    .title {
      font-size: 16px;
    }

    .desc {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    .title {
      font-size: 14px;
    }
  }
`;

const BoundryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  padding-top: 16px;
  border-radius: 24px;
  border-top: 8px solid #0b140d;
  background: linear-gradient(180deg, rgba(199, 255, 228, 0.05) 0%, rgba(0, 255, 102, 0) 100%);
  box-shadow: 0 -8px 0 0 #020902;

  @media (max-width: 767px) {
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    gap: 12px;

    img {
      width: 36px;
      height: 36px;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: unset;
  }
`;

const Heading = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: column;
  margin-bottom: 36px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
  color: #ffffff99;
  max-width: 697px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 56px;
  line-height: 140%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (max-width: 1023px) {
    font-size: 40px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const Wrapper = styled.div`
  padding: 0 24px;

  @media (max-width: 1023px) {
    padding: 0 24px;
  }

  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

export default Boundries;
