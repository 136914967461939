import React, { useContext } from "react";
import styled from "styled-components";
import { Trans, t } from "@lingui/macro";
import CountUp from "react-countup";

import { USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";

import { ARBITRUM, FANTOM, OP, getChainName } from "config/chains";
import arb24Icon from "img/ic_arbitrum_24.svg"
import op24Icon from "img/ic_op_24.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import opImg from "img/ic_op_40.svg";
import stats1 from "img/dashboard/tvl.svg";
import fantomImg from "img/ic_ftm_40.svg";
import stats2 from "img/dashboard/tvolumn.svg";
import stats3 from "img/dashboard/tfee.svg";
import since from "img/dashboard/since.svg";
import longPosition from "img/dashboard/longPosition.svg";
import shortPosition from "img/dashboard/shortPosition.svg";
import VolumeChart from "./VolumeChart";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import TooltipComponent from "components/Tooltip/Tooltip";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { formatDate } from "lib/dates";
import { useMediaQuery } from "react-responsive";

import { StatsContext } from "contexts/StatsProvider";

const Stats = ({ chainId }) => {
  const isTablet = useMediaQuery({ query: "(max-width: 1023px) and (min-width: 767px)" });
  const totalStatsStartDate = {
    [FANTOM]: `05 Dec 2022`,
    [OP]: `03 Mar 2023`,
    [ARBITRUM]: `29 Jun 2023`
  };
  const chainName = getChainName(chainId);

  const { totalValueLocked, totalFees, totalTradingVolume, longOpenInterest, shortOpenInterest, feeSinceToNow } =
    useContext(StatsContext);
  return (
    <Wrapper className={`${chainId === OP ? "op-content" : ""}`}>
      <Title>
        <span>Stats</span> {chainId === OP && <img src={op24Icon} alt="op24Icon" />}
        {chainId === FANTOM && <img src={ftm24Icon} alt="ftm24Icon" />}
        {chainId === ARBITRUM && <img src={arb24Icon} alt="arb24Icon" />}
      </Title>
      <Description>
        <div>
          {chainName} Total Stats start from {totalStatsStartDate[chainId]}.<br /> To view detailed stats, please check the{" "}
          <ExternalLink href="https://app.mummy.finance/#/analytic/overview">Analytics page</ExternalLink>
          {/* {chainId !== ARBITRUM && <ExternalLink href="https://app.mummy.finance/#/analytic/overview">Analytics page</ExternalLink>}
          {chainId === ARBITRUM && <ExternalLink href="https://stats.mummy.finance/arbitrum">Stats page</ExternalLink>} */}
          .
        </div>
        {/* {chainId === FANTOM && (
          <ExternalLink href="https://stats.mummy.finance">https://stats.mummy.finance</ExternalLink>
        )}
        {chainId === OP && (
          <ExternalLink href="https://stats.mummy.finance/optimism">https://stats.mummy.finance/optimism</ExternalLink>
        )} */}

        {/* <ExternalLink href="https://www.mummy.finance/#/analytic/">https://www.mummy.finance/#/analytic</ExternalLink>. */}
      </Description>
      {chainId === FANTOM && <ChainImg src={fantomImg} alt="Chain img"/>}
      {chainId === OP && <ChainImg src={opImg} alt="Chain img"/>}
      {chainId === ARBITRUM && <ChainImg src={chainId === FANTOM ? fantomImg : arb24Icon} alt="Chain img"/>}


      <StatsContainer>
        <StatBlock>
          <div className="stats-img">
            <img src={stats1} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Total Value Locked</div>
            <div className="stats-value">
              {totalValueLocked[chainId] ? (
                <TooltipComponent
                  position="left-bottom"
                  handle={
                    <CountUp
                      end={parseFloat(formatAmount(totalValueLocked[chainId], USD_DECIMALS, 0))}
                      separator=","
                      prefix="$"
                    />
                  }
                  renderContent={() => (
                    <CustomContent>
                      <div>
                        The total value of MMY staked and MLP pool on:
                        <div className="row">
                          Fantom:{" "}
                          <span className="value">
                            ${formatAmount(totalValueLocked[FANTOM], USD_DECIMALS, 0, true)}
                          </span>
                        </div>
                        <div className="row">
                          Optimism:{" "}
                          <span className="value">${formatAmount(totalValueLocked[OP], USD_DECIMALS, 0, true)}</span>
                        </div>
                        <div className="row">
                          Arbitrum:{" "}
                          <span className="value">${formatAmount(totalValueLocked[ARBITRUM], USD_DECIMALS, 0, true)}</span>
                        </div>
                      </div>
                      <div className="divider"></div>
                      <div className="row">
                        Total:{" "}
                        <span className="value">${formatAmount(totalValueLocked["total"], USD_DECIMALS, 0, true)}</span>
                      </div>
                    </CustomContent>
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={stats2} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Total Volume</div>
            <div className="stats-value">
              {totalTradingVolume[chainId] ? (
                <TooltipComponent
                  position="left-bottom"
                  handle={
                    <CountUp
                      end={parseFloat(formatAmount(totalTradingVolume[chainId], USD_DECIMALS, 0))}
                      separator=","
                      prefix="$"
                    />
                  }
                  renderContent={() => (
                    <StatsTooltip
                      title={`Total Volume`}
                      fantomValue={totalTradingVolume[FANTOM]}
                      opValue={totalTradingVolume[OP]}
                      arbValue={totalTradingVolume[ARBITRUM]}
                      total={totalTradingVolume["total"]}
                    />
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={stats3} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Total Fees</div>
            <div className="stats-value">
              {totalFees[chainId] ? (
                <TooltipComponent
                  position={isTablet ? "right-bottom" : "left-bottom"}
                  handle={
                    <CountUp
                      end={parseFloat(formatAmount(totalFees[chainId], USD_DECIMALS, 0))}
                      separator=","
                      prefix="$"
                    />
                  }
                  renderContent={() => (
                    <StatsTooltip
                      title={`Total Fees`}
                      fantomValue={totalFees[FANTOM]}
                      opValue={totalFees[OP]}
                      arbValue={totalFees[ARBITRUM]}
                      total={totalFees["total"]}
                    />
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={longPosition} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Long Positions</div>
            <div className="stats-value">
              {longOpenInterest[chainId] ? (
                <TooltipComponent
                  position="left-bottom"
                  className="nowrap"
                  handle={
                    <CountUp
                      end={parseFloat(formatAmount(longOpenInterest[chainId], USD_DECIMALS, 0))}
                      separator=","
                      prefix="$"
                    />
                  }
                  renderContent={() => (
                    <StatsTooltip
                      title={`Long Positions`}
                      fantomValue={longOpenInterest[FANTOM]}
                      arbValue={longOpenInterest[ARBITRUM]}
                      opValue={longOpenInterest[OP]}
                      total={longOpenInterest["total"]}
                    />
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={shortPosition} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Short Positions</div>
            <div className="stats-value">
              {shortOpenInterest[chainId] ? (
                <TooltipComponent
                  position="left-bottom"
                  className="nowrap"
                  handle={
                    <CountUp
                      end={parseFloat(formatAmount(shortOpenInterest[chainId], USD_DECIMALS, 0))}
                      separator=","
                      prefix="$"
                    />
                  }
                  renderContent={() => (
                    <StatsTooltip
                      title={`Short Positions`}
                      fantomValue={shortOpenInterest[FANTOM]}
                      opValue={shortOpenInterest[OP]}
                      arbValue={shortOpenInterest[ARBITRUM]}
                      total={shortOpenInterest["total"]}
                    />
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={since} alt="img" />
          </div>
          {feeSinceToNow[chainId]?.timestamps && feeSinceToNow[chainId]?.value ? (
            <div className="stats-info">
              <div className="label">Fees since {formatDate(feeSinceToNow[chainId]?.timestamps)}</div>
              <div className="stats-value">
                <TooltipComponent
                  position={isTablet ? "right-bottom" : "left-bottom"}
                  className="nowrap"
                  handle={
                    <CountUp
                      end={parseFloat(formatAmount(feeSinceToNow[chainId]?.value, USD_DECIMALS, 2))}
                      separator=","
                      prefix="$"
                    />
                  }
                  renderContent={() => (
                    <StatsTooltip
                      title={`Fees`}
                      fantomValue={feeSinceToNow[FANTOM]?.value}
                      opValue={feeSinceToNow[OP]?.value}
                      arbValue={feeSinceToNow[ARBITRUM]?.value}
                      total={feeSinceToNow["total"]}
                    />
                  )}
                />
              </div>
            </div>
          ) : <div className="skeleton-box" style={{width: '65px', height: '27px'}}/>}
        </StatBlock>
      </StatsContainer>
      <VolumeChart chainId={chainId} />
    </Wrapper>
  );
};

const ChainImg = styled.img`
  position: absolute;
  width: 202px;
  height: 202px;
  top: -36px;
  right: -40px;

  @media (max-width: 1023px) {
    width: 160px;
    height: 160px;
    right: -36px;
  }

  @media (max-width: 767px) {
    width: 64px;
    height: 64px;
    top: -16px;
    right: -16px;
  }
`;

const StatBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .stats-img {
    background: linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);
    border-radius: 16px;
    padding: 12px;
    @media (max-width: 1023px) {
      width: 48px;
      height: 48px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .label {
    margin-bottom: 4px;
  }
  .stats-value {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }
`;

const StatsContainer = styled.div`
  margin: 36px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Description = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 4px;
`;

const CustomContent = styled.div`
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    .value {
      color: #fff;
      font-weight: bold;
      text-align: right;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 24px;
  background: radial-gradient(50% 285.34% at 100% 0%, rgba(0, 46, 88, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%);
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.op-content {
    background: radial-gradient(50% 285.34% at 100% 0%, rgba(88, 0, 0, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%);
  }
  @media screen and (max-width: 600px) {
    padding: 16px;
  }

  @media (max-width: 767px) {
    .Tooltip-popup {
      min-width: 240px !important;
    }
  }
`;

export default Stats;
