import React from "react";
import styled from "styled-components";

export const StyledLongShortTag = styled.span`
  color: ${(props) => (props.isLong ? "#34f5ae" : "#fa3c58")};
  padding: 4px;
  padding-top: 6px;
  /* background-color: #303035; */
  background-color: #86868645;
  text-transform: uppercase;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  text-align: center;
`;

const LongShortTag = ({ isLong, style }) => {
  return (
    <StyledLongShortTag isLong={isLong} style={style}>
      {isLong ? "LONG" : "SHORT"}
    </StyledLongShortTag>
  );
};

export default LongShortTag;
