import SEO from "components/Common/SEO";
import Footer from "components/Footer/Footer";
import club1 from "img/home/club1.svg";
import club2 from "img/home/club2.svg";
import { useChainId } from "lib/chains";
import { getPageTitle } from "lib/legacy";
import { switchNetwork } from "lib/wallets";
import { useState } from "react";
import styled from "styled-components";
import Banner from "./components/Banner";
import Banner2 from "./components/Banner2";
import HowTo from "./components/Howto";
import StakeNWFTMain from "./components/StakeNFTInfo";
import StartBlock from "./components/StartBlock";
import "./style.scss";
import { ARBITRUM, FANTOM, OP } from "config/chains";
import { useWeb3React } from "@web3-react/core";
const CLUBS = [
  { name: "Mummy Club_1", icon: club1, value: 250 },
  { name: "Mummy Club_2", icon: club2, value: 10 },
];

function NFT({ connectWallet, setPendingTxns, pendingTxns }) {
  const { chainId } = useChainId();
  const { active, library, account, chainId: waletChainId } = useWeb3React();
  // const [activedTab, setActivedTab] = useState(chainId);
  // const handleClickTab = (value) => {
  //   setActivedTab(value);
  //   switchNetwork(value, true);
  // };
  return (
    <SEO title={getPageTitle("NFT")}>
      <NFTWrap className="">
        {/* <ClubTabs>
          {CLUBS.map((item) => (
            <ClubItem
              className={`${item.value === activedTab ? "actived" : ""}`}
              onClick={() => {
                handleClickTab(item.value);
              }}
              key={item.name}
            >
              <img alt="img" src={item.icon} />
              {item.name}
            </ClubItem>
          ))}
        </ClubTabs> */}
        <Banner connectWallet={connectWallet} setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
        {chainId !== ARBITRUM && (
          <>
            <StakeNWFTMain connectWallet={connectWallet} setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
            {/* <Participate /> */}
            <Banner2 />
            <HowTo />
            <StartBlock />
          </>
        )}
        {chainId === ARBITRUM && <div className="nft-notavailable-outside"></div>}
      </NFTWrap>
      <Footer />
    </SEO>
  );
}
const ClubItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;

  &.actived {
    background: #03f5ae;
    color: #101233;
  }
  img {
    width: 24px;
    margin-right: 8px;
  }
`;
const ClubTabs = styled.div`
  display: flex;
  margin: 48px auto 0;
  width: fit-content;
  background: #101233;
  border-radius: 8px;
  overflow: hidden;
`;
const NFTWrap = styled.div`
  margin-bottom: 160px;
  .container {
    width: 100%;
    max-width: 1144px;
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 767px) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  .btn-primary {
    border: none;
    background: #03f5ae;
    border-radius: 8px;
    color: #080715;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #04d397;
      color: #080715 !important;
    }
  }
  .disable {
    background: #b4cfc7;
  }
  .btn-outline {
    font-weight: 500;
    font-size: 14px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #ffffff;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .Footer-wrapper {
    position: unset;
    width: 100%;
    transform: none;
  }
`;

export default NFT;
