import { MMY_VAULT, MLP_VAULT, MMY_WFTM_VAULT, MMY_WETH_VAULT, MMY_WFTM_OLD_VAULT } from "./index";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { StyledVaultContent, Wrapper } from "./index.style";

import gmxIcon from "img/ic_mmy_custom.svg";
import mlpIcon from "img/ic_mlp_40.svg";
import ftm from "img/tokens/ftm.svg";
import eth from "img/tokens/eth.svg";
import icLink from "img/icons/link-green.svg";

import CHAINS from "./data";
import getImageChainMini from "./getImageChainMini";

const VaultTab = ({ currentActiveTab, chainId }) => {
  return (
    <Wrapper>
      <StyledVaultContent>
        <div className="header">
          <div className="title">Strategy</div>
          <ExternalLink href={CHAINS[chainId]?.strategy[currentActiveTab].link} className="txt-link">
            Contract <img src={icLink} alt="" />
          </ExternalLink>
        </div>
        <div className="content">{CHAINS[chainId]?.strategy[currentActiveTab].text}</div>
      </StyledVaultContent>
      <StyledVaultContent>
        <div className="header" style={{ marginBottom: "24px" }}>
          <div className="title">
            <div className="App-card-title-mark-icon">
              {currentActiveTab === MMY_VAULT ? (
                <>
                  <img className="big-img" src={gmxIcon} alt="mmyBigIcon" />
                </>
              ) : currentActiveTab === MLP_VAULT ? (
                <>
                  <img className="big-img" src={mlpIcon} alt="mmyBigIcon" />
                </>
              ) : (
                <>
                  <img className="big-img" src={gmxIcon} alt="mmyBigIcon" />
                </>
              )}

              {getImageChainMini(chainId)}
            </div>

            {currentActiveTab === MMY_VAULT ? <>MMY</> : currentActiveTab === MLP_VAULT ? <>MLP</> : <>MMY</>}
          </div>
          <ExternalLink
            href={
              !Array.isArray(CHAINS[chainId]?.token[currentActiveTab].link)
                ? CHAINS[chainId]?.token[currentActiveTab].link
                : CHAINS[chainId]?.token[currentActiveTab].link[0]
            }
            className="txt-link"
          >
            Contract <img src={icLink} alt="" />
          </ExternalLink>
        </div>
        <div className="content">
          {!Array.isArray(CHAINS[chainId]?.token[currentActiveTab].text)
            ? CHAINS[chainId]?.token[currentActiveTab].text
            : CHAINS[chainId]?.token[currentActiveTab].text[0]}
        </div>
      </StyledVaultContent>
      {[MMY_WFTM_OLD_VAULT, MMY_WFTM_VAULT].includes(currentActiveTab) && (
        <StyledVaultContent>
          <div className="header" style={{ marginBottom: "24px" }}>
            <div className="title">
              <img src={ftm} alt="mmyBigIcon" /> WFTM
            </div>
            <ExternalLink href={CHAINS[chainId]?.token[currentActiveTab].link[1]} className="txt-link">
              Contract <img src={icLink} alt="" />
            </ExternalLink>
          </div>
          <div className="content">{CHAINS[chainId]?.token[currentActiveTab].text[1]}</div>
        </StyledVaultContent>
      )}
      {currentActiveTab === MMY_WETH_VAULT && (
        <StyledVaultContent>
          <div className="header" style={{ marginBottom: "24px" }}>
            <div className="title">
              <img src={eth} alt="mmyBigIcon" /> WETH
            </div>
            <ExternalLink href={CHAINS[chainId]?.token[currentActiveTab].link[1]} className="txt-link">
              Contract <img src={icLink} alt="" />
            </ExternalLink>
          </div>
          <div className="content">{CHAINS[chainId]?.token[currentActiveTab].text[1]}</div>
        </StyledVaultContent>
      )}
      {(currentActiveTab === MMY_VAULT || currentActiveTab === MLP_VAULT) && (
        <StyledVaultContent>
          <div className="header" style={{ marginBottom: "24px" }}>
            <div className="title">Disclaimer</div>
          </div>
          <div className="content">
            The esMMY APY reflects the rewards used to compound for more FTM, esMMY, and MP and will not be directly
            calculated when withdrawing tokens.
          </div>
        </StyledVaultContent>
      )}
    </Wrapper>
  );
};

export default VaultTab;
