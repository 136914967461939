import React from "react";
import styled from "styled-components";

import share from "img/share.svg";
import moment from "moment";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getDecimalTokenByAddress, getNameTokenByAddress } from "utils/tokenHelper";
import { formatAmount } from "lib/numbers";

const Wrapper = styled.div`
  padding: 0;
  font-size: 14px;

  /* border: 1px solid hsla(0, 0%, 100%, 0.062745098) !important;
  background-color: hsla(0, 0%, 100%, 0.062745098) !important; */
  &:first-child {
    border-top: none !important;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }

  .wrapper {
    position: relative;

    .share {
      position: absolute;

      top: 7px;
      right: 5px;
    }
  }

  .close-on {
    flex-direction: column;

    .size:first-child {
      .label {
        color: #ffffff;
      }
    }

    .size:last-child {
      .label {
        color: rgb(185, 185, 185);
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed hsla(0, 0%, 100%, 0.062745098);
  padding-bottom: 14px;

  .name {
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      color: #34f5ae;
    }
  }
`;

const Body = styled.div`
  padding-top: 14px;

  .title {
    margin-bottom: 14px;
  }

  .timestamp {
    color: rgb(185, 185, 185);
  }
`;

const MobileTradeHistoryItemTradeTab = ({ data }) => {
  const indexToken = getNameTokenByAddress(data.indexToken);
  const isLongOrShort = data.isLong ? "LONG" : "SHORT";
  const isPlusOrMinus = data.action === "DecreasePosition" ? "-" : "+";
  const CollateralDelta = formatAmount(data.collateralDelta, 30, 3, true);
  return (
    <Wrapper>
      <div className="table-mobile">
        <div className="header">
          <div className="market">
            <div className="wrapper">
              <div className="coin">{getNameTokenByAddress(data.indexToken)}/USD</div>
              <div
                style={{
                  color: data.isLong ? "#34f5ae" : "rgb(246, 71, 93)",
                }}
                className="position"
              >
                {data.isLong ? "LONG" : "SHORT"}
              </div>
              <ExternalLink className="share" href={`https://ftmscan.com/tx/${data.txhash}`}>
                <img src={share} alt="share" />
              </ExternalLink>
            </div>
          </div>
        </div>

        <div className="content open-position">
          <div className="close-on child">
            <div className="size child">
              <div className="label">
                {data.action === "DecreasePosition" || data.action === "IncreasePosition"
                  ? data.sizeDelta == 0
                    ? data.action == "IncreasePosition"
                      ? `Deposited $${CollateralDelta} to ${indexToken} ${isLongOrShort}`
                      : data.collateralValue !== 0
                      ? `Withdrew  $${CollateralDelta} from ${indexToken} ${isLongOrShort}`
                      : ""
                    : `${indexToken} ${isLongOrShort} ${String(data.action)
                        .replace("Position", "d")
                        .toLowerCase()} by $${formatAmount(data.sizeDelta, 30, 3, true)} (price = $${formatAmount(
                        data.price,
                        30,
                        3,
                        true
                      )})`
                  : data.action === "Swap"
                  ? `Swapped ${formatAmount(
                      data.amountIn,
                      getDecimalTokenByAddress(data.tokenIn),
                      3,
                      true
                    )} ${getNameTokenByAddress(data.tokenIn)} for ${formatAmount(
                      data.amountOutAfterFees,
                      getDecimalTokenByAddress(data.tokenOut),
                      3,
                      true
                    )} ${getNameTokenByAddress(data.tokenOut)}`
                  : `${getNameTokenByAddress(data.tokenOut)} ${isLongOrShort} of ${formatAmount(
                      data.size,
                      30,
                      3,
                      true
                    )} has been liquidated (price = $${formatAmount(data.markPrice, 30, 3, true)})`}
              </div>
            </div>

            <div className="size child">
              <div className="label">{moment(data.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MobileTradeHistoryItemTradeTab;
