import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import styled from "styled-components";

import VaultFantom from "pages/Vault";
import VaultOP from "pages/Vault/indexOP";
import Vest from "pages/Vest";

import mlp from "img/routerTab/mlp.svg";
import mmy_esmmy from "img/routerTab/mmy_esmmy.svg";
import overview from "img/routerTab/overview.svg";
import transfer_account from "img/routerTab/transfer_account.svg";
import vault from "img/routerTab/vault.svg";
import vest from "img/routerTab/vest.svg";

import { Trans } from "@lingui/react";
import GlpManager from "abis/GlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import Footer from "components/Footer/Footer";
import { getServerUrl } from "config/backend";
import { ARBITRUM, FANTOM, OP, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useGmxPrice, useTotalGmxStaked, useTotalEsGmxStaked } from "domain/legacy";
import { ethers } from "ethers";
import { contractFetcher } from "lib/contracts";
import {
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import EarnMLP from "pages/Stake/EarnMLP";
import MMYandESMMY from "pages/Stake/MMYandESMMY";
import EarnOverview from "pages/Stake/Overview";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import bg from "img/earn/bg.svg";

const { AddressZero } = ethers.constants;
const EarnTabs = [
  // {
  //   name: "Overview",
  //   params: null,
  //   img: overview,
  //   chains: [FANTOM, OP, ARBITRUM]
  // },
  {
    name: "MMY & esMMY",
    params: "mmy-esmmy",
    img: mmy_esmmy,
    chains: [FANTOM, OP, ARBITRUM]
  },
  {
    name: "MLP",
    params: "mlp",
    img: mlp,
    chains: [FANTOM, OP, ARBITRUM]
  },
  {
    name: "Vest",
    params: "vest",
    img: vest,
    chains: [FANTOM, OP, ARBITRUM]
  },
  {
    name: "Vault",
    params: "vault",
    img: vault,
    chains: [FANTOM, OP]
  },
  {
    name: "Transfer account",
    params: "transfer-account",
    img: transfer_account,
    chains: [FANTOM, OP, ARBITRUM]
  },
];
export default function RouterTab({ setPendingTxns, connectWallet }) {
  let match = useRouteMatch();
  const location = useLocation();
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const gmxAddress = getContract(chainId, "GMX");
  const esGmxAddress = getContract(chainId, "ES_GMX");
  const bnGmxAddress = getContract(chainId, "BN_GMX");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");

  const stakedGmxTrackerAddress = getContract(chainId, "StakedGmxTracker");
  const bonusGmxTrackerAddress = getContract(chainId, "BonusGmxTracker");
  const feeGmxTrackerAddress = getContract(chainId, "FeeGmxTracker");

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");

  const glpManagerAddress = getContract(chainId, "GlpManager");

  const stakedGmxDistributorAddress = getContract(chainId, "StakedGmxDistributor");
  const stakedGlpDistributorAddress = getContract(chainId, "StakedGlpDistributor");

  const gmxVesterAddress = getContract(chainId, "GmxVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  const vesterAddresses = [gmxVesterAddress, glpVesterAddress];

  const excludedEsGmxAccounts = [stakedGmxDistributorAddress, stakedGlpDistributorAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [gmxAddress, esGmxAddress, glpAddress, stakedGmxTrackerAddress];
  const depositTokens = [
    gmxAddress,
    esGmxAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    bnGmxAddress,
    glpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmxTrackerAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedGmxSupply } = useSWR(
    [`StakeV2:stakedGmxSupply:${active}`, chainId, gmxAddress, "balanceOf", stakedGmxTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
    }
  );

  const { data: esGmxSupply } = useSWR(
    [`StakeV2:esGmxSupply:${active}`, chainId, readerAddress, "getTokenSupply", esGmxAddress],
    {
      fetcher: contractFetcher(library, ReaderV2, [excludedEsGmxAccounts]),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );
  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { gmxPrice } = useGmxPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);

  // const { totalGmxSupply, totalSupply } = useMMYInfo();
  let totalGmxSupply, totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalGmxSupply = totalSupplies[1];
    totalSupply = totalSupplies[1];
  }
  // const gmxPrice = BigNumber.from("1000000000000000000000000000000")

  // let { total: totalGmxSupply } = useTotalGmxSupply();
  // let totalGmxSupply = BigNumber.from("10000000000000000000000000");

  let { op: opGmxStaked, fantom: fantomGmxStaked, total: totalGmxStaked } = useTotalGmxStaked();
  let { op: opEsGmxStaked, fantom: fantomEsGmxStaked, total: totalEsGmxStaked } = useTotalEsGmxStaked();


  const gmxSupplyUrl = getServerUrl(chainId, "/gmx_supply");
  // const { data: gmxSupply } = useSWR([gmxSupplyUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });
  let gmxSupply = totalSupply;

  const isGmxTransferEnabled = true;

  let esGmxSupplyUsd;
  if (esGmxSupply && gmxPrice) {
    esGmxSupplyUsd = esGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmxSupply,
    gmxPrice,
    gmxSupply
  );

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusGmxTrackerRewards && processedData.bnGmxInFeeGmx) {
    multiplierPointsAmount = processedData.bonusGmxTrackerRewards.add(processedData.bnGmxInFeeGmx);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnGmxInFeeGmx && processedData.bonusGmxInFeeGmx) {
    totalRewardTokens = processedData.bnGmxInFeeGmx.add(processedData.bonusGmxInFeeGmx);
  }

  let totalRewardTokensAndGlp;
  if (totalRewardTokens && processedData && processedData.glpBalance) {
    totalRewardTokensAndGlp = totalRewardTokens.add(processedData.glpBalance);
  }

  const bonusGmxInFeeGmx = processedData ? processedData.bonusGmxInFeeGmx : undefined;

  let stakedGmxSupplyUsd;
  if (!totalGmxStaked.isZero() && gmxPrice) {
    stakedGmxSupplyUsd = totalGmxStaked.mul(gmxPrice).div(expandDecimals(1, 18));
  }

  let stakedEsGmxSupplyUsd;
  if (!totalEsGmxStaked.isZero() && gmxPrice) {
    stakedEsGmxSupplyUsd = totalEsGmxStaked.mul(gmxPrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalGmxSupply && !totalGmxSupply.isZero() && gmxPrice) {
    totalSupplyUsd = totalGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableGmx = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.gmxVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusGmxInFeeGmx
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.gmxVesterPairAmount);
    const stakedTokens = processedData.bonusGmxInFeeGmx;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableGmx = availableTokens.mul(stakedTokens).div(divisor);
    }
  }
  let earnMsg;
  if (totalRewardTokensAndGlp && totalRewardTokensAndGlp.gt(0)) {
    let gmxAmountStr;
    if (processedData.gmxInStakedGmx && processedData.gmxInStakedGmx.gt(0)) {
      gmxAmountStr = formatAmount(processedData.gmxInStakedGmx, 18, 2, true) + " MMY";
    }
    let esGmxAmountStr;
    if (processedData.esGmxInStakedGmx && processedData.esGmxInStakedGmx.gt(0)) {
      esGmxAmountStr = formatAmount(processedData.esGmxInStakedGmx, 18, 2, true) + " esMMY";
    }
    let mpAmountStr;
    if (processedData.bonusGmxInFeeGmx && processedData.bnGmxInFeeGmx.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnGmxInFeeGmx, 18, 2, true) + " MP";
    }
    let glpStr;
    if (processedData.glpBalance && processedData.glpBalance.gt(0)) {
      glpStr = formatAmount(processedData.glpBalance, 18, 2, true) + " MLP";
    }
    const amountStr = [gmxAmountStr, esGmxAmountStr, mpAmountStr, glpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        <span>
          You are earning {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndGlp, 18, 2, true)} tokens.
          <br />
          Tokens: {amountStr}.
        </span>
      </div>
    );
  }

  return (
    <Wrapper>
      <div className="total-wrapper-content">
        <div className="router">
          {EarnTabs.filter(x => x.chains.includes(chainId)).map((item) => (
            <div
              key={item.params}
              className={`earn-tab ${
                (item.params && location.pathname.includes(item.params)) ||
                (!item.params && location.pathname === "/earn-v2/")
                  ? "earn-tab-active"
                  : ""
              }`}
            >
              <Link to={`/earn-v2/${item.params || ""}`}>
                <img src={item.img} alt={item.params} />
                <span>{item.name}</span>
              </Link>
            </div>
          ))}
        </div>
        <Switch>
          <div style={{width: "100%"}}>
        {/* {true && <Warning>
          We will be shutting down this website starting from January 30. Please ensure that all of your assets are unstaked before the deadline.
      </Warning>
      } */}
          <Route path={`/earn-v2/vault`} exact>
            {chainId === FANTOM && (
              <VaultFantom setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
            )}
            {chainId === OP && 
              <VaultOP setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
            }
          </Route>
          <Route path={`/earn-v2/mmy-esmmy`} exact>
            <MMYandESMMY
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              gmxPrice={gmxPrice}
              stakedGmxSupplyUsd={stakedGmxSupplyUsd}
              stakedEsGmxSupplyUsd={stakedEsGmxSupplyUsd}
              totalGmxSupply={totalGmxSupply}
              totalSupplyUsd={totalSupplyUsd}
              esGmxSupplyUsd={esGmxSupplyUsd}
              esGmxSupply={esGmxSupply}
              hasMultiplierPoints={hasMultiplierPoints}
              vestingData={vestingData}
              maxUnstakeableGmx={maxUnstakeableGmx}
              multiplierPointsAmount={multiplierPointsAmount}
              bonusGmxInFeeGmx={bonusGmxInFeeGmx}
            />
          </Route>
          <Route path={`/earn-v2/mlp`} exact>
            <EarnMLP setPendingTxns={setPendingTxns} connectWallet={connectWallet} processedData={processedData} />
          </Route>
          <Route path={`/earn-v2/vest`} exact>
            <Vest
              totalRewardTokens={totalRewardTokens}
              vestingData={vestingData}
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
            />
          </Route>
          <Route path={match.path} exact>
          <MMYandESMMY
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              gmxPrice={gmxPrice}
              stakedGmxSupplyUsd={stakedGmxSupplyUsd}
              stakedEsGmxSupplyUsd={stakedEsGmxSupplyUsd}
              totalGmxSupply={totalGmxSupply}
              totalSupplyUsd={totalSupplyUsd}
              esGmxSupplyUsd={esGmxSupplyUsd}
              esGmxSupply={esGmxSupply}
              hasMultiplierPoints={hasMultiplierPoints}
              vestingData={vestingData}
              maxUnstakeableGmx={maxUnstakeableGmx}
              multiplierPointsAmount={multiplierPointsAmount}
              bonusGmxInFeeGmx={bonusGmxInFeeGmx}
            />
          </Route>

          <Route path={`/earn-v2/transfer-account`} exact>
            <BeginAccountTransfer setPendingTxns={setPendingTxns} />
          </Route>
          </div>
        </Switch>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;

  min-height: 900px;

  // background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 286px;

  .earn-tab {
    opacity: 0.6 !important;
  }
  .earn-tab-active {
    opacity: 1 !important;
  }
  @media (max-width: 1024px) {
    /* min-height: 1200px; */
    gap: 0;
  }

  @media (max-width: 600px) {
    gap: 0;
  }

  .total-wrapper-content {
    display: flex;

    gap: 24px;

    width: 1320px;

    /* padding: 0 24px; */

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0 24px;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 0;
    }

    .router {
      margin-top: 64px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      width: 200px;

      a {
        cursor: pointer;
        display: flex;
        align-items: center;

        gap: 10px;

        font-weight: 400;
        font-size: 14px;

        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
      }

      .earn-tab-active {
        a {
          color: white !important;
        }
      }

      @media (max-width: 600px) {
        flex-direction: row;
        align-items: center;

        width: 100%;
        height: 95px;
        background: #191b2e;
        padding: 12px;
        margin-top: 0px;
        gap: unset;
        justify-content: space-between;
        .earn-tab {
          padding: 12px;
          opacity: 1 !important;
        }
        .earn-tab-active {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 12px;
        }
        div {
          flex: 1;
          a {
            display: flex;

            flex-direction: column;
            gap: 6px;
            opacity: 1;

            img {
              width: 32px;
              height: 32px;
            }

            font-weight: 500;
            font-size: 8px;

            color: #ffffff;

            &:nth-child(2) {
              span {
                max-width: 30px;
              }
            }

            span {
              max-width: 38px;

              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
`;
const Warning = styled.div`
  border-radius: 12px;
  background: var(--main-red, #f6475d);
  padding: 12px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 24px;
  @media screen and (max-width: 700px) {
    padding: 16px;
  }
`;
