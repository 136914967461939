import { ethers } from "ethers";
import { getContract } from "./contracts";
import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, TESTNET, FANTOM, MUMBAI, OP } from "./chains";
import { Token } from "domain/tokens";

export const TOKENS: { [chainId: number]: Token[] } = {
  [MAINNET]: [
    {
      name: "Bitcoin (BTCB)",
      symbol: "BTC",
      decimals: 18,
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-bitcoin",
      imageUrl: "https://assets.coingecko.com/coins/images/14108/small/Binance-bitcoin.png",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
      isNative: true,
    },
    {
      name: "Wrapped Binance Coin",
      symbol: "WBNB",
      decimals: 18,
      address: getContract(MAINNET, "NATIVE_TOKEN"),
      isWrapped: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
      baseSymbol: "BNB",
    },
    {
      name: "USD Gambit",
      symbol: "USDG",
      decimals: 18,
      address: getContract(MAINNET, "USDG"),
      isUsdg: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-gambit",
      imageUrl: "https://assets.coingecko.com/coins/images/15886/small/usdg-02.png",
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-usd",
      imageUrl: "https://assets.coingecko.com/coins/images/9576/small/BUSD.png",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x55d398326f99059fF775485246999027B3197955",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
  ],
  [TESTNET]: [
    {
      name: "Bitcoin (BTCB)",
      symbol: "BTC",
      decimals: 8,
      address: "0xb19C12715134bee7c4b1Ca593ee9E430dABe7b56",
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x1958f7C067226c7C8Ac310Dc994D0cebAbfb2B02",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
    },
    {
      name: "Wrapped Binance Coin",
      symbol: "WBNB",
      decimals: 18,
      address: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
      isWrapped: true,
      baseSymbol: "BNB",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
    },
    {
      name: "USD Gambit",
      symbol: "USDG",
      decimals: 18,
      address: getContract(TESTNET, "USDG"),
      isUsdg: true,
      imageUrl: "https://assets.coingecko.com/coins/images/15886/small/usdg-02.png",
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: "0x3F223C4E5ac67099CB695834b20cCd5E5D5AA9Ef",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9576/small/BUSD.png",
    },
  ],
  [ARBITRUM_TESTNET]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: "0x27960f9A322BE96A1535E6c19B3958e80E6a2670",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    // https://github.com/OffchainLabs/arbitrum/blob/950c2f91b2e951cd3764394e0a73eac3797aecf3/packages/arb-ts/src/lib/networks.ts#L65
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xf0DCd4737A20ED33481A49De94C599944a3Ca737",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0x818ED84bA1927945b631016e0d402Db50cE8865f",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
  ],
  [ARBITRUM]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    // {
    //   name: "Chainlink",
    //   symbol: "LINK",
    //   decimals: 18,
    //   address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
    //   isStable: false,
    //   isShortable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
    // },
    // {
    //   name: "Uniswap",
    //   symbol: "UNI",
    //   decimals: 18,
    //   address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
    //   isStable: false,
    //   isShortable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
    // },
    {
      name: "Radiant Capital",
      symbol: "RDNT",
      decimals: 18,
      address: "0x3082CC23568eA640225c2467653dB90e9250AaA0",
      displayDecimals: 4,
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26536/small/Radiant-Logo-200x200.png?1658715865",
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      displayDecimals: 4,
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16547/small/photo_2023-03-29_21.47.00.jpeg?1680097630",
    },
    {
      name: "GMX",
      symbol: "GMX",
      decimals: 18,
      address: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
  ],
  [AVALANCHE]: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      isWrapped: true,
      baseSymbol: "AVAX",
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Ethereum (WETH.e)",
      symbol: "ETH",
      address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Bitcoin (BTC.b)",
      symbol: "BTC",
      address: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Bitcoin (WBTC.e)",
      symbol: "WBTC",
      address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin (USDC.e)",
      symbol: "USDC.e",
      address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      address: "0x130966628846BFd36ff31a822705796e8cb8C18D",
      decimals: 18,
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [FANTOM]: [
    {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
      displayDecimals: 4,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/4001/small/Fantom_round.png?1669652346",
    },
    {
      name: "Wrapped FTM",
      symbol: "WFTM",
      decimals: 18,
      displayDecimals: 4,
      address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      isWrapped: true,
      baseSymbol: "FTM",
      imageUrl: "https://ftmscan.com/token/images/wFtm_32.png",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "BNB",
      symbol: "BNB",
      address: "0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      address: "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/MCDDai_32.png",
    },
    {
      name: "LINK",
      symbol: "LINK",
      decimals: 18,
      displayDecimals: 3,
      address: "0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8",
      isShortable: true,
      imageUrl: "https://ftmscan.com/token/images/chainlink_32.png",
    },
  ],
  [OP]: [
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isShortable: true,
      isNative: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/usdc_32.png",
    },
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 6,
      address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/tether_32.png",
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/MCDDai_32.png",
    },
    {
      name: "OP",
      symbol: "OP",
      decimals: 18,
      displayDecimals: 3,
      address: "0x4200000000000000000000000000000000000042",
      isShortable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/optimism_32.png",
    },
    {
      name: "LINK",
      symbol: "LINK",
      decimals: 18,
      displayDecimals: 3,
      address: "0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
      isShortable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/chainlink_32.png",
    },
    {
      name: "SNX",
      symbol: "SNX",
      decimals: 18,
      displayDecimals: 3,
      address: "0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4",
      isShortable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/SynthetixSNX_32.png",
    },
    // {
    //   name: "FXS",
    //   symbol: "FXS",
    //   decimals: 18,
    //   address: "0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be",
    //   isShortable: true,
    //   imageUrl: "https://optimistic.etherscan.io/token/images/fraxshareop_32.png",
    // },
  ],
  [MUMBAI]: [
    {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
      displayDecimals: 3,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/4001/small/Fantom_round.png?1669652346",
    },
    {
      name: "Wrapped FTM",
      symbol: "WFTM",
      decimals: 18,
      displayDecimals: 3,
      address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      isWrapped: true,
      baseSymbol: "FTM",
      imageUrl: "https://ftmscan.com/token/images/wFtm_32.png",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      address: "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/MCDDai_32.png",
    },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [ARBITRUM]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(ARBITRUM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed MMY",
      symbol: "esGMX",
      address: getContract(ARBITRUM, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [AVALANCHE]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(AVALANCHE, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed MMY",
      symbol: "esGMX",
      address: getContract(AVALANCHE, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [OP]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(OP, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed MMY",
      symbol: "esGMX",
      address: getContract(OP, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(OP, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [FANTOM]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(FANTOM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed MMY",
      symbol: "esGMX",
      address: getContract(FANTOM, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(FANTOM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [ARBITRUM]: {
    // arbitrum
    GMX: {
      name: "GMX",
      symbol: "GMX",
      decimals: 18,
      address: getContract(ARBITRUM, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    GLP: {
      name: "GMX LP",
      symbol: "GLP",
      decimals: 18,
      address: getContract(ARBITRUM, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [AVALANCHE]: {
    // avalanche
    GMX: {
      name: "MMY",
      symbol: "MMY",
      decimals: 18,
      address: getContract(AVALANCHE, "GMX"),
    },
    GLP: {
      name: "MMY LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(AVALANCHE, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      // imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [OP]: {
    GMX: {
      name: "MMY",
      symbol: "MMY",
      decimals: 18,
      address: getContract(OP, "GMX"),
    },
    GLP: {
      name: "MMY LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(OP, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      // imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [FANTOM]: {
    GMX: {
      name: "MMY",
      symbol: "MMY",
      decimals: 18,
      address: getContract(FANTOM, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/28547/small/Logo_MMY.png?1671599329",
    },
    GLP: {
      name: "MMY LP",
      symbol: "MLP",
      decimals: 18,
      address: getContract(FANTOM, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
    },
  },
};

export const ICONLINKS = {
  [ARBITRUM_TESTNET]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      arbitrum: "https://testnet.arbiscan.io/token/0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    },
  },
  [FANTOM]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/mummy-finance",
      fantom: "https://ftmscan.com/address/0x9CB7beAEcdE90a682BDb86AaA32EF032bD9e4079",
    },
    GLP: {
      fantom: "https://ftmscan.com/token/0xcf4D627f1bb9aB2deC8Ec4c928686b2b4165Ec73",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      fantom: "https://ftmscan.com/address/0x74b23882a30290451A17c44f4F05243b6b58C76d",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      fantom: "https://ftmscan.com/address/0x321162cd933e2be498cd2267a90534a804051b11",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      fantom: "https://ftmscan.com/address/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://ftmscan.com/address/0x04068da6c83afcfa0e13ba15a6696662335d5b75",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      fantom: "https://ftmscan.com/address/0x049d68029688eabf473097a2fc38ef61633a3c7a",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      fantom: "https://ftmscan.com/address/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
    },
    FTM: {
      coingecko: "https://www.coingecko.com/en/coins/fantom",
      fantom: "https://ftmscan.com/address/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    },
    BNB: {
      coingecko: "https://www.coingecko.com/en/coins/bnb",
      fantom: "https://ftmscan.com/address/0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454",
    },
  },
  [OP]: {
    GMX: {
      op: "https://optimistic.etherscan.io/address/0x47536f17f4ff30e64a96a7555826b8f9e66ec468",
    },
    GLP: {
      op: "https://optimistic.etherscan.io/address/0xFfB69477FeE0DAEB64E7dE89B57846aFa990e99C",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      op: "https://optimistic.etherscan.io/address/0x4200000000000000000000000000000000000006",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      op: "https://optimistic.etherscan.io/address/0x68f180fcCe6836688e9084f035309E29Bf0A2095",
    },
    "BTC.b": {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      op: "https://optimistic.etherscan.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      op: "https://optimistic.etherscan.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      op: "https://optimistic.etherscan.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      op: "https://optimistic.etherscan.io/address/0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      op: "https://optimistic.etherscan.io/address/0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      op: "https://optimistic.etherscan.io/address/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    },
    OP: {
      coingecko: "https://www.coingecko.com/en/coins/optimism",
      op: "https://optimistic.etherscan.io/address/0x4200000000000000000000000000000000000042",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      op: "https://optimistic.etherscan.io/address/0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
    },
    SNX: {
      coingecko: "https://www.coingecko.com/en/coins/synthetix-network-token",
      op: "https://optimistic.etherscan.io/address/0x8700daec35af8ff88c16bdf0418774cb3d7599b4",
    },
  },
  [ARBITRUM]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      arbitrum: "https://arbiscan.io/token/0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      reserves: "https://arbiscan.io/address/0x489ee077994b6658eafa855c308275ead8097c4a",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      arbitrum: "https://arbiscan.io/address/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      arbitrum: "https://arbiscan.io/address/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "https://arbiscan.io/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      arbitrum: "https://arbiscan.io/address/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      arbitrum: "https://arbiscan.io/address/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      arbitrum: "https://arbiscan.io/address/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      arbitrum: "https://arbiscan.io/address/0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    },
    ARB: {
      coingecko: "https://www.coingecko.com/en/coins/arbitrum",
      arbitrum: "https://arbiscan.io/address/0x912ce59144191c1204e64559fe8253a0e49e6548",
    },
    RDNT: {
      coingecko: "https://www.coingecko.com/en/coins/radiant-capital",
      arbitrum: "https://arbiscan.io/address/0x3082CC23568eA640225c2467653dB90e9250AaA0",
    },
  },
  [AVALANCHE]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      avalanche: "https://snowtrace.io/address/0x47536f17f4ff30e64a96a7555826b8f9e66ec468",
    },
    GLP: {
      avalanche: "https://snowtrace.io/address/0xC2C5968e16ec9fABc39e27f9AbFC07C8Cfba6F16",
      // reserves: "https://core.app/account/0x9ab2De34A33fB459b538c43f251eB825645e8595",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://snowtrace.io/address/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
    },
    WBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      avalanche: "https://snowtrace.io/address/0x50b7545627a5162f82a992c33b87adc75187b218",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin-avalanche-bridged-btc-b",
      avalanche: "https://snowtrace.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      avalanche: "https://snowtrace.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://snowtrace.io/address/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      avalanche: "https://snowtrace.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    },
  },
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  WBTC: "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  USDT: "#67B18A",
  MIM: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  FTM: "#E84142",
  LINK: "#3256D6",
  OP: "#E84142",
  SNX: "#539165",
  BNB: "pink",
  ARB: "#539165",
  GMX: "#9695F8",
  URDNT: "#E84142",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [MAINNET, TESTNET, ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, FANTOM, OP];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenLowerCase(chainId: number, address: string) {
  return address
    ? TOKENS[chainId].find((token) => token.address.toLocaleLowerCase() === address.toLocaleLowerCase())
    : null;
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WAVAX", "WFTM"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

const AVAILABLE_CHART_TOKENS = {
  [FANTOM]: ["ETH", "BTC", "FTM", "BNB", "LINK"],
  [OP]: ["ETH", "BTC", "OP", "LINK", "SNX"],
  [AVALANCHE]: ["AVAX", "ETH", "BTC"],
  [ARBITRUM]: ["ETH", "BTC", "ARB", "GMX", "RDNT"],
};

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;
  return (token.isStable || AVAILABLE_CHART_TOKENS[chainId]?.includes(getNormalizedTokenSymbol(tokenSymbol))) ?? false;
}

export function getTokenSymbol(chainId: number, tokenAddress: string) {
  return getNormalizedTokenSymbol(getTokenLowerCase(chainId, tokenAddress)?.symbol);
}

export function getTokenDecimal(chainId: number, tokenAddress: string) {
  return getTokenLowerCase(chainId, tokenAddress)?.decimals || 18;
}
