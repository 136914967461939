import axios from "axios";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import styled from "styled-components";

import MMY_ETH from "img/MMY-ETH.svg";
import MMY_FTM from "img/MMY-FTM.svg";
import ftmBackdrop from "img/home/ftmBackdrop.svg";
import playIcon from "img/home/ic_play.svg";
import opBackdrop from "img/home/opBackdrop.svg";
import optimizeRightImg from "img/home/optimize-right-img.svg";
import optimizeBg from "img/home/optimize.svg";
import gmx40Icon from "img/ic_mmy_40.svg";
import tokensIcon from "img/tokens.svg";

import useProcessedData from "hooks/useProcessedData";
import useSwitchChain from "hooks/useSwitchChain";
import { getSkeletonClassName } from "utils/skeletonHelper";

import { FANTOM, OP } from "config/chains";
import { formatAmountFree, formatNumber } from "lib/numbers";

const caculateAPYHourly = (apr, time) => {
  const aprTimeFrame = (apr / 4080) * time;
  const A = 1 + aprTimeFrame / (time * 100);
  return (Math.pow(A, time) - 1) * 100;
};

const caculateAPYDaily = (apr, time) => {
  const aprTimeFrame = (apr / 365) * time;
  const A = 1 + aprTimeFrame / (time * 100);
  return (Math.pow(A, time) - 1) * 100;
};

const MMY_WFTM_Address = "0xdc935ffe9f9c972b7b304e0b7e61eb774037e394"; // fix me later
const MMY_WETH_Address = "0x104d0312f95a1b3056435d19668f2272ab1e7df2"; // fix me later

const Optimize = ({ chainId }) => {
  const [switchChain] = useSwitchChain(chainId);

  const [statsMMYWFTM, setStatsMMYWFTM] = useState({
    price: 0,
    apr: 12.9,
  });

  const [statsMMYWETH, setStatsMMYWETH] = useState({
    price: 0,
    apr: 1,
  });

  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const usedChainId = chainId === FANTOM || chainId === OP ? chainId : FANTOM;
  const processedData = useProcessedData(usedChainId);

  let aprTotalGmx;
  let apyGmx;
  let dailyApyGmx;
  aprTotalGmx = parseFloat(formatAmountFree(processedData?.gmxAprTotal, 2));
  apyGmx = aprTotalGmx ? formatNumber(caculateAPYHourly(aprTotalGmx, 4080), 2) : null;
  dailyApyGmx = aprTotalGmx ? formatNumber(caculateAPYDaily(aprTotalGmx, 1), 4) : null;

  let aprTotalGlp;
  let apyGlp;
  let dailyApyGlp;
  aprTotalGlp = parseFloat(formatAmountFree(processedData?.glpAprTotal, 2));
  apyGlp = aprTotalGlp ? formatNumber(caculateAPYHourly(aprTotalGlp, 4080), 2) : null;
  dailyApyGlp = aprTotalGlp ? formatNumber(caculateAPYDaily(aprTotalGlp, 1), 4) : null;

  let aprTotalGmxFtm;
  let apyGmxFtm;
  let dailyApyGmxFtm;
  aprTotalGmxFtm = parseFloat(statsMMYWFTM.apr);
  apyGmxFtm = aprTotalGmxFtm ? formatNumber(caculateAPYHourly(aprTotalGmxFtm, 4080), 2) : null;
  dailyApyGmxFtm = aprTotalGmxFtm ? formatNumber(caculateAPYDaily(aprTotalGmxFtm, 1), 4) : null;

  const totalApr = statsMMYWFTM.apr;

  const totalApy = !isNaN(formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2)
    : "--";

  const [daily, setDaily] = useState("0.0000");

  useEffect(() => {
    if (!isNaN(totalApy)) {
      setDaily(formatNumber(caculateAPYDaily(Number(totalApr), 1), 4));
    }
  }, [totalApy, totalApr]);

  let aprTotalGmxEth;
  let apyGmxEth;
  let dailyApyGmxEth;
  aprTotalGmxEth = parseFloat(statsMMYWETH.apr);
  apyGmxEth = aprTotalGmxEth ? formatNumber(caculateAPYHourly(aprTotalGmxEth, 4080), 2) : null;
  dailyApyGmxEth = aprTotalGmxEth ? formatNumber(caculateAPYDaily(aprTotalGmxEth, 1), 4) : null;

  useEffect(() => {
    const fetchStatsMMYWFTM = async () => {
      const res = await axios.get(`https://api.mummy.finance/api/tokens/info?symbols=MMY/FTM&chain=FANTOM`, {});

      if (res && res.data) {
        const { price } = res.data["MMY/FTM"];

        setStatsMMYWFTM({
          ...statsMMYWFTM,
          price,
        });
      }
    };

    const fetchStatsMMYWETH = async () => {
      const res = await axios.get(`https://api.velodrome.finance/api/v1/pairs`, {});

      if (
        res &&
        res.data &&
        res.data.data &&
        res.data.data.find((item) => item.address.toLowerCase() === MMY_WETH_Address)
      ) {
        const { apr, tvl, totalSupply } = res.data.data.find((item) => item.address.toLowerCase() === MMY_WETH_Address);

        setStatsMMYWETH({
          price: tvl / totalSupply,
          apr,
        });
      }
    };

    setInterval(() => {
      fetchStatsMMYWFTM();
      fetchStatsMMYWETH();
    }, 5000);
  }, []);

  return (
    <Wrapper>
      <div className="container">
        <div className="left">
          <div className="heading">
            <Fade bottom>
              <div className="wrapper">
                <Title>Optimize performance with Mummy Vault</Title>
                <Description>
                  Our cutting-edge auto-compound Yield Farming strategy, designed to empower investors to earn rewards
                  effortlessly.
                </Description>
              </div>
            </Fade>
            {isMobileOrTablet && (
              <Fade right>
                <img src={optimizeRightImg} alt="rightImg" />
              </Fade>
            )}
          </div>
          <Fade left>
            <Container>
              <TokenItem>
                <TokenInfo>
                  <img src={ftmBackdrop} alt="ftmBackdrop" className="backdrop" />
                  <div className="symbol">
                    <img src={gmx40Icon} alt="mmy_icon" />
                    <div className="symbol">MMY</div>
                  </div>
                  <div className="stats">
                    <div className="stat-row">
                      APY: <span className={`value ${getSkeletonClassName(apyGmx)}`}>{apyGmx}%</span>
                    </div>
                    <div className="stat-row">
                      Daily: <span className={`value ${getSkeletonClassName(dailyApyGmx)}`}>{daily}%</span>
                    </div>
                  </div>
                </TokenInfo>
                <ViewPoolBtn to="earn-v2/vault" onClick={() => switchChain(FANTOM)}>
                  <img src={playIcon} alt="playIcon" />
                </ViewPoolBtn>
              </TokenItem>
              <TokenItem>
                <TokenInfo>
                  <img src={ftmBackdrop} alt="ftmBackdrop" className="backdrop" />
                  <div className="symbol">
                    <img src={tokensIcon} alt="tokensIcon" />
                    <div className="symbol">MLP</div>
                  </div>
                  <div className="stats">
                    <div className="stat-row">
                      APY: <span className={`value ${getSkeletonClassName(apyGlp)}`}>{apyGlp}%</span>
                    </div>
                    <div className="stat-row">
                      Daily: <span className={`value ${getSkeletonClassName(dailyApyGlp)}`}>{dailyApyGlp}%</span>
                    </div>
                  </div>
                </TokenInfo>
                <ViewPoolBtn to="earn-v2/vault" onClick={() => switchChain(FANTOM)}>
                  <img src={playIcon} alt="playIcon" />
                </ViewPoolBtn>
              </TokenItem>
              <TokenItem>
                <TokenInfo>
                  <img src={ftmBackdrop} alt="ftmBackdrop" className="backdrop" />
                  <div className="symbol">
                    <img src={MMY_FTM} alt="MMY_FTM" />
                    <div className="symbol">MMY-WFTM</div>
                  </div>
                  <div className="stats">
                    <div className="stat-row">
                      APY: <span className={`value ${getSkeletonClassName(apyGmxFtm)}`}>{totalApy}% </span>
                    </div>
                    <div className="stat-row">
                      Daily: <span className={`value ${getSkeletonClassName(dailyApyGmxFtm)}`}>{dailyApyGmxFtm}%</span>
                    </div>
                  </div>
                </TokenInfo>
                <ViewPoolBtn to="earn-v2/vault" onClick={() => switchChain(FANTOM)}>
                  <img src={playIcon} alt="playIcon" />
                </ViewPoolBtn>
              </TokenItem>
              <TokenItem className="op">
                <TokenInfo>
                  <img src={opBackdrop} alt="opBackdrop" className="backdrop" />
                  <div className="symbol">
                    <img src={MMY_ETH} alt="MMY_ETH" />
                    <div className="symbol">MMY-WETH</div>
                  </div>
                  <div className="stats">
                    <div className="stat-row">
                      APY: <span className={`value ${getSkeletonClassName(apyGmxEth)}`}>{apyGmxEth}%</span>
                    </div>
                    <div className="stat-row">
                      Daily: <span className={`value ${getSkeletonClassName(dailyApyGmxEth)}`}>{dailyApyGmxEth}%</span>
                    </div>
                  </div>
                </TokenInfo>
                <ViewPoolBtn to="earn-v2/vault" onClick={() => switchChain(OP)}>
                  <img src={playIcon} alt="playIcon" />
                </ViewPoolBtn>
              </TokenItem>
            </Container>
          </Fade>
        </div>
        {!isMobileOrTablet && (
          <Fade right>
            <img src={optimizeRightImg} alt="rightImg" />
          </Fade>
        )}
      </div>
    </Wrapper>
  );
};

const Title = styled.div`
  font-weight: 700;
  font-size: 56px;
  line-height: 140%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (max-width: 1023px) {
    font-size: 40px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
  color: #ffffff99;
  max-width: 697px;
  margin-top: 24px;
`;

const ViewPoolBtn = styled(Link)`
  width: 32px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #080715;
  background-color: #03f5ae;
  padding: 4px;
  border-radius: 8px;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    background: #04d397;
    color: #080715 !important;
  }
`;

const TokenInfo = styled.div`
  .symbol {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;

    img[alt="mmy_icon"] {
      width: 36px;
      height: 36px;
    }
    img:not([alt="mmy_icon"]) {
      width: 54px;
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
  }

  .stats {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    .stat-row {
      color: #ffffff99;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;

      .value {
        color: #03f5ae;

        &.skeleton-box {
          width: 50px !important;
        }
      }

      &:last-child {
        padding-left: 16px;
        border-left: 1px solid #ffffff1a;
      }
    }
  }
`;

const TokenItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #ffffff1a;
  border-radius: 24px;
  flex: 1;
  background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

  &.op {
    background: linear-gradient(90deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 16px;
  }
`;

const Wrapper = styled.div`
  background-image: url(${optimizeBg});
  margin: 120px 0 !important;

  .container {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding: 0 24px;

    .left {
      display: flex;
      flex-direction: column;
      gap: 64px;
    }
  }

  @media (max-width: 1023px) {
    background-image: none;
    margin-top: 80px !important;
    padding: 0 24px;

    .container {
      padding: 0;
      .left {
        gap: 40px;
        .heading {
          display: flex;
          align-items: flex-start;
          gap: 24px;
        }
      }
    }

    img[alt="rightImg"] {
      width: 248px;
      height: 248px;
    }
  }

  @media (max-width: 767px) {
    padding: 0 16px;

    .container {
      .left .heading {
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

export default Optimize;
