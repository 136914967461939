import { Bridge } from "@socket.tech/plugin";
import { useWeb3React } from "@web3-react/core";
import "./bridge.scss";
import { useChainId } from "lib/chains";
import { FANTOM } from "config/chains";

function SocketBridge() {
  const { library } = useWeb3React();
  const { chainId } = useChainId();

  const defaultSourceNetwork = 1;
  const defaultDestNetwork = chainId;

  const defaultSourceToken = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
  const defaultDestToken = chainId === FANTOM ? "0x01e77288b38b416F972428d562454fb329350bAc" : "0x47536f17f4ff30e64a96a7555826b8f9e66ec468";
  return (
    <Bridge
      className="bright"
      provider={library}
      defaultSourceNetwork={defaultSourceNetwork}
      defaultDestNetwork={defaultDestNetwork}
      defaultSourceToken={defaultSourceToken}
      defaultDestToken={defaultDestToken}
      API_KEY="645b2c8c-5825-4930-baf3-d9b997fcd88c"
      enableRefuel={true}
      customize={{
        accent: "rgb(131,249,151)",
        interactive: "rgb(0,0,0)",
        onAccent: "rgb(0,0,0)",
        onInteractive: "rgb(240,240,240)",
        primary: "rgb(31,34,44)",
        secondary: "rgb(68,69,79)",
        secondaryText: "rgb(200,200,200)",
        text: "rgb(255,255,255)",
        fontSize: 20,
      }}
    />
  );
}

export default SocketBridge;
