import React from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";

import reloadIc from "img/reload.svg";
import Button from "components/Common/Button";

const getClientTimezoneOffset = () => {
  return -new Date().getTimezoneOffset() / 60;
};

const MILISECONDS_IN_HOUR = 3600000;

const DateFilter = ({ startDate, endDate, handleEndDate, handleStartDate, handleSearch, handleRefresh }) => {
  // This uss for UTC timezone
  return (
    <StyledDateFilter>
      {/* Start date */}
      <span className="title">Time:</span>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        onChange={(date) =>
          handleStartDate(+date.setHours(0, 0, 0, 0) + getClientTimezoneOffset() * MILISECONDS_IN_HOUR)
        }
        timeFormat="number"
        placeholderText="YYYY-MM-DD"
        selected={startDate}
      />

      {/* To date */}
      <span className="title">To:</span>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        onChange={(date) => handleEndDate(+date.setHours(0, 0, 0, 0) + getClientTimezoneOffset() * MILISECONDS_IN_HOUR)}
        placeholderText="YYYY-MM-DD"
        selected={endDate}
      />

      {/* Search button */}
      <StyledButton onClick={handleSearch}>Search</StyledButton>
      <span className="reload" onClick={handleRefresh}>
        <img src={reloadIc} alt="" />
      </span>
    </StyledDateFilter>
  );
};

const StyledButton = styled(Button)`
  color: rgb(4, 211, 151);
  padding: 0;
  border: none;

  &:hover {
    background: none;

    .btn-label {
      opacity: 0.5;
    }
  }

  .btn-label {
    margin: 0;
    font-size: 13px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const StyledDateFilter = styled.div`
  @media (max-width: 1023px) {
    margin-top: 12px;
    font-size: 13px;
    flex-wrap: wrap;
    gap: 10px;
    > *:not(.react-datepicker__tab-loop) {
      margin-right: 0px !important;
    }
    .react-datepicker-wrapper {
      &:nth-child(4) {
        margin-right: 25px !important;
      }
    }
    input {
      font-size: 13px;
    }
  }

  .duration {
    display: flex;
    gap: 20px;

    > *:hover {
      color: rgb(4, 211, 151);
    }
  }

  display: flex;
  align-items: center;
  /* gap: 10px; */

  > *:not(.react-datepicker__tab-loop) {
    margin-right: 10px;
  }

  span {
    cursor: pointer;
  }

  img {
    width: 16px;
    height: 16px;
  }

  .title {
    margin-right: 8px;
  }

  .reload {
    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(110%) contrast(110%);
    }
    &:hover {
      img {
        filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
      }
    }
  }
`;

export default DateFilter;
