import React from "react";
import noItemFoundIc from "img/ic_noitemfound.svg";

const NoItemFound = () => {
  return (
    <div
      style={{
        margin: "0 auto",
        width: "100%",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <img src={noItemFoundIc} alt="" />
      <span>No item found</span>
    </div>
  );
};

export default NoItemFound;
