import { FC } from "react";
import styled from "styled-components";
import icLink from "img/icons/link-green.svg";
import { Link } from "react-router-dom";
import { TXT } from "../multichainTxt";
import { useChainId } from "lib/chains";
import { FANTOM } from "config/chains";
const HowTo: FC = () => {
  const { chainId } = useChainId();
  const Steps = [
    {
      name: "1",
      title: "Mint NFT",
      content: "The earlier you mint, the higher the power to earn rewards.",
      link: "/nft?min=true",
      linkName: "Mint now",
      isMint: true,
    },
    {
      name: "2",
      title: "Stake",
      content: `Stake your NFT to earn rewards from Community Treasury on ${chainId === FANTOM ? "Fantom" : "Optimism"}.`,
      link: "/nft",
      linkName: "",
      external: false,
    },
    {
      name: "3",
      title: " Earn",
      content: `Earn rewards in $${TXT[chainId]?.nativeToken}. The longer you stake, the higher rewards you earn from Mummy Finance.`,
      link: "/nft",
      linkName: "",
      external: false,
    },
  ];
  return (
    <StyledHowTo>
      <div className="container">
        <SectionTitle>How to get reward with {TXT[chainId]?.clubName}</SectionTitle>
        <Desc>Very simple, you just need to hold Mummy NFT</Desc>
        <HowToWrap>
          {Steps.map((item, idx) => (
            <Item>
              <div className="step-number">{idx + 1}</div>
              <div className="step-content">
                <div className="label">{item.title}</div>
                <div className="content">{item.content}</div>
                {item?.external ? (
                  <Link to={item.link} className="howto-link">
                    {" "}
                    {item.linkName} <img alt="mint" src={icLink} />{" "}
                  </Link>
                ) : (
                  <Link to={item.link} className="howto-link">
                    {item.linkName}
                  </Link>
                )}
              </div>
            </Item>
          ))}
        </HowToWrap>
      </div>
    </StyledHowTo>
  );
};

export default HowTo;

const StyledHowTo = styled.section`
  color: #ffffff;
  margin-bottom: 152px;
  @media screen and (max-width: 991px) {
    margin-bottom: 104px;
  }
  @media screen and (max-width: 700px) {
    margin-bottom: 80px;
  }
`;

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  text-align: center;

  @media screen and (max-width: 700px) {
    font-size: 24px;
    padding: 0 24px;
  }
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 8px 0 24px 0;
  text-align: center;
`;
const Item = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  @media screen and (max-width: 700px) {
    padding: 16px;
  }
  .step-number {
    background: linear-gradient(90deg, #03f5ae 0%, #e3fff7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
    font-size: 64px;
    line-height: 100% !important;
    margin-right: 24px;
  }
  .step-content {
    .label {
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: #fff;
    }
    .content {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.6);
      margin: 9px 0;
    }
    .howto-link {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #03f5ae;
      text-decoration: none;
      img {
        margin-left: 8px;
      }
    }
    @media screen and (max-width: 700px) {
      .howto-link {
        img {
          margin-left: 4px;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .step-number {
      font-size: 48px;
    }
  }
`;

const HowToWrap = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media screen and (max-width: 991px) {
    grid-template-columns: 100%;
  }
  @media screen and (max-width: 700px) {
    gap: 16px;
  }
`;
