// import ExternalLink from "components/ExternalLink/ExternalLink";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import Modal from "components/Modal/Modal";
import { formatAmount } from "lib/numbers";
import arrowRightIc from "img/ic_arrowright16.svg";

import Pagination from "../Pagination";
import NoItemFound from "../NoItemFound";
import Loader from "components/Common/Loader";
import { getApiAnalytics } from "pages/Analytics/config";
import { getTokenSymbol } from "config/tokens";
import Tooltip from "components/Tooltip/Tooltip";
import inInfo from "img/ic_info.svg";
const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const calcPnl = (realisedPnl) => {
  return formatAmount(realisedPnl, 30, 3, true);
};

const isPnlPlus = (realisedPnl) => {
  const pnl = calcPnl(realisedPnl);

  return !pnl.includes("-");
};

const PositionModal = (props) => {
  const { isVisible, setIsVisible, data } = props;
  const renderTitle = () => {
    return (
      <>
        <div style={{ color: "rgb(255,227,169)" }}>{data.title}</div>
        <div style={{ color: "rgb(52, 245, 174)" }}>{data.longOrShort}</div>
      </>
    );
  };
  return (
    <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={renderTitle()} isBottomMobal>
      <StyledModal>
        <div className="close-on child">
          <div className="label">Closed on</div>

          <div className="value">{data.closeOn}</div>
        </div>

        <div className="size child">
          <div className="label">Size</div>

          <div className="value">${data.size}</div>
        </div>

        <div className="collateral child">
          <div className="label">Collateral</div>
          <div className="value">${data.collateral}</div>
        </div>

        <div className="pnl child">
          <div className="label">PnL</div>
          <div className="value">
            {" "}
            <div style={{ color: isPnlPlus(data.pnl) ? "#34f5ae" : "rgb(246, 71, 93)" }}>
              {isPnlPlus(data.pnl) ? "+" : "-"}$
              {calcPnl(data.pnl).includes("-") ? calcPnl(data.pnl).slice(1) : calcPnl(data.pnl)}
            </div>
          </div>
        </div>

        <div className="fee child">
          <div className="label">Fee</div>
          <div className="value">${data.fee}</div>
        </div>

        <div className="fee child">
          <div className="label">Entry Price</div>
          <div className="value">${data.entryPrice}</div>
        </div>

        <div className="fee child">
          <div className="label">Exit Price</div>
          <div className="value">${data.exitPrice}</div>
        </div>

        <div className="view-details child">{/* <Link to={`/analytic/position/${id}`}>View details</Link> */}</div>
      </StyledModal>
    </Modal>
  );
};

export default function ClosePositions({ account, chainId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const [perPage, setPerPage] = useState(10);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await axios(
        `${getApiAnalytics(
          chainId
        )}leaderboard/trader-list-closed?page=${currentPage}&pageSize=${perPage}&address=${account}`
      );

      setRows(response.data.count);
      setData(response.data.rows);
      setIsLoading(false);
    };

    fetchData();
  }, [account, currentPage, perPage, chainId]);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  if (isSmallScreen)
    return (
      <>
        {!isLoading ? (
          data.map(
            (
              { id, settledTimestamp, isLong, size, collateral, realisedPnl, fee, averagePrice, exitPrice, indexToken },
              index
            ) => (
              <React.Fragment key={id}>
                <div className="table-mobile">
                  <div className="header">
                    <div
                      className="market"
                      style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                      onClick={() => {
                        setIsVisible(true);
                        setModalData({
                          title: `${getTokenSymbol(chainId, indexToken)}/USD`,
                          longOrShort: `${isLong ? "LONG" : "SHORT"}`,
                          closeOn: `${moment(settledTimestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}`,
                          size: `${formatAmount(size, 30, 3, true)}`,
                          collateral: `${formatAmount(collateral, 30, 3, true)}`,
                          pnl: realisedPnl,
                          fee: `${formatAmount(fee, 30, 3, true)}`,
                          entryPrice: `${formatAmount(averagePrice, 30, 3, true)}`,
                          exitPrice: `${formatAmount(exitPrice, 30, 3, true)}`,
                        });
                      }}
                    >
                      <div className="wrapper">
                        <div className="coin">{getTokenSymbol(chainId, indexToken)}/USD</div>
                        <div
                          style={{
                            color: isLong ? "#34f5ae" : "rgb(246, 71, 93)",
                          }}
                          className="position"
                        >
                          {isLong ? "LONG" : "SHORT"}
                        </div>
                      </div>
                      <div className="icon">
                        <img src={arrowRightIc} alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="content open-position">
                    <div className="close-on child">
                      <div className="label">Closed on</div>

                      <div className="value">{moment(settledTimestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
                    </div>

                    <div className="size child">
                      <div className="label">Size</div>

                      <div className="value">${formatAmount(size, 30, 3, true)}</div>
                    </div>

                    <div className="pnl child">
                      <div className="label">
                        <Tooltip
                          handle={`PnL`}
                          position="left-bottom"
                          renderContent={() => {
                            return <p className="text-white">PnL after fees</p>;
                          }}
                        />
                      </div>
                      <div className="value">
                        {" "}
                        <div
                          style={{
                            color:
                              Number(calcPnl(realisedPnl)) === 0
                                ? "#ffffff"
                                : isPnlPlus(realisedPnl)
                                ? "#34f5ae"
                                : "rgb(246, 71, 93)",
                          }}
                        >
                          {isPnlPlus(realisedPnl) ? "+" : "-"}$
                          {calcPnl(realisedPnl).includes("-") ? calcPnl(realisedPnl).slice(1) : calcPnl(realisedPnl)}
                        </div>
                      </div>
                    </div>

                    <div className="fee child">
                      <div className="label">Fee</div>
                      <div className="value">${formatAmount(fee, 30, 3, true)}</div>
                    </div>
                    <div className="view-details child">
                      <Link to={`/analytic/position/${id}`}>View details</Link>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          )
        ) : (
          // <Loader />
          <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
        )}
        {data.length > 0 ? (
          <Pagination
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            row={row}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <NoItemFound />
        )}
        <PositionModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          data={modalData}
          title={
            <>
              <div style={{ color: "rgb(255,227,169)", fontWeight: 500 }}>CAKE/USD</div>
              <div style={{ color: "#34f5ae", fontWeight: 600, fontSize: "12px" }}>SHORT</div>
            </>
          }
        />
      </>
    );

  return (
    <>
      {!isLoading ? (
        data.length > 0 ? (
          <>
            <div className="table">
              <div className="header">
                <div className="time child">Closed on</div>
                <div className="market child">Market</div>
                <div className="size child">Size</div>
                <div className="collateral child">Collateral</div>
                <div className="pnl child">
                  <Tooltip
                    handle={`PnL`}
                    position="left-bottom"
                    renderContent={() => {
                      return <p className="text-white">PnL after fees</p>;
                    }}
                  />
                </div>
                <div className="fee child">Fee</div>
                <div className="entry-price child">Entry Price</div>
                <div className="exit-price child">Exit Price</div>
                <div className="view-details child"></div>
              </div>

              {data.map(
                ({
                  id,
                  settledTimestamp,
                  isLong,
                  size,
                  collateral,
                  realisedPnl,
                  netPnl,
                  pnlAfterFee,
                  fee,
                  averagePrice,
                  exitPrice,
                  txhash,
                  indexToken,
                }) => (
                  <div className="content" key={id}>
                    <div className="time child">{moment(settledTimestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
                    <div className="market child">
                      <div className="wrapper">
                        <div className="coin">{getTokenSymbol(chainId, indexToken)}/USD</div>
                        <div
                          style={{
                            color: isLong ? "#34f5ae" : "rgb(246, 71, 93)",
                          }}
                          className="position"
                        >
                          {isLong ? "LONG" : "SHORT"}
                        </div>
                      </div>
                    </div>
                    <div className="size child">${formatAmount(size, 30, 3, true)}</div>
                    <div className="collateral child">${formatAmount(collateral, 30, 3, true)}</div>
                    <div className="pnl child">
                      <div
                        style={{
                          color:
                            Number(calcPnl(pnlAfterFee)) === 0
                              ? "#ffffff"
                              : isPnlPlus(pnlAfterFee)
                              ? "#34f5ae"
                              : "rgb(246, 71, 93)",
                        }}
                      >
                        {isPnlPlus(pnlAfterFee) ? "+" : "-"}$
                        {calcPnl(pnlAfterFee).includes("-") ? calcPnl(pnlAfterFee).slice(1) : calcPnl(pnlAfterFee)}
                      </div>
                    </div>
                    <div className="fee child">${formatAmount(fee, 30, 3, true)}</div>
                    <div className="entry-price child">${formatAmount(averagePrice, 30, 3, true)}</div>
                    <div className="exit-price child">${formatAmount(exitPrice, 30, 3, true)}</div>
                    <div className="view-details child">
                      <Link to={`/analytic/position/${id}`}>View details</Link>
                    </div>
                  </div>
                )
              )}
            </div>

            <Pagination
              rowsPerPage={perPage}
              setRowsPerPage={setPerPage}
              row={row}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <NoItemFound />
        )
      ) : (
        // <Loader />
        <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
      )}
    </>
  );
}
