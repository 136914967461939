import { Trans } from "@lingui/macro";
import TokenIcon from "img/earn/mlp_coin.svg";
import "./Earn.scss";
import { GLP_DECIMALS, USD_DECIMALS } from "lib/legacy";
import { formatKeyAmount } from "lib/numbers";
import { Link } from "react-router-dom";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Tooltip from "components/Tooltip/Tooltip";
import { getConstant } from "config/chains";
import { useChainId } from "lib/chains";
const EarnMLP = (props) => {
  const { processedData } = props;
  const { chainId } = useChainId();
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  return (
    <div className="earn-content-container">
      <div className="tab-content-title">MLP</div>
      <div className="tab-content-desc">Earn rewards by staking MLP token.</div>
      <div className="tab-main-content">
        <div className="main-content left-content">
          {/* <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">Rewards</div>
              <div className="text-button-green">
                <Link to="/earn-v2/">Claim page</Link>
              </div>
            </div>
            <div className="rewards-container">
              <div className="reward-info">
                <div className="title">
                  {nativeTokenSymbol} ({wrappedTokenSymbol})
                </div>
                {processedData?.feeGlpTrackerRewards ? (
                  <div className="value">
                    {formatKeyAmount(processedData, "feeGlpTrackerRewards", 18, 4)}{" "}
                    <span>${formatKeyAmount(processedData, "feeGlpTrackerRewardsUsd", USD_DECIMALS, 2, true)}</span>
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  </div>
                )}
              </div>

              <div className="reward-info">
                <div className="title">esMMY</div>
                {processedData.stakedGlpTrackerRewards ? (
                  <div className="value">
                    {formatKeyAmount(processedData, "stakedGlpTrackerRewards", 18, 4)}{" "}
                    <span>${formatKeyAmount(processedData, "stakedGlpTrackerRewardsUsd", USD_DECIMALS, 2, true)}</span>
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  </div>
                )}
              </div>
            </div>
          </div> */}
          <div className="left-box-content stake-box-content">
            <div className="main-title-container">
              <div className="main-title">MLP</div>
            </div>
            <div className="box-desc">Stake MLP to earn {nativeTokenSymbol}, esMMY, and MPs.</div>
            <div className="rewards-es-container">
              <div className="account-info">
                <div className="reward-info">
                  <div className="title">Wallet</div>
                  {processedData.glpBalance ? (
                    <div className="value">
                      {formatKeyAmount(processedData, "glpBalance", GLP_DECIMALS, 2, true)} MLP{" "}
                      <span>${formatKeyAmount(processedData, "glpBalanceUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="reward-info">
                  <div className="title">Staked</div>
                  {processedData.glpBalance ? <div className="value">
                    {formatKeyAmount(processedData, "glpBalance", GLP_DECIMALS, 2, true)} MLP{" "}
                    <span>${formatKeyAmount(processedData, "glpBalanceUsd", USD_DECIMALS, 2, true)}</span>
                  </div> : <div className="value">
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  </div>}

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content right-content">
          <div className="right-content-border-box">
            <div className="box-main-price">
              <div className="price-info">
                <div className="title">MLP price</div>
                {processedData.glpPrice ? <div className="value">${formatKeyAmount(processedData, "glpPrice", USD_DECIMALS, 3, true)}</div> : <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />}

              </div>
              <img src={TokenIcon} alt="mmy-tokens" />
            </div>
            <div className="buy-btn-container">
              <Link className="App-button-option App-card-option" to="/buy_mlp">
                <span>Buy MLP</span>
              </Link>
              <Link className="App-button-option App-card-option border-btn" to="/buy_mlp#redeem">
                <span>Sell MLP</span>
              </Link>
            </div>
            <div className="box-devider">
              <div className="divider-title">MLP APR</div>
              <div className="divider-line" />
            </div>
            <div className="box-apr">
              {processedData.glpAprTotal ? <Tooltip
                handle={`${formatKeyAmount(processedData, "glpAprTotal", 2, 2, true)}%`}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                        value={`${formatKeyAmount(processedData, "glpAprForNativeToken", 2, 2, true)}%`}
                        showDollar={false}
                      />
                      <StatsTooltipRow
                        label="Escrowed MMY APR"
                        value={`${formatKeyAmount(processedData, "glpAprForEsGmx", 2, 2, true)}%`}
                        showDollar={false}
                      />
                      <div style={{ marginTop: "8px", fontWeight: 400 }}>
                        <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                      </div>
                    </>
                  );
                }}
              /> : <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />}

            </div>
            <div className="box-token-info">
              <div className="title">Total Staked</div>
              {processedData.glpSupply ? <div className="value">
                {formatKeyAmount(processedData, "glpSupply", 18, 0, true)} MLP ($
                {formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 0, true)})
              </div> : <div className="value">
                <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
              </div>}

            </div>
            <div className="box-token-info">
              <div className="title">Total Supply</div>
              {processedData.glpSupply ? <div className="value">
                {formatKeyAmount(processedData, "glpSupply", 18, 0, true)} MLP ($
                {formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 0, true)})
              </div> : <div className="value">
                <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EarnMLP;
