import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";

import swiperImg1 from "../images/swiper1.svg";
import swiperImg2 from "../images/swiper2.svg";
import swiperImg3 from "../images/swiper3.svg";

const Carousel = () => {
  SwiperCore.use([Autoplay, Pagination]);

  return (
    <Wrapper className="container">
      <Swiper
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        noSwiping={true}
        speed={4000}
        slidesPerView={1}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        <CarouselItem>
          <img src={swiperImg1} alt="" />
        </CarouselItem>
        <CarouselItem>
          <img src={swiperImg2} alt="" />
        </CarouselItem>
        <CarouselItem>
          <img src={swiperImg3} alt="" />
        </CarouselItem>
      </Swiper>
    </Wrapper>
  );
};

const CarouselItem = styled(SwiperSlide)`
  height: 212px;
  border-radius: 24px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1023px) {
    height: 200px;
    img {
      height: unset;
    }
  }

  @media screen and (max-width: 768px) {
    height: 171px;
  }
`;

const Wrapper = styled.div`
  margin-top: 37px !important;
  padding: 800px 24px 0;

  .swiper {
    height: 276px;

    .swiper-pagination-bullets.swiper-pagination-horizontal {
      top: 231px;

      .swiper-pagination-bullet {
        background-color: #d9d9d93d;
        opacity: 1;
        &-active {
          background-color: #d9d9d9;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding-top: 797px;

    .swiper {
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        top: 190px;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 794px 16px 0;
  }
`;

export default Carousel;
