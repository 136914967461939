import { useWeb3React } from "@web3-react/core";
import { getPageTitle } from "lib/legacy";
import styled from "styled-components";

import { getWhitelistedTokens } from "config/tokens";
import { useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { bigNumberify } from "lib/numbers";

import Footer from "components/Footer/Footer";
import SEO from "components/Common/SEO";
import Markets from "./components/Markets";
import Stats from "./components/Stats";
import Tokens from "./components/Tokens";

import patternImg from "img/dashboard/pattern.svg";

export default function DashboardV3() {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  return (
    <SEO title={getPageTitle("Dashboard")}>
      <Wrapper>
        <div className="default-container page-layout DashboardV2">
          <Stats chainId={chainId} />
          <Tokens chainId={chainId} active={active} library={library} />
          <Markets chainId={chainId} />
        </div>
        <Footer />
      </Wrapper>
    </SEO>
  );
}

const Wrapper = styled.div`
  background-image: url(${patternImg});
  background-repeat: no-repeat;
  background-size: contain;
`;
