import { ARBITRUM, FANTOM, OP } from "config/chains";

export const API_PATH = {
  [FANTOM]: "",
  [OP]: "optimism/",
  [ARBITRUM]: "arbitrum/"
};

export const getApiAnalytics = (chainId) => {
  return `https://api.mummy.finance/${API_PATH[chainId]}`;
};

export const EXTERNAL_LINK = {
  [FANTOM]: {
    moreDetail: "https://medium.com/@mummyftm/mummy-referral-contest-with-15-000-ftm-in-total-prize-e87d07ec662c",
  },
  [OP]: {
    moreDetail:
      "https://medium.com/@mummyftm/10-000-worth-of-prizes-to-be-won-in-the-mummyblitz-trading-contest-on-optimism-c8a8ec7b00c3",
  },
  [ARBITRUM]: {
    moreDetail:
      "https://medium.com/@mummyftm/10-000-worth-of-prizes-to-be-won-in-the-mummyblitz-trading-contest-on-optimism-c8a8ec7b00c3",
  },
};
