import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import ExternalLink from "components/ExternalLink/ExternalLink";
import OpenPositions from "./Account/OpenPositions";
import ClosePositions from "./Account/ClosePositions";
import Orders from "./Account/Orders";

import Wrapper from "./Account.styled";

import share from "img/share.svg";
import icVolume from "img/ic-volume.svg";
import icPnl from "img/ic-pnl.svg";
import icAccFee from "img/ic-acc-fee.svg";
import TradeHistoryAcc from "./Account/TradeHistoryAcc";
import BackUrl from "./BackUrl";
import Avatar from "components/Avatar";
import axios from "axios";
import { formatAmount } from "lib/numbers";
import { useHistory, useParams } from "react-router-dom";
import { getApiAnalytics } from "../config";
import { getExplorerUrl } from "config/chains";
import useYourNFTId from "hooks/useYourNFTId";

const StyledExternalLink = styled(ExternalLink)`
  &:hover {
    img {
      filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
    }
  }
`;

const TABS = ["Open Positions", "Closed Positions", "Orders", "Trade History"];

export default function Overview({ chainId }) {
  const [data, setData] = useState({
    totalTradingVolume: 0,
    totalPnl: 0,
    totalNetPnl:0,
    totalFee: 0,
    totalOpenPosition: 0,
    totalClosePosition: 0,
    totalOpenOrder: 0,
  });

  const history = useHistory();
  const { account } = useParams() as any;
  const NFTid = useYourNFTId(account);

  const [tab, setTab] = useState(history.action === "POP" ? "Closed Positions" : "Open Positions");

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios(`${getApiAnalytics(chainId)}leaderboard/trader/${account}`);
      if (response?.data) setData(response.data);
    };
    fetchData();
    const timerId = setInterval(() => {
      fetchData();
    }, 20000);

    return () => {
      clearInterval(timerId);
    };
  }, [account, chainId]);

  const calcPnl = (totalPnl) => {
    return formatAmount(totalPnl, 30, 3, true);
  };

  const isPnlPlus = (totalPnl) => {
    const pnl = calcPnl(totalPnl);

    return !pnl.includes("-");
  };

  return (
    <AccountWrapper>
      <Wrapper>
        <div className="account-container">
          <BackUrl defaultUrl="/analytic/top-traders" />
          <div className="info">
            <Avatar address={account} size="48px" chainId={chainId} NFTid={NFTid} />
            <span>
              {isSmallScreen
                ? account.slice(0, 6) + "..." + account.slice(account.length - 4, account.length)
                : account}
            </span>

            <StyledExternalLink href={`${getExplorerUrl(chainId)}address/${account}`}>
              <img src={share} alt="share" />
            </StyledExternalLink>
          </div>

          <div className="group-info">
            <div className="child">
              <img src={icVolume} alt="icVolume" />

              <div className="content">
                <div className="label">Total Trading Volume</div>
                <div className="value">${formatAmount(data?.totalTradingVolume || 0, 30, 3, true)}</div>
              </div>
            </div>

            <div className="child">
              <img src={icPnl} alt="icVolume" />

              <div className="content">
                <div className="label">Total PnL</div>
                <div className="value green">
                  <div
                    style={{
                      color:
                        Number(calcPnl(data.totalNetPnl)) === 0
                          ? "#ffffff"
                          : isPnlPlus(data.totalNetPnl)
                          ? "#34f5ae"
                          : "rgb(246, 71, 93)",
                    }}
                  >
                    {isPnlPlus(data.totalNetPnl) ? "+" : "-"}$
                    {calcPnl(data.totalNetPnl).includes("-") ? calcPnl(data.totalNetPnl).slice(1) : calcPnl(data.totalNetPnl)}
                  </div>
                </div>
              </div>
            </div>

            <div className="child">
              <img src={icAccFee} alt="icVolume" />

              <div className="content">
                <div className="label">Total Fee Paid</div>
                <div className="value">${formatAmount(data?.totalFee || 0, 30, 3, true)}</div>
              </div>
            </div>
          </div>

          <div className="sub-tabs">
            {TABS.map((item) => (
              <div
                className="child"
                style={{
                  color: tab === item ? "#03F5AE" : "rgb(185, 185, 185)",
                  textDecoration: tab === item ? "underline" : "unset",
                }}
                onClick={() => setTab(item)}
              >
                {item}

                {item === "Open Positions" && ` ${data.totalOpenPosition > 0 ? `(${data.totalOpenPosition})` : ""}`}

                {item === "Closed Positions" && ` ${data.totalClosePosition > 0 ? `(${data.totalClosePosition})` : ""}`}

                {item === "Orders" && ` ${data.totalOpenOrder > 0 ? `(${data.totalOpenOrder})` : ""}`}
              </div>
            ))}
          </div>

          {tab === "Open Positions" && <OpenPositions account={account} chainId={chainId} />}
          {tab === "Closed Positions" && <ClosePositions account={account} chainId={chainId} />}
          {tab === "Orders" && <Orders account={account} chainId={chainId} />}
          {tab === "Trade History" && <TradeHistoryAcc chainId={chainId} />}
        </div>
      </Wrapper>
    </AccountWrapper>
  );
}
const AccountWrapper = styled.div`
  margin-bottom: 286px;
  width: 100%;
`;
