import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { formatAmount, getDisplayDecimalByAmount } from "lib/numbers";

const FlashValue = ({ value, className, ...props }) => {
  const ref = useRef(0);
  const valueRef = useRef();

  useEffect(() => {
    ref.current = value;
    if (valueRef.current) {
      var refElm: any = valueRef.current;

      var timerId = setTimeout(() => {
        refElm.classList.remove("animate-up", "animate-down");
      }, 2000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [value]);
  return (
    <StyledFlashValue
      {...props}
      className={`${className} animate-${ref.current < value ? "up" : ref.current === value ? "middle" : "down"}`}
      ref={valueRef}
    >
      <span>${value ? formatAmount(value, 30, getDisplayDecimalByAmount(value, 30), true) : "--"}</span>
    </StyledFlashValue>
  );
};

const down = keyframes`
  from {
    background-color: #f6475d;
  }

  to {
    background-color: transparent;
  }
`;

const up = keyframes`
  from {
    background-color: #34f5ae;
  }

  to {
    background-color: transparent;
  }
`;

const StyledFlashValue = styled.div`
  span {
    width: fit-content;
    padding: 2px 4px;
    margin-left: -4px;
  }

  &.animate-up span {
    animation: ${up} 2s linear;
  }

  &.animate-down span {
    animation: ${down} 2s linear;
  }
`;

export default FlashValue;
