import Avatar from "components/Avatar";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";

import { TabWrapper } from "../styles/Analytics.styled";
import { Table, TDCell, THead, TRow } from "./Account.styled";
import BackUrl from "./BackUrl";
import MobilePositionHistoryItem from "./MobilePositionHistoryItem";
import axios from "axios";
import share from "img/share.svg";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import { formatAmount } from "lib/numbers";
import moment from "moment";
import { getNormalizedTokenSymbol, getTokenLowerCase, getTokenSymbol } from "config/tokens";
import { importImage, shortenAddress } from "lib/legacy";
import { getApiAnalytics } from "../config";
import { getExplorerUrl } from "config/chains";
import useYourNFTId from "hooks/useYourNFTId";

export const getTokenIcon = (chainId, tokenAddress) => {
  if (!chainId || !tokenAddress) return;
  const token = getTokenLowerCase(chainId, tokenAddress);
  return importImage(`ic_${getNormalizedTokenSymbol(token?.symbol)?.toLowerCase()}_40.svg`);
};

const StyledMobileHistoryContainer = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  &:hover {
    img {
      filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.6);
  padding: 20px 0;
  .wallet-top {
    color: #adabab;
  }
  .info {
    display: flex;
    gap: 12px;

    img {
      width: 42px;
      height: 42px;
    }

    .name {
      font-size: 20px;
      margin-bottom: 4px;
      color: rgb(255, 227, 169);

      span {
        color: #34f5ae;
        font-size: 13px;
      }
    }

    .price {
      font-size: 12px;
      color: rgb(173, 171, 171);
      font-size: 14px;

      .value {
        color: white;
      }
    }

    .detail {
      display: flex;
      flex-direction: column;
    }
  }

  .wallet {
    align-self: flex-end;
    display: flex;
    align-items: center;

    @media (max-width: 1023px) {
      display: none;
    }

    img {
      width: 20px;
      height: 20px;
      margin: 0 4px;
    }

    a {
      color: #fff;
      font-weight: bold;
      text-decoration: none;
    }
  }
`;

const Content = styled.div`
  padding: 20px 0;

  .detail {
    display: flex;
    gap: 48px;

    > *:first-child {
      display: none;
    }

    > .title {
      color: rgb(173, 171, 171);

      .value {
        color: white;
      }

      &.wallet {
        a {
          text-decoration: none;
          font-weight: bold;
        }
      }

      &.pnl {
        .value {
          color: #fa3c58;
          &.positive {
            color: #34f5ae;
          }
        }
      }

      &.liquidation {
        .value {
          color: rgb(255, 179, 19);
        }
      }
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      gap: 8px;

      > *:first-child {
        display: block;
      }

      > .title {
        color: rgb(173, 171, 171);
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin: 0 10px;
        }

        .value {
          display: flex;
          align-items: center;
        }

        &.pnl {
          span {
            color: #34f5ae;
          }
        }

        &.liquidation {
          span {
            color: rgb(255, 179, 19);
          }
        }

        span {
          color: white;
        }
      }
    }
  }
`;

const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.4rem;
  border: 1px solid hsla(0, 0%, 100%, 0.062745098);

  @media (max-width: 1023px) {
    display: none;
  }
`;

const StyledTDCell = styled(TDCell)`
  padding: 14px 10px;
  color: white;
`;

const PositionDetail = ({ chainId }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });
  const { position } = useParams();
  const [data, setData] = useState({});
  const NFTid = useYourNFTId(data?.account);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios(`${getApiAnalytics(chainId)}leaderboard/trade/${position}`);
      if (response?.data) setData(response.data);
    };
    fetchData();
    const timerId = setInterval(() => {
      fetchData();
    }, 20000);

    return () => {
      clearInterval(timerId);
    };
  }, [chainId, position]);

  const calcPnl = (realisedPnl) => {
    return formatAmount(realisedPnl, 30, 3, true);
  };

  const isPnlPlus = (realisedPnl) => {
    const pnl = calcPnl(realisedPnl);

    return !pnl.includes("-");
  };
  const isClosed = data?.actions?.[0]?.type === "ClosePosition" || data?.actions?.[0]?.type === "LiquidatePosition";
  const renderType = (item) => {
    switch (item.type) {
      case "PartialClosePosition":
        return "PARTIAL CLOSE";
      case "WithdrawCollateralPosition":
        return "WITHDRAW COLLATERAL";
      case "DepositCollateralPosition":
        return "DEPOSIT COLLATERAL";
      case "OpenPosition":
        return "OPEN";
      case "ClosePosition":
        return "CLOSE";
      case "LiquidatePosition":
        return "LIQUIDATE";
      case "IncreasePosition":
        return "INCREASE";
      case "DecreasePosition":
        return "PARTIAL CLOSE";
      default:
        return "";
    }
  };
  return (
    <TabWrapper className="account-postion-details">
      {!isEmpty(data) && (
        <>
          <BackUrl defaultUrl={`/analytic/account/${data?.account}`} />

          <Header>
            <div className="info">
              <img src={getTokenIcon(chainId, data.indexToken)} alt="" />
              <div className="detail">
                <div className="name">
                  {getTokenSymbol(chainId, data.indexToken)}/USD{" "}
                  <span style={{ color: data?.isLong ? "#34f5ae" : "rgb(246, 71, 93)" }}>
                    {data?.isLong ? "LONG" : "SHORT"}
                  </span>
                  {data.leverage && (
                    <span
                      style={{
                        color: "#FFC700",
                        backgroundColor: "#FFC70033",
                        marginLeft: "4px",
                        padding: "0 4px",
                        borderRadius: "4px",
                        fontWeight: 600,
                      }}
                    >
                      {Number(data.leverage).toFixed(2)}X
                    </span>
                  )}
                </div>
                <div className="price">
                  Entry Price: <span className="value">${formatAmount(data?.entryPrice, 30, 3, true)}</span> |{" "}
                  {isClosed ? "Exit Price" : "Mark Price"}:{" "}
                  <span className="value">
                    $
                    {isClosed
                      ? formatAmount(data?.actions?.[0]?.price, 30, 3, true)
                      : formatAmount(data?.marketPrice, 30, 3, true)}
                  </span>
                </div>
              </div>
            </div>
            <div className="wallet wallet-top">
              Wallet:
              <Avatar address={data?.account} size="20px" chainId={chainId} NFTid={NFTid} />
              <Link to={`/analytic/account/${data?.account}`}>{data?.account}</Link>
            </div>
          </Header>
          <Content>
            <div className="detail">
              <div className="title wallet">
                Wallet
                <span className="value">
                  <Avatar address={data?.account} size="20px" chainId={chainId} NFTid={NFTid} />
                  <Link to={`/analytic/account/${data?.account}`}>{shortenAddress(data?.account, 13)}</Link>
                </span>
              </div>
              <div className="title size">
                Size{isSmallScreen ? "" : ": "}
                <span className="value">${formatAmount(data?.size, 30, 3, true)}</span>
              </div>
              <div className="title collateral">
                Collateral Value{isSmallScreen ? "" : ": "}
                <span className="value">${formatAmount(data?.collateral, 30, 3, true)}</span>
              </div>
              <div className="title pnl">
                PnL{isSmallScreen ? "" : ": "}
                <span
                  className="value"
                  style={{
                    color:
                      Number(calcPnl(isClosed ? data?.pnlAfterFee : data?.netPnl)) === 0
                        ? "#ffffff"
                        : isPnlPlus(isClosed ? data?.pnlAfterFee : data?.netPnl)
                        ? "#34f5ae"
                        : "rgb(246, 71, 93)",
                  }}
                >
                  {Number(calcPnl(isClosed ? data?.pnlAfterFee : data?.netPnl)) === 0 ? "" : isPnlPlus(isClosed ? data?.pnlAfterFee : data?.netPnl) ? "+" : "-"}$
                  {(isClosed ? data?.pnlAfterFee : data?.netPnl) ? formatAmount(isClosed ? data?.pnlAfterFee : data?.netPnl, 30, 3, true).replace("-", "") : "--"}
                </span>
              </div>
              <div className="title fee">
                Paid Fees{isSmallScreen ? "" : ": "}
                <span className="value">${formatAmount(data?.fee, 30, 3, true)}</span>
              </div>
              {!isClosed && (
                <div className="title liquidation">
                  Liquidation Price{isSmallScreen ? "" : ": "}
                  <span className="value">${formatAmount(data?.liquidPrice, 30, 3, true)}</span>
                </div>
              )}
            </div>
          </Content>
          <StyledTable className="details-position-container">
            <TRow>
              <THead style={{ fontWeight: 500 }}>Timestamp</THead>
              <THead style={{ fontWeight: 500 }}>Action</THead>
              <THead style={{ fontWeight: 500 }}>Collateral Delta</THead>
              <THead style={{ fontWeight: 500 }}>Size Delta</THead>
              <THead style={{ fontWeight: 500 }}>Paid Fee</THead>
              <THead style={{ fontWeight: 500 }}>Executed Price</THead>
            </TRow>
            {data &&
              data?.actions &&
              data?.actions.map((item, index) => {
                return (
                  <TRow key={index}>
                    <StyledTDCell style={{ color: "rgba(132,142,156)" }}>
                      {moment(item?.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}
                    </StyledTDCell>
                    <StyledTDCell>{renderType(item)}</StyledTDCell>
                    <StyledTDCell>
                      {item?.collateralValue != 0
                        ? item?.type === "OpenPosition" ||
                          item?.type === "DepositCollateralPosition" ||
                          item?.type === "IncreasePosition"
                          ? "+"
                          : "-"
                        : ""}

                      {item?.collateralValue != 0 ? "$" + formatAmount(item?.collateralValue, 30, 3, true) : ""}
                    </StyledTDCell>
                    <StyledTDCell>
                      {item?.sizeDelta != 0 &&
                      item?.type !== "DepositCollateralPosition" &&
                      item?.type !== "WithdrawCollateralPosition"
                        ? item?.type === "OpenPosition" || item?.type === "IncreasePosition"
                          ? "+"
                          : "-"
                        : ""}
                      {item?.sizeDelta != 0 &&
                      item?.type !== "DepositCollateralPosition" &&
                      item?.type !== "WithdrawCollateralPosition"
                        ? item?.sizeDelta
                          ? "$" + formatAmount(item?.sizeDelta, 30, 3, true).replace("-", "")
                          : "--"
                        : ""}
                    </StyledTDCell>
                    <StyledTDCell>{item?.fee != 0 ? "$" + formatAmount(item?.fee, 30, 3, true) : ""}</StyledTDCell>
                    <StyledTDCell
                      style={{ display: "flex", "justify-content": "space-between", "align-items": "center" }}
                    >
                      ${formatAmount(item?.price, 30, 3, true)}
                      <StyledExternalLink href={`${getExplorerUrl(chainId)}tx/${item.txhash}`}>
                        <img src={share} alt="share" />
                      </StyledExternalLink>
                    </StyledTDCell>
                  </TRow>
                );
              })}
          </StyledTable>
          <StyledMobileHistoryContainer>
            {data?.actions &&
              data?.actions.map((item, index) => <MobilePositionHistoryItem position={item} key={index} />)}
          </StyledMobileHistoryContainer>
        </>
      )}
    </TabWrapper>
  );
};

export default PositionDetail;
