import React, { useContext, useMemo } from "react";
import styled, { css, keyframes } from "styled-components";
import CountUp from "react-countup";
import Fade from "react-reveal/Fade";

import totalTradingVolumeIc from "img/dashboard/tvolumn.svg";
import totalValueLockedIc from "img/dashboard/tvl.svg";
import totalFeesIc from "img/dashboard/tfee.svg";
import openInterestIc from "img/dashboard/openInterest.svg";
import totalUsersIc from "img/dashboard/tuser.svg";
import bnbIc from "img/home/ic_bnb.svg";
import ftmIc from "img/home/ic_ftm.svg";
import ethIc from "img/home/ic_eth.svg";
import btcIc from "img/home/ic_btc.svg";
import usdtIc from "img/home/ic_usdt.svg";
import usdcIc from "img/home/ic_usdc.svg";
import daiIc from "img/home/ic_dai.svg";

import { bigNumberify, formatAmount } from "lib/numbers";

import { Link } from "react-router-dom";
import { StatsContext } from "contexts/StatsProvider";
import { USD_DECIMALS } from "lib/legacy";
import { getSkeletonClassName } from "utils/skeletonHelper";

const Hero = () => {
  const { totalValueLocked, totalFees, totalUsers, totalTradingVolume, longOpenInterest, shortOpenInterest } =
    useContext(StatsContext);

  const totalOpenInterest = useMemo(() => {
    return longOpenInterest["total"] && shortOpenInterest["total"]
      ? longOpenInterest["total"].add(shortOpenInterest["total"])
      : bigNumberify(0);
  }, [longOpenInterest, shortOpenInterest]);

  return (
    <Wrapper>
      <video className="videoTag" autoPlay loop muted playsInline>
        <source src={"https://cdn.mummy.finance/bg_videoMMY.mp4"} type="video/mp4" />
      </video>
      <Inner>
        <Fade bottom>
          <Heading>
            <Title>
              <div>Decentralized</div>
              <GradientText>Perpetual Exchange</GradientText>
            </Title>
            <Description>
              Trade BTC, ETH, FTM, OP, ARB and other top cryptocurrencies with up to 100x leverage directly from your
              wallet
            </Description>
            <TradeButton href="https://app.mummy.finance/#/trade">Start Trading</TradeButton>
          </Heading>
        </Fade>
        <Stats className="container">
          <StatItem>
            <img src={totalTradingVolumeIc} alt="stat_ic" />
            <div className="content">
              <div className={`value ${getSkeletonClassName(totalTradingVolume.total)}`}>
                <CountUp
                  end={parseFloat(formatAmount(totalTradingVolume.total, USD_DECIMALS, 0))}
                  separator=","
                  prefix="$"
                />
              </div>
              <div className="title">Total Trading Volume</div>
            </div>
          </StatItem>
          <StatItem>
            <img src={totalValueLockedIc} alt="stat_ic" />
            <div className="content">
              <div className={`value ${getSkeletonClassName(totalValueLocked.total)}`}>
                <CountUp
                  end={parseFloat(formatAmount(totalValueLocked.total, USD_DECIMALS, 0))}
                  separator=","
                  prefix="$"
                />
              </div>
              <div className="title">Total Value Locked</div>
            </div>
          </StatItem>
          <StatItem>
            <img src={totalFeesIc} alt="stat_ic" />
            <div className="content">
              <div className={`value ${getSkeletonClassName(totalFees.total)}`}>
                <CountUp end={parseFloat(formatAmount(totalFees.total, USD_DECIMALS, 0))} separator="," prefix="$" />
              </div>
              <div className="title">Total Fees</div>
            </div>
          </StatItem>
          <StatItem>
            <img src={openInterestIc} alt="stat_ic" />
            <div className="content">
              <div className={`value ${getSkeletonClassName(totalOpenInterest)}`}>
                <CountUp end={parseFloat(formatAmount(totalOpenInterest, USD_DECIMALS, 0))} separator="," prefix="$" />
              </div>
              <div className="title">Open Interest</div>
            </div>
          </StatItem>
          <StatItem>
            <img src={totalUsersIc} alt="stat_ic" />

            <div className="content">
              <div className={`value ${getSkeletonClassName(totalUsers.total)}`}>
                <CountUp end={parseInt(formatAmount(totalUsers.total || 0, 0, 0))} />
              </div>
              <div className="title">Total Users</div>
            </div>
          </StatItem>
        </Stats>
      </Inner>
      <FloatTokens>
        <img src={bnbIc} alt="bnbIc" />
        <img src={ftmIc} alt="ftmIc" />
        <img src={ethIc} alt="ethIc" />
        <img src={btcIc} alt="btcIc" />
        <img src={usdtIc} alt="usdtIc" />
        <img src={usdcIc} alt="usdcIc" />
        <img src={daiIc} alt="daiIc" />
      </FloatTokens>
    </Wrapper>
  );
};

function createCSS() {
  const altAttrNames = {
    bnbIc: [7, 0, 11, 20.93],
    daiIc: [21.42, 10.42, 14.67, 16.67],
    usdtIc: [10.17, 19.37, 26.66, 4.53],
    btcIc: [87.67, 20.83, 10.33, 12.5],
    ethIc: [84.26, 40.22, 26.92, 45.35],
    usdcIc: [8.42, 10.42, 50.07, 7.05],
    ftmIc: [89, 10.42, 47.07, 7.05],
  };

  let styles = "";

  Object.keys(altAttrNames).forEach((name) => {
    styles += `
      img[alt="${name}"] {
        left: ${altAttrNames[name][0]}%;
        right: ${altAttrNames[name][1]}%;
        top: ${altAttrNames[name][2]}%;
        bottom: ${altAttrNames[name][3]}%;

        @media (max-width: 1023px) {
          top: ${altAttrNames[name][2] / 2}%;
          scale: 0.6;
        }

        @media (max-width: 767px) {
          top: ${altAttrNames[name][2] / 2.5}%;
          scale: 0.4;
        }
      }
    `;
  });

  return css`
    ${styles}
  `;
}

const floating = keyframes`
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 5px); }
  100%   { transform: translate(0, -0px); }
`;

const FloatTokens = styled.div`
  position: absolute;
  inset: 0;
  z-index: 5;

  img {
    position: absolute;
    animation: ${floating} 2.5s ease-in-out 0.33s infinite normal none;
  }

  ${createCSS()}
`;

const GradientText = styled.div`
  height: 75px;
  background-image: linear-gradient(to right, #03f5ae, #e3fff7);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  @media (max-width: 1023px) {
    height: 50px;
  }

  @media (max-width: 767px) {
    height: 35px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (max-width: 1023px) {
    font-size: 48px;
    gap: 14px;
  }

  @media (max-width: 767px) {
    font-size: 32px;
    gap: 8px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff99;
  max-width: 697px;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 20px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const StatItem = styled.div`
  flex: 1;
  background-color: #ffffff1a;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);

  img {
    width: 48px;
    height: 48px;
    filter: drop-shadow(0px 4px 24px #03f5ae);
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    height: 100%;

    .value {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
    }

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #ffffff99;
      margin-top: auto;
    }
  }

  @media (max-width: 1023px) {
    min-width: calc(100% / 4);

    &:nth-child(4),
    &:nth-child(5) {
      width: 224px;
      flex: unset;
    }

    .content {
      .value {
        font-size: 16px;
      }

      .title {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 767px) {
    min-width: unset;
    flex-direction: row;
    padding: 16px;

    &:nth-child(4),
    &:nth-child(5) {
      width: unset;
      flex: unset;
    }

    img {
      width: 32px;
      height: 32px;
    }

    .content {
      gap: 8px;
      align-items: flex-start;

      .value {
        font-size: 16px;
      }
    }
  }
`;

const Stats = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  grid-template-columns: repeat(5, 1fr);

  padding: 0 24px;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 64px;

  @media (max-width: 1023px) {
    gap: 32px;
  }

  @media (max-width: 1023px) {
    gap: 32px;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: 767px) {
    gap: 16px;
  }
`;

const TradeButton = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #080715;
  background-color: #03f5ae;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  outline: none;
  margin-top: 16px;
  text-decoration: none;
  &:hover {
    background: #04d397;
    color: #080715 !important;
  }

  @media (max-width: 767px) {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    border-radius: 8px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  padding: 177.5px 0 113.5px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  @media (max-width: 1023px) {
    padding: 126px 24px 62px;
    height: 864px;
  }

  @media (max-width: 767px) {
    padding: 113px 16px 57px;
  }

  > video {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
    object-fit: cover;
    background-color: #000000;
    filter: brightness(30%) blur(4px);
  }
`;

export default Hero;
