import React from "react";
import { FiX } from "react-icons/fi";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { HeaderLink } from "./HeaderLink";
import "./Header.scss";
import { useHistory } from "react-router-dom";
import { isHomeSite } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import logoImg from "img/logo_MMY.svg";
import icDown from "img/icons/dropdown-ic-header.svg";
import { useChainId } from "lib/chains";
import { ARBITRUM, FANTOM } from "config/chains";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const { chainId } = useChainId();

  const history = useHistory();
  // const isHomeSite = history.location.pathname === "/" ? true : false;
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <a className="App-header-link-main" href="https://www.mummy.finance/">
            <img src={logoImg} alt="MMY Logo" />
          </a>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      <div className="App-header-link-container">
        <HeaderLink
          to="/dashboard"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <span>Dashboard</span>
        </HeaderLink>
      </div>
      {chainId !== ARBITRUM && (
        <div className="App-header-link-container">
          <HeaderLink to="/nft" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
            <Trans>NFT</Trans>
          </HeaderLink>
        </div>
      )}
      {/* {small && !isHomeSite && (
        <div className="App-header-link-container">
          <HeaderLink to="/nft" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
            <span>Mint NFT</span>
          </HeaderLink>
        </div>
      )}
      {small && !isHomeSite && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/stake-nft"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <span>Stake NFT</span>
          </HeaderLink>
        </div>
      )}
      {!small && (
        <div className="App-header-link-container">
          <div className="App-header-dropdown">
            <Menu>
              <Menu.Button as="div">
                <span>NFT <img alt="" src={icDown}/></span>
              </Menu.Button>
              <div>
                <Menu.Items as="div" className="menu-items">
                  <Menu.Item>
                    <div className="menu-item">
                      <HeaderLink
                        to="/nft"
                        redirectPopupTimestamp={redirectPopupTimestamp}
                        showRedirectModal={showRedirectModal}
                      >
                        <span>Mint</span>
                      </HeaderLink>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div className="menu-item">
                      <HeaderLink
                        to="/stake-nft"
                        redirectPopupTimestamp={redirectPopupTimestamp}
                        showRedirectModal={showRedirectModal}
                      >
                        <span>Stake NFT</span>
                      </HeaderLink>
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </div>
            </Menu>
          </div>
        </div>
      )} */}

      {/* <div className="header-dropdown">
        <div className="dropbtn">
          <HeaderLink to="/nft" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
            <span>NFT</span>
          </HeaderLink>
        </div>
        <div className="header-dropdown-content">
          <Link to={`/nft`}>
            <div>
              <div>Mint</div>
            </div>
          </Link>
          <Link to={`/stake-nft`}>
            <div>
              <div>Stake NFT</div>
            </div>
          </Link>
        </div>
      </div> */}
      <div className="App-header-link-container">
        <HeaderLink
          to="/earn-v2/"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <span>Earn</span>
        </HeaderLink>
      </div>
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/earn-v2/vault"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <span>Vault</span>
        </HeaderLink>
      </div> */}
      <div className="App-header-link-container">
        <HeaderLink to="/buy" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <span>Buy</span>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to="/referrals"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <span>Referrals</span>
        </HeaderLink>
      </div>
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/ecosystem"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <span>Ecosystem</span>
        </HeaderLink>
      </div> */}
    <div className="App-header-link-container">
    <div className="new-dot" />
      <ExternalLink href="https://skeleton.finance">
        <span>Skeleton</span>
      </ExternalLink>
    </div>
     <div className="App-header-link-container">
        <HeaderLink to="/bridge" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Bridge</Trans>
        </HeaderLink>
      </div>
      {
        <div className="App-header-link-container">
          <HeaderLink
            to="/analytic/overview"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <span>Analytics</span>
          </HeaderLink>
        </div>
      }
      {/* {small && !isHomeSite && (
        <div className="App-header-link-container">
          <ExternalLink href="https://leaderboard.mummy.finance/fantom/leaderboard">
            <span>Leaderboard</span>
          </ExternalLink>
        </div>
      )} */}
      {small && !isHomeSite && chainId === FANTOM && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/proof-of-locked-liquidity"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <span>PoLL</span>
          </HeaderLink>
        </div>
      )}
      {small && !isHomeSite && (
        <div className="App-header-link-container">
          <div className="new-dot new-dot-mobile" />
          <HeaderLink to="/news" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
            <span>News</span>
          </HeaderLink>
        </div>
      )}
      {small && !isHomeSite && (
        <div className="App-header-link-container">
          <ExternalLink href="https://docs.mummy.finance">
            <span>About</span>
          </ExternalLink>
        </div>
      )}
      {!small && (
        <div className="App-header-link-container">
          {/* <div className="new-dot" /> */}
          <div className="App-header-dropdown">
            <Menu>
              <Menu.Button as="div">
                <span>
                  More <img alt="" src={icDown} />
                </span>
              </Menu.Button>
              <div>
                <Menu.Items as="div" className="menu-items">
                  {/* <Menu.Item>
                    <div className="menu-item">
                      <ExternalLink href="https://leaderboard.mummy.finance/fantom/leaderboard">
                        <span>Leaderboard</span>
                      </ExternalLink>
                    </div>
                  </Menu.Item> */}
                  <Menu.Item>
                    <div className="menu-item">
                      <HeaderLink
                        to="/news"
                        redirectPopupTimestamp={redirectPopupTimestamp}
                        showRedirectModal={showRedirectModal}
                      >
                        <span>News</span>
                      </HeaderLink>
                    </div>
                  </Menu.Item>
                  {chainId === FANTOM && (
                    <Menu.Item>
                      <div className="menu-item">
                        <HeaderLink
                          to="/proof-of-locked-liquidity"
                          redirectPopupTimestamp={redirectPopupTimestamp}
                          showRedirectModal={showRedirectModal}
                        >
                          <span>PoLL</span>
                        </HeaderLink>
                      </div>
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    <div className="menu-item">
                      <ExternalLink href="https://docs.mummy.finance">
                        <span>About</span>
                      </ExternalLink>
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </div>
            </Menu>
          </div>
        </div>
      )}

      {small && !isHomeSite && (
        <div className="App-header-link-container">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={openSettings}>
            <span>Settings</span>
          </a>
        </div>
      )}
    </div>
  );
}
