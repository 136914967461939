import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import Tooltip from "components/Tooltip/Tooltip";
import styled from "styled-components";
import { Table, TDCell, THead, TRow } from "pages/Analytics/components/Account.styled";
import share from "img/share.svg";
import {
  CHAIN_ID_LOCAL,
  USD_DECIMALS,
  MAX_LEVERAGE,
  BASIS_POINTS_DIVISOR,
  LIQUIDATION_FEE,
  TRADES_PAGE_SIZE,
  deserialize,
  getExchangeRateDisplay,
} from "lib/legacy";
import { useTrades, useLiquidationsData } from "domain/legacy";
import { getContract } from "config/contracts";

import "./TradeHistory.css";
import { getExplorerUrl, NETWORK_METADATA } from "config/chains";
import { bigNumberify, formatAmount } from "lib/numbers";
import { formatDateTime } from "lib/dates";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { select, t, Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getDecimalTokenByAddress, getNameTokenByAddress } from "utils/tokenHelper";
import LongShortTag, { StyledLongShortTag } from "pages/Analytics/components/LongShortTag";
import moment from "moment";
import { useMedia } from "react-use";
import MobileTradeHistoryItem from "pages/Analytics/components/MobileTradeHistoryItem";
import MobileTradeHistoryItemTradeTab from "pages/Analytics/components/MobileTradeHistoryItemTradeTab";
import { getNormalizedTokenSymbol, getTokenLowerCase, getTokenSymbol } from "config/tokens";

const { AddressZero } = ethers.constants;

function getPositionDisplay(increase, indexToken, isLong, sizeDelta) {
  const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : "";
  return `
    ${increase ? `Increase` : `Decrease`} ${symbol} ${isLong ? `Long` : `Short`}
    ${increase ? "+" : "-"}${formatAmount(sizeDelta, USD_DECIMALS, 2, true)} USD`;
}

function getOrderActionTitle(action) {
  let actionDisplay;

  if (action.startsWith("Create")) {
    actionDisplay = `Create`;
  } else if (action.startsWith("Cancel")) {
    actionDisplay = `Cancel`;
  } else {
    actionDisplay = `Update`;
  }

  return `${actionDisplay} Order`;
}

function renderLiquidationTooltip(liquidationData, label) {
  const minCollateral = liquidationData.size.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE(CHAIN_ID_LOCAL));
  const text =
    liquidationData.type === "full"
      ? `This position was liquidated as the max leverage of 100x was exceeded.`
      : `Max leverage of 100x was exceeded, the remaining collateral after deducting losses and fees have been sent back to your account`;
  return (
    <Tooltip
      position="left-top"
      handle={label}
      renderContent={() => (
        <>
          {text}
          <br />
          <br />
          <StatsTooltipRow
            label={`Initial collateral`}
            showDollar
            value={formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label={`Min required collateral`}
            showDollar
            value={formatAmount(minCollateral, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label={`Borrow fee`}
            showDollar
            value={formatAmount(liquidationData.borrowFee, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow label={`PnL`} value={`-$${formatAmount(liquidationData.loss, USD_DECIMALS, 2, true)}`} />
          {liquidationData.type === "full" && (
            <StatsTooltipRow label={`Liquidation fee`} showDollar value={formatAmount(LIQUIDATION_FEE, 30, 2, true)} />
          )}
        </>
      )}
    />
  );
}

function getLiquidationData(liquidationsDataMap, key, timestamp) {
  return liquidationsDataMap && liquidationsDataMap[`${key}:${timestamp}`];
}

export default function TradeHistoryV2(props) {
  const { account, infoTokens, getTokenInfo, chainId, nativeTokenAddress, shouldShowPaginationButtons } = props;
  const [pageIds, setPageIds] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const isMobile = useMedia("(max-width: 1110px)");
  const getAfterId = () => {
    return pageIds[pageIndex];
  };

  const { trades, updateTrades } = useTrades(chainId, account, props.forSingleAccount, getAfterId());

  const liquidationsData = useLiquidationsData(chainId, account);
  const liquidationsDataMap = useMemo(() => {
    if (!liquidationsData) {
      return null;
    }
    return liquidationsData.reduce((memo, item) => {
      const liquidationKey = `${item.key}:${item.timestamp}`;
      memo[liquidationKey] = item;
      return memo;
    }, {});
  }, [liquidationsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTrades(undefined, true);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [updateTrades]);

  const loadNextPage = () => {
    if (!trades || trades.length === 0) {
      return;
    }

    const lastTrade = trades[trades.length - 1];
    pageIds[pageIndex + 1] = lastTrade.id;
    setPageIds(pageIds);
    setPageIndex(pageIndex + 1);
  };

  const loadPrevPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const getMsg = useCallback(
    (trade) => {
      const tradeData = trade;
      const params = tradeData;
      const longOrShortText = params?.isLong ? `Long` : `Short`;
      const defaultMsg = "";

      if (tradeData.action === "BuyUSDG") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }
        return `Swap ${formatAmount(params.tokenAmount, token.decimals, 4, true)} ${token.symbol} for ${formatAmount(
          params.usdgAmount,
          18,
          4,
          true
        )} USDG`;
      }

      if (tradeData.action === "SellUSDG") {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }
        return `Swap ${formatAmount(params.usdgAmount, 18, 4, true)} USDG for ${formatAmount(
          params.tokenAmount,
          token.decimals,
          4,
          true
        )} ${token.symbol}`;
      }

      if (tradeData.action === "Swap") {
        const tokenIn = getTokenInfo(infoTokens, params.tokenIn, true, nativeTokenAddress);
        const tokenOut = getTokenInfo(infoTokens, params.tokenOut, true, nativeTokenAddress);
        if (!tokenIn || !tokenOut) {
          return defaultMsg;
        }
        // return `Swap ${formatAmount(params.amountIn, tokenIn.decimals, 4, true)} ${tokenIn.symbol} for ${formatAmount(
        //   params.amountOutAfterFees,
        //   tokenOut.decimals,
        //   4,
        //   true
        // )} ${tokenOut.symbol}`;
        return `Swapped ${formatAmount(params.amountIn, tokenIn.decimals, 4, true)} ${getNormalizedTokenSymbol(
          tokenIn.symbol
        )} for ${formatAmount(params.amountOutAfterFees, tokenOut.decimals, 4, true)} ${getNormalizedTokenSymbol(
          tokenOut.symbol
        )}`;
      }

      if (tradeData.action === "IncreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          // return `Request deposit into ${indexToken.symbol} ${longOrShortText}, +${formatAmount(
          //   params.collateralDelta,
          //   USD_DECIMALS,
          //   2,
          //   true
          // )} USD`;
          return `Deposited $${formatAmount(
            params.collateralDelta,
            USD_DECIMALS,
            2,
            true
          )} to ${getNormalizedTokenSymbol(indexToken.symbol)} ${longOrShortText.toUpperCase()}`;
        }

        // return `Request increase ${indexToken.symbol === "WFTM" ? "FTM" : indexToken.symbol } ${longOrShortText}, +${formatAmount(
        //   params.sizeDelta,
        //   USD_DECIMALS,
        //   2,
        //   true
        // )} USD, Acceptable Price: ${params.isLong ? "<" : ">"} ${formatAmount(
        //   params.price,
        //   USD_DECIMALS,
        //   2,
        //   true
        // )} USD`;
        return `${getNormalizedTokenSymbol(
          indexToken.symbol
        )} ${longOrShortText.toUpperCase()} increased by $${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} (price = $${formatAmount(params.price, USD_DECIMALS, 2, true)})`;
      }

      if (tradeData.action === "DecreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          // return `Request withdrawal from ${indexToken.symbol === "WFTM" ? "FTM" : indexToken.symbol } ${longOrShortText}, -${formatAmount(
          //   params.collateralDelta,
          //   USD_DECIMALS,
          //   2,
          //   true
          // )} USD`;
          return `Withdrew $${formatAmount(
            params.collateralDelta,
            USD_DECIMALS,
            2,
            true
          )} from ${getNormalizedTokenSymbol(indexToken.symbol)} ${longOrShortText.toUpperCase()}`;
        }

        // return `Request decrease ${indexToken.symbol === "WFTM" ? "FTM" : indexToken.symbol } ${longOrShortText}, -${formatAmount(
        //   params.sizeDelta,
        //   USD_DECIMALS,
        //   2,
        //   true
        // )} USD, Acceptable Price: ${params.isLong ? ">" : "<"} ${formatAmount(
        //   params.price,
        //   USD_DECIMALS,
        //   2,
        //   true
        // )} USD`;
        return `${getNormalizedTokenSymbol(
          indexToken.symbol
        )} ${longOrShortText.toUpperCase()} decreased by $${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} (price = $${formatAmount(params.price, USD_DECIMALS, 2, true)})`;
      }

      if (tradeData.action === "CancelIncreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return (
            <span>
              Could not execute deposit into {indexToken.symbol} {longOrShortText}
            </span>
          );
        }

        return (
          <>
            <span>
              Could not increase {indexToken.symbol} {longOrShortText},
              {`+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)}`} USD, Acceptable Price:&nbsp;
              {params.isLong ? "<" : ">"}&nbsp;
            </span>
            <Tooltip
              position="left-top"
              handle={`${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)} USD`}
              renderContent={() => (
                <span>Try increasing the "Allowed Slippage", under the Settings menu on the top right.</span>
              )}
            />
          </>
        );
      }

      if (tradeData.action === "CancelDecreasePosition") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Could not execute withdrawal from ${indexToken.symbol} ${longOrShortText}`;
        }

        return (
          <>
            <span>
              Could not decrease {indexToken.symbol} {longOrShortText},
              {`+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)}`} USD, Acceptable Price:&nbsp;
              {params.isLong ? ">" : "<"}&nbsp;
            </span>
            <Tooltip
              position="left-top"
              handle={`${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)} USD`}
              renderContent={() => (
                <span>Try increasing the "Allowed Slippage", under the Settings menu on the top right</span>
              )}
            />
          </>
        );
      }

      if (tradeData.action === "IncreasePosition-Long" || tradeData.action === "IncreasePosition-Short") {
        if (params.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Deposit ${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD into ${indexToken.symbol
            } ${longOrShortText}`;
        }
        return `Increase ${indexToken.symbol} ${longOrShortText}, +${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD, ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD`;
      }

      if (tradeData.action === "DecreasePosition-Long" || tradeData.action === "DecreasePosition-Short") {
        if (params.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Withdraw ${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD from ${indexToken.symbol
            } ${longOrShortText}`;
        }
        const isLiquidation = params.flags?.isLiquidation;
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp);

        if (isLiquidation && liquidationData) {
          return (
            <>
              {renderLiquidationTooltip(liquidationData, `Partial Liquidation`)} {indexToken.symbol} {longOrShortText},
              -{formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD, {indexToken.symbol}&nbsp; Price: $
              {formatAmount(params.price, USD_DECIMALS, 2, true)} USD
            </>
          );
        }
        const actionDisplay = isLiquidation ? `Partially Liquidated` : `Decreased`;
        return `
        ${actionDisplay} ${indexToken.symbol} ${longOrShortText},
        -${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD
      `;
      }

      if (tradeData.action === "LiquidatePosition" || tradeData.action === "LiquidatePosition-Short") {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp);
        if (liquidationData) {
          return (
            <>
              {renderLiquidationTooltip(liquidationData, `Liquidated`)} {indexToken.symbol} {longOrShortText}, -
              {formatAmount(params.size, USD_DECIMALS, 2, true)} USD,&nbsp;
              {indexToken.symbol} Price: ${formatAmount(params.markPrice, USD_DECIMALS, 2, true)} USD
            </>
          );
        }
        //   return `
        //   Liquidated ${indexToken.symbol} ${longOrShortText},
        //   -${formatAmount(params.size, USD_DECIMALS, 2, true)} USD,
        //   ${indexToken.symbol} Price: ${formatAmount(params.markPrice, USD_DECIMALS, 2, true)} USD
        // `;
        return `${getNormalizedTokenSymbol(indexToken.symbol)} ${longOrShortText.toUpperCase()} of $${formatAmount(
          params.size,
          USD_DECIMALS,
          2,
          true
        )} has been liquidated (price = $${formatAmount(params.markPrice, USD_DECIMALS, 2, true)})`;
      }

      if (["ExecuteIncreaseOrder", "ExecuteDecreaseOrder"].includes(tradeData.action)) {
        const order = deserialize(params.order);
        const indexToken = getTokenInfo(infoTokens, order.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        const longShortDisplay = order.isLong ? `Long` : `Short`;
        const executionPriceDisplay = formatAmount(order.executionPrice, USD_DECIMALS, 2, true);
        const sizeDeltaDisplay = `${order.type === "Increase" ? "+" : "-"}${formatAmount(
          order.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )}`;
        return `Execute Order: ${select(order.type, { Increase: "Increase", Decrease: "Decrease" })} ${indexToken.symbol
          } ${longShortDisplay} ${sizeDeltaDisplay} USD, Price: ${executionPriceDisplay} USD`;
      }

      if (
        [
          "CreateIncreaseOrder",
          "CancelIncreaseOrder",
          "UpdateIncreaseOrder",
          "CreateDecreaseOrder",
          "CancelDecreaseOrder",
          "UpdateDecreaseOrder",
        ].includes(tradeData.action)
      ) {
        const order = deserialize(params.order);
        const indexToken = getTokenInfo(infoTokens, order.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const increase = tradeData.action.includes("Increase");
        const priceDisplay = `${order.triggerAboveThreshold ? ">" : "<"} ${formatAmount(
          order.triggerPrice,
          USD_DECIMALS,
          2,
          true
        )}`;
        return `
        ${getOrderActionTitle(tradeData.action)}:
        ${getPositionDisplay(increase, indexToken, order.isLong, order.sizeDelta)},
        Price: ${priceDisplay}
      `;
      }

      if (tradeData.action === "ExecuteSwapOrder") {
        const order = deserialize(params.order);
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, order.path[0] === nativeTokenAddress ? AddressZero : order.path[0]);
        const toToken = getTokenInfo(infoTokens, order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const fromAmountDisplay = formatAmount(order.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true);
        const toAmountDisplay = formatAmount(order.amountOut, toToken.decimals, toToken.isStable ? 2 : 4, true);
        return `
        Execute Order: Swap ${fromAmountDisplay} ${fromToken.symbol} for ${toAmountDisplay} ${toToken.symbol}
      `;
      }

      if (["CreateSwapOrder", "UpdateSwapOrder", "CancelSwapOrder"].includes(tradeData.action)) {
        const order = deserialize(params.order);
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, order.path[0] === nativeTokenAddress ? AddressZero : order.path[0]);
        const toToken = getTokenInfo(infoTokens, order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const amountInDisplay = fromToken
          ? formatAmount(order.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
          : "";
        const minOutDisplay = toToken
          ? formatAmount(order.minOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
          : "";

        return `
        ${getOrderActionTitle(tradeData.action)}:
        Swap ${amountInDisplay} ${fromToken?.symbol || ""} for ${minOutDisplay} ${toToken?.symbol || ""},
        Price: ${getExchangeRateDisplay(order.triggerRatio, fromToken, toToken)}`;
      }
    },
    [getTokenInfo, infoTokens, nativeTokenAddress, chainId, liquidationsDataMap]
  );

  const tradesWithMessages = useMemo(() => {
    if (!trades || trades.length === 0) {
      return [];
    }
    if (trades && trades.length > 0) {
      return trades
        .map((trade) => ({
          msg: getMsg(trade),
          ...trade,
        }))
        .filter((trade) => trade.msg);
    } else {
      return [];
    }
  }, [trades, getMsg]);

  return (
    <div className="TradeHistory">
      {(
        <div className="new-history">
          {!trades && <div className="skeleton-box" style={{height: '25px'}}/>}
          <StyledTable>
            {tradesWithMessages.length > 0 && !isMobile && <TRow className="tradeHistory-Header">
              <THead>Time</THead>
              <THead>Market</THead>
              <THead>Action Value</THead>
              <THead></THead>
            </TRow>}

            {tradesWithMessages.length > 0 && !isMobile && trades.map((trade, index) => {
              const item = trade;
              const txUrl = getExplorerUrl(chainId) + "tx/" + item.txhash;
              // let msg = getMsg(trade);
              const indexToken = getTokenSymbol(chainId, item.indexToken);
              // console.log("getTokenSymbol", getTokenSymbol(chainId, item.indexToken));
              const isLongOrShort = item.isLong ? "LONG" : "SHORT";
              const isPlusOrMinus = item.action === "DecreasePosition" ? "-" : "+";
              const CollateralDelta = formatAmount(item.collateralDelta, 30, 3, true);
              // if (!msg) {
              //   return null;
              // }

              return (
                <TRow key={item.address}>
                  <TDCell style={{ width: "20%", color: "#848E9C" }}>
                    {moment(item.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}
                  </TDCell>
                  <TDCell
                    style={{
                      width: "20%",
                      color: "rgb(255, 227, 169)",
                      fontWeight: "500",
                    }}
                  >
                    {item.action === "Swap" ? (
                      <>
                        <StyledLongShortTag>
                          <span style={{ color: "#fff" }}>SWAP</span>
                        </StyledLongShortTag>
                        <span style={{ marginLeft: "8px" }}>
                          {getNormalizedTokenSymbol(getTokenLowerCase(chainId, item.tokenIn)?.symbol)}/
                          {getNormalizedTokenSymbol(getTokenLowerCase(chainId, item.tokenOut)?.symbol)}
                        </span>
                      </>
                    ) : (
                      <>
                        <LongShortTag isLong={item.isLong} style={{ "margin-right": "8px", fontWeight: "500" }} />
                        {indexToken}/USD
                      </>
                    )}
                  </TDCell>
                  <TDCell>
                    {/* Sizedelta == 0 && type == IncreasePosition -> deposit */}
                    {/* Sizedelta == 0 && collateralValue != 0 && type == DecreasePosition -> withdraw */}
                    {item.action === "DecreasePosition" || item.action === "IncreasePosition"
                      ? item.sizeDelta == 0
                        ? item.action == "IncreasePosition"
                          ? `Deposited $${CollateralDelta} to ${indexToken} ${isLongOrShort}`
                          : item.collateralValue !== 0
                            ? `Withdrew  $${CollateralDelta} from ${indexToken} ${isLongOrShort}`
                            : ""
                        : `${indexToken} ${isLongOrShort} ${String(item.action)
                          .replace("Position", "d")
                          .toLowerCase()} by $${formatAmount(item.sizeDelta, 30, 3, true)} (price = $${formatAmount(
                            item.price,
                            30,
                            3,
                            true
                          )})`
                      : item.action === "Swap"
                        ? `Swapped ${formatAmount(
                          item.amountIn,
                          getTokenLowerCase(chainId, item.tokenIn)?.decimals,
                          3,
                          true
                        )} ${getNormalizedTokenSymbol(getTokenLowerCase(chainId, item.tokenIn)?.symbol)}
                        for ${formatAmount(
                          item.amountOutAfterFees,
                          getTokenLowerCase(chainId, item.tokenOut)?.decimals,
                          3,
                          true
                        )} ${getNormalizedTokenSymbol(getTokenLowerCase(chainId, item.tokenOut).symbol)}`
                        : `${indexToken} ${isLongOrShort} of ${formatAmount(
                          item.size,
                          30,
                          3,
                          true
                        )} has been liquidated (price = $${formatAmount(item.markPrice, 30, 3, true)})`}
                  </TDCell>
                  <TDCell style={{ width: "45px" }}>
                    <StyledExternalLink href={`${getExplorerUrl(chainId)}tx/${item.txhash}`}>
                      <img src={share} alt="share" />
                    </StyledExternalLink>
                  </TDCell>
                </TRow>
                // <div className="TradeHistory-row App-box App-box-border" key={index}>

                //   <div>
                //     <div className="muted TradeHistory-time">
                //       {formatDateTime(tradeData.timestamp)}
                //       {(!account || account.length === 0) && (
                //         <span>
                //           {" "}
                //           (<Link to={`/actions/${tradeData.account}`}>{tradeData.account}</Link>)
                //         </span>
                //       )}
                //     </div>
                //     <ExternalLink className="plain" href={txUrl}>
                //       {msg}
                //     </ExternalLink>
                //   </div>
                // </div>
              );
            })}
          </StyledTable>
        </div>
      )}
      {tradesWithMessages.length > 0 && isMobile && (
        <StyledMobileHistoryContainer>
          {trades.map((item) => (
            <MobileTradeHistoryItemTradeTab data={item} />
          ))}
        </StyledMobileHistoryContainer>
      )}
      {shouldShowPaginationButtons && (
        <div>
          {pageIndex > 0 && (
            <button className="App-button-option App-card-option" onClick={loadPrevPage}>
              <span>Prev</span>
            </button>
          )}
          {trades && trades.length >= TRADES_PAGE_SIZE && (
            <button className="App-button-option App-card-option" onClick={loadNextPage}>
              <span>Next</span>
            </button>
          )}
        </div>
      )}
      {trades && tradesWithMessages.length === 0 && (
        <div className="TradeHistory-row App-box App-card no-trade-history no-data-card">
          <span>No trades yet</span>
        </div>
      )}
    </div>
  );
}
const StyledTable = styled(Table)`
  width: 100%;
  /* @media screen {
    display: none;
  } */
  border-spacing: 0 12px;
  border-collapse: separate;
  background: none;
  margin-top: -10px;
`;
const StyledExternalLink = styled(ExternalLink)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  &:hover {
    img {
      filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
    }
  }
`;
const StyledMobileHistoryContainer = styled.div`
  display: none;

  /* background: #ffffff10; */
  padding: 0;

  @media (max-width: 1023px) {
    display: block;
    margin-top: 10px;
  }
  @media screen and (max-width: 700px) {
    margin-top: 10px;
  }
  .table-mobile {
    /* background-color: rgb(41, 41, 44); */
    /* background: #212333; */
    background: #191B2E;
    border-radius: 16px;
    /* background-color: rgb(28, 28, 31); */
    padding: 14px;
    margin-bottom: 12px;

    .header {
      border-bottom: 1px dashed hsla(0, 0%, 100%, 0.062745098);
      padding-bottom: 14px;

      .coin {
        color: rgb(255, 227, 169);
        font-weight: 500;
      }

      .position {
        color: rgb(7, 186, 117);
        font-weight: 600;
      }
    }

    .content {
      .child {
        margin-top: 12px;

        display: flex;
        justify-content: space-between;

        &.close-on {
          .value {
            color: rgb(132, 142, 156);
          }
        }

        &.net-value {
          .coin {
            text-align: right;
          }

          .position {
            margin-top: 5px;

            font-size: 12px;
          }
        }

        &.view-details {
          justify-content: center;
          margin: 25px 0px 12px;
          border-top: 1px dashed rgb(64, 64, 64);
          padding-top: 20px;

          color: #03f5ae;
          font-size: 13px;
          font-weight: 500;
          a {
            text-decoration: none;
            color: #03f5ae;
          }
        }
      }
    }
  }
`;
