import { Menu } from "@headlessui/react";
import { useWeb3React } from "@web3-react/core";
import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";
import MintIc from "img/home/ic-mint.svg";
import mum1 from "img/home/mum1.png";
import DropdownIcon from "img/icons/dropdown-ic.svg";
import InfoIc from "img/icons/ic-info.svg";
import icPrev from "img/icons/ic-prev.svg";
import linkWhite from "img/icons/link-white.svg";
import ftm from "img/tokens/ftm.svg";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import { useEffect, useState } from "react";
import styled from "styled-components";
import "./dropdown.scss";

import MummyClubSale from "abis/MummyClubSale.json";
import NFTClub from "abis/NFTClub.json";
import { ethers } from "ethers";

import { commify } from "@ethersproject/units";
import { getContract } from "config/contracts";
import useProviderBalance from "hooks/useProviderBalance";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import Slider from "react-slick";
import { useMedia } from "react-use";
import useSWR from "swr";
import { TXT } from "../multichainTxt";
import { getLinkCDN } from "config/chains";
import { getDocsClub } from "config/chains";

const abiDecoder = require("abi-decoder");
// eslint-disable-next-line no-sparse-arrays
const listOption = [
  {
    num: 1,
  },
  {
    num: 2,
  },
  {
    num: 3,
  },
  {
    num: 4,
  },
  ,
  {
    num: 5,
  },
];
export default function MintModal({ isVisible, setIsVisible, connectWallet, setPendingTxns, pendingTxns }) {
  const [minting, setMinting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedNum, setSelectedNum] = useState(listOption[0]);
  const handleClick = (item) => {
    setSelectedNum(item);
  };
  const isMobile = useMedia("(max-width: 676px)");

  const { active, activate, account: walletAccount, library } = useWeb3React();
  const { chainId } = useChainId();

  const ftmBalance = useProviderBalance();

  // const [nftId, setNFTId] = useState([]);
  const [dataMinted, setDataMinted] = useState([]);

  const nftClubAddress = getContract(chainId, "NFTClub");

  const MummyClubSaleAddress = getContract(chainId, "MummyClubSale");

  const { data: estimateAmount } = useSWR(
    [`NFT:estimateAmount:${selectedNum.num}`, chainId, MummyClubSaleAddress, "estimateAmount"],
    {
      fetcher: contractFetcher(library, MummyClubSale, [selectedNum.num]),
    }
  );
  const { data: currentPP } = useSWR([`NFT:currentPP:${active}`, chainId, MummyClubSaleAddress, "getCurrentPP"], {
    fetcher: contractFetcher(library, MummyClubSale),
  });
  const { data: totalSupply } = useSWR([`NFT:totalSupply:${active}`, chainId, nftClubAddress, "totalSupply"], {
    fetcher: contractFetcher(library, NFTClub),
  });
  // const { data: totalPower } = useSWR([`NFT:totalSupply:${active}`, chainId, MummyClubSaleAddress, "totalPower"], {
  //   fetcher: contractFetcher(library, MummyClubSale),
  // });
  const { data: totalVolume } = useSWR([`NFT:totalSupply:${active}`, chainId, MummyClubSaleAddress, "totalVolume"], {
    fetcher: contractFetcher(library, MummyClubSale),
  });
  const nextPrice =
    currentPP && currentPP._mccPrice ? currentPP._mccPrice.mul(bigNumberify(101)).div(bigNumberify(100)) : 0;
  const nextPower = currentPP && currentPP._mccPower ? (parseFloat(currentPP._mccPower) * 99) / 100 : 0;
  const nextBonus =
    currentPP && currentPP._esMMYBonus ? currentPP._esMMYBonus.mul(bigNumberify(99)).div(bigNumberify(100)) : 0;

  useEffect(() => {
    if (!isVisible) {
      setIsSuccess(false);
    }
  }, [isVisible]);

  const number = Number(totalSupply);
  const lastMintNFT = [];
  for (let index = number; index > number - 6 && index > 0; index--) {
    lastMintNFT.push(index);
  }
  const mintNFT = () => {
    const opts = {
      value: estimateAmount?._totalPrice.toString(),
      // gasLimit: bigNumberify(7258590),
      successMsg: `Mint completed`,
      failMsg: `Mint failed`,
      sentMsg: `Mint submitted`,
      pendingTxns,
      setPendingTxns,
    };
    const params = [selectedNum.num];
    const method = "mintMummyClub";
    const contract = new ethers.Contract(MummyClubSaleAddress, MummyClubSale.abi, library.getSigner());
    callContract(chainId, contract, method, params, opts)
      .then(async (tx) => {
        try {
          setMinting(true);
          const receipt = await tx.wait();

          abiDecoder.addABI(MummyClubSale.abi);
          const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
          const eventLog = decodedLogs.filter((item) => item.name === "AssetMinted");
          if (eventLog) {
            const listData = [];
            eventLog.forEach((element) => {
              listData.push({
                nftID: element.events[1].value,
                power: element.events[2].value,
                bonus: element.events[3].value,
              });
            });
            // setNFTId(listData);
            setDataMinted(listData);
          }
          setIsSuccess(true);
        } catch (error) {
          console.log(error);
          // history.push("/your-nft")
        }
      })
      .finally(() => {
        setMinting(false);
      });
    //TODO
    //   const receipt = {
    //     "to": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //     "from": "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //     "contractAddress": null,
    //     "transactionIndex": 1,
    //     "gasUsed": {
    //         "type": "BigNumber",
    //         "hex": "0x0830f6"
    //     },
    //     "logsBloom": "0x00000000000000000000000000000000000000000000000000000000000200000082000000000000000000100000000000008000000020000000000000008000000000000000000000000108000000800001000000000000000100000000000001000000020002000000000000000800000000000000040080000010000000000000000000000000000000200000000000000000000000000000000000000080200000000100002000080000002000000000000000000000000000002000004000100002000010000001000000001100000020000000800000108008000020000000000000000000000000100000010008000000000000000000000002100000",
    //     "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //     "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //     "logs": [
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000000000d9a505f7fa2e000000000000000000000000000000000000000000000000003c386f53c670c9700000000000000000000000000000000000000000000000000799dce5f9df2c000000000000000000000000000000000000000000000000003b5eca4dce769b70000000000000000000000000000000000000000000000000087381ebf1d95a0",
    //             "logIndex": 2,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000026",
    //             "logIndex": 3,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000026"
    //             ],
    //             "data": "0x",
    //             "logIndex": 4,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 5,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000027",
    //             "logIndex": 6,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000027"
    //             ],
    //             "data": "0x",
    //             "logIndex": 7,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 8,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000028",
    //             "logIndex": 9,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000028"
    //             ],
    //             "data": "0x",
    //             "logIndex": 10,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 11,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000003a22c8bc68e98b0faf40f349dd2b2890fae01484"
    //             ],
    //             "data": "0x0000000000000000000000000000000000000000000000000043b467b83ecb9c000000000000000000000000000000000000000000000000040eb1c19bccc3ed00000000000000000000000000000000000000000000010c455e0c95b2fcabf100000000000000000000000000000000000000000000000003cafd59e38df85100000000000000000000000000000000000000000000010c45a1c0fd6b3b778d",
    //             "logIndex": 12,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         }
    //     ],
    //     "blockNumber": 29787980,
    //     "confirmations": 4,
    //     "cumulativeGasUsed": {
    //         "type": "BigNumber",
    //         "hex": "0x08e586"
    //     },
    //     "effectiveGasPrice": {
    //         "type": "BigNumber",
    //         "hex": "0x0843f7031a"
    //     },
    //     "status": 1,
    //     "type": 2,
    //     "byzantium": true,
    //     "events": [
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000000000d9a505f7fa2e000000000000000000000000000000000000000000000000003c386f53c670c9700000000000000000000000000000000000000000000000000799dce5f9df2c000000000000000000000000000000000000000000000000003b5eca4dce769b70000000000000000000000000000000000000000000000000087381ebf1d95a0",
    //             "logIndex": 2,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000026",
    //             "logIndex": 3,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x26"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000026"
    //             ],
    //             "data": "0x",
    //             "logIndex": 4,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x26"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 5,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000027",
    //             "logIndex": 6,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x27"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000027"
    //             ],
    //             "data": "0x",
    //             "logIndex": 7,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x27"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 8,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
    //             ],
    //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000028",
    //             "logIndex": 9,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x28"
    //                 }
    //             ],
    //             "event": "AssetMinted",
    //             "eventSignature": "AssetMinted(address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000000000000000000000000000000000000000000028"
    //             ],
    //             "data": "0x",
    //             "logIndex": 10,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
    //             "args": [
    //                 "0x0000000000000000000000000000000000000000",
    //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
    //                 {
    //                     "type": "BigNumber",
    //                     "hex": "0x28"
    //                 }
    //             ],
    //             "event": "Transfer",
    //             "eventSignature": "Transfer(address,address,uint256)"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
    //             "topics": [
    //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
    //             ],
    //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
    //             "logIndex": 11,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         },
    //         {
    //             "transactionIndex": 1,
    //             "blockNumber": 29787980,
    //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
    //             "address": "0x0000000000000000000000000000000000001010",
    //             "topics": [
    //                 "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
    //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
    //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
    //                 "0x0000000000000000000000003a22c8bc68e98b0faf40f349dd2b2890fae01484"
    //             ],
    //             "data": "0x0000000000000000000000000000000000000000000000000043b467b83ecb9c000000000000000000000000000000000000000000000000040eb1c19bccc3ed00000000000000000000000000000000000000000000010c455e0c95b2fcabf100000000000000000000000000000000000000000000000003cafd59e38df85100000000000000000000000000000000000000000000010c45a1c0fd6b3b778d",
    //             "logIndex": 12,
    //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
    //         }
    //     ]
    // }
    //   abiDecoder.addABI(NFTClub.abi);
    //   const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
    //   const eventLog = decodedLogs.filter((item) => item.name === "AssetMinted");
    //   if (eventLog) {
    //     const listID = eventLog.map((item) => item.events[1].value)
    //     setNFTId(listID)
    //   }
    //   setIsSuccess(true);
    //   setMinting(false);
  };

  const renderContent = () => {
    const setting1 = {
      dots: false,
      infinite: true,
      speed: 2000,
      autoplay: true,

      slidesToShow: lastMintNFT.length > 5 ? 5 : lastMintNFT.length,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    };
    if (minting) return <ContentLoading />;
    if (isSuccess)
      return (
        <SuccessContent
          nftId={dataMinted}
          mccPower={currentPP?._mccPower}
          esMMYBonus={estimateAmount?._totalBonus}
          setIsSuccess={setIsSuccess}
        />
      );
    return (
      <ModalWrap>
        <NFT>
          <TitleMobile>
            <div className="mint-title">Join Mummy Club, Earn Reward Together</div>
            <div className="mint-desc">
              The earlier you mint, the higher the power to earn rewards.{" "}
              <a href={getDocsClub(chainId)} target={"blank"}>
                Learn More <img src={linkWhite} alt="img" />{" "}
              </a>
            </div>{" "}
          </TitleMobile>
          <img alt="mint" className="mum1" src={TXT[chainId]?.logo1} />{" "}
          <div className="info-wrap">
            <img alt="mint" className="nft-mobile" src={TXT[chainId]?.logo1} />{" "}
            <div className="info-container">
              <div>
                <div className="label">Total NFT Minted</div>
                <div className="amount">{formatAmount(totalSupply, 0, 0, true)}</div>
              </div>
              <div>
                <div className="label">Total NFT Supply</div>
                <div className="amount">
                  5,000
                  {/* {formatAmount(totalPower, 0, 0, true)} */}
                </div>
              </div>
              <div>
                <div className="label">Total Volume</div>
                <div className="amount">
                  <img alt="img" src={TXT[chainId]?.nativeIcon} /> {formatAmount(totalVolume, 18, 2, true)}
                </div>
              </div>{" "}
            </div>
          </div>
        </NFT>
        <MintInfo>
          <MintInfoContent>
            <div className="mint-title">Join Mummy Club, Earn Reward Together</div>
            <div className="mint-desc">
              The earlier you mint, the higher the power to earn rewards.{" "}
              <a href={getDocsClub(chainId)} target="_blank" rel="noreferrer">
                Learn More <img src={linkWhite} alt="img" />{" "}
              </a>
            </div>
            <div className="balance-box">
              <div>
                <div className="your-balance number-nft"> Number of NFT</div>
                <Menu>
                  <Menu.Button as="div">
                    <button className=" PositionDropdown-dots-icon btn-dropdown">
                      <>
                        {" "}
                        <div className="content-dropdown">
                          {selectedNum.num} &nbsp;
                          <span>
                            ({formatAmount(estimateAmount?._totalPrice, 18, 2, true)} {TXT[chainId]?.nativeToken})
                          </span>
                        </div>
                        <img src={DropdownIcon} className="icon-down" alt="icon" />
                      </>
                    </button>
                  </Menu.Button>
                  <div className="PositionDropdown-extra-options">
                    <Menu.Items as="div" className="menu-items">
                      {listOption.map((item, id) => {
                        return (
                          <Menu.Item onClick={() => handleClick(item)}>
                            <div className="menu-item">
                              <p>{item.num} </p>
                            </div>
                          </Menu.Item>
                        );
                      })}
                    </Menu.Items>
                  </div>
                </Menu>
              </div>
              <div>
                <div className="your-balance">
                  Avail:{" "}
                  <span>
                    {formatAmount(ftmBalance, 18, 2, true)} {TXT[chainId]?.nativeToken}
                  </span>
                </div>
                {!active ? (
                  <button className="btn-primary" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                ) : estimateAmount &&
                  estimateAmount?._totalPrice &&
                  estimateAmount._totalPrice.lt(ftmBalance) &&
                  Number(totalSupply) <= 5000 ? (
                  <button className="btn-primary" disabled={Date.now() <= 1671462000000} onClick={mintNFT}>
                    {" "}
                    <img alt="mint " src={MintIc} /> Mint NFT
                  </button>
                ) : (
                  <button className="btn-primary disable" disabled>
                    {Number(totalSupply) <= 5000 ? `Insufficient ${TXT[chainId]?.nativeToken} balance` : "Sold out"}
                  </button>
                )}
              </div>
            </div>

            <div className="mint-stats">
              <div>
                <div div className="first-box">
                  <div className="mint-stats-label">Current Power </div>
                  <div className="mint-stats-amount">{formatAmount(currentPP?._mccPower, 0, 0, true)}</div>
                </div>
                <div>
                  <div className="mint-stats-label">
                    Next Power
                    <Tooltip
                      position={`${isMobile ? "left-bottom" : "right-bottom "} `}
                      handle={<img alt="mint" src={InfoIc} />}
                      renderContent={() => {
                        return "Power decreases by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">{commify(nextPower.toFixed(0))}</div>
                </div>
              </div>
              <div>
                <div className="first-box">
                  <div className="mint-stats-label">Price</div>
                  <div className="mint-stats-amount">
                    {" "}
                    <img alt="img" src={TXT[chainId]?.nativeIcon} /> {formatAmount(currentPP?._mccPrice, 18, 2, true)}{" "}
                    {TXT[chainId]?.nativeToken}
                  </div>
                </div>{" "}
                <div>
                  <div className="mint-stats-label">
                    Bonus
                    <Tooltip
                      position={`${isMobile ? "left-bottom" : "right-bottom "}`}
                      handle={<img alt="mint" src={InfoIc} />}
                      renderContent={() => {
                        return "You can stake or vest your bonus esMMY on the Earn tab. You are not required to reserve funds to vest your bonus esMMY ONLY if you have never staked it before.";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">
                    {formatAmount(currentPP?._esMMYBonus, 18, 2, true) + " esMMY"}
                  </div>
                </div>
                {/* <div>
                  <div className="mint-stats-label">
                    Next Price
                    <Tooltip
                      position={`${isMobile ? "left-bottom " : "right-bottom"} `}
                      handle={<img alt="mint" src={InfoIc} />}
                      renderContent={() => {
                        return "Price increases by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">
                    {" "}
                    <img alt="img" src={TXT[chainId]?.nativeIcon} /> {formatAmount(nextPrice, 18, 2, true)}{" "}
                    {TXT[chainId]?.nativeToken}
                  </div>
                </div> */}
              </div>
              {/* <div>
                <div className="first-box">
                  <div className="mint-stats-label">Current Power</div>
                  <div className="mint-stats-amount">{formatAmount(currentPP?._mccPower, 0, 0, true)} </div>
                </div>
                <div>
                  <div className="mint-stats-label">
                    Next Power
                    <Tooltip
                      position={`${isMobile ? "left-bottom" : "right-bottom "}`}
                      handle={<img alt="mint" src={InfoIc} />}
                      renderContent={() => {
                        return "Power decreases by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">
                    {commify(nextPower.toFixed(0))}
                  </div>
                </div>
              </div> */}
            </div>
          </MintInfoContent>
          <div className="last-mint">
            <div className="last-mint-label">Latest NFT Minted</div>
            {lastMintNFT.length > 0 ? (
              <div className="last-mint-images1">
                <Slider {...setting1}>
                  {lastMintNFT.map((item, id) => {
                    return (
                      <div key={id} className="nft-mint-item">
                        <img
                          style={{ width: "80px", height: "80px" }}
                          src={`${getLinkCDN(chainId)}${item}.png`}
                          alt="nft"
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            ) : (
              <EmptyWrapper>No NFT has been minted yet.</EmptyWrapper>
            )}
          </div>
        </MintInfo>
      </ModalWrap>
    );
  };
  return (
    <div>
      <Modal className="modal-mint" isVisible={isVisible} setIsVisible={minting ? () => {} : setIsVisible}>
        {renderContent()}
      </Modal>
    </div>
  );
}

function SuccessContent(props) {
  const { nftId, mccPower, esMMYBonus, setPendingTxns, setIsSuccess } = props;
  const { chainId } = useChainId();
  const setting = {
    dots: true,
    infinite: true,
    speed: 1000,

    autoplay: false,

    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  let bonus = bigNumberify(0);
  nftId.forEach((element) => {
    bonus = bonus.add(parseValue(element.bonus, 0));
  });
  return (
    <SuccessWrap>
      <div className="success-title">Congratulation!</div>
      <div className="success-content">
        {" "}
        <div className="success-desc">
          You have just received <span>{nftId.length}</span> NFT & <span>{formatAmount(bonus, 18, 2, true)}</span> esMMY
          bonus in your wallet
        </div>
        <div className="slider-wrapper">
          <Slider {...setting}>
            {nftId.map((item, id) => {
              return (
                <div className="item-nft" key={id}>
                  <img src={`${getLinkCDN(chainId)}${item.nftID}.png`} alt="img" />
                  <div className="nft-name">Mummy #{item.nftID}</div>
                  <div className="nft-info">
                    <div className="nft-power nft-info-power ">
                      Power: &nbsp; <span>{formatAmount(item.power, 0, 0, true)}</span>
                    </div>{" "}
                    <div className="nft-power">
                      Bonus: <span>{formatAmount(item.bonus, 18, 2, true)} esMMY</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="nft-action">
          <button className="btn-primary" onClick={() => setIsSuccess(false)}>
            Mint more NFT
          </button>
          <a className="btn-outline" href="#/stake-nft">
            Go to stake NFT
          </a>
        </div>
      </div>
    </SuccessWrap>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={icPrev} alt="img" className="ic-next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      {" "}
      <img src={icPrev} alt="img" />
    </div>
  );
}

function ContentLoading() {
  const [widthPercent, setWidthPercent] = useState(0);

  useEffect(() => {
    let width = 0;
    let timmer;

    timmer = setInterval(() => {
      if (width < 90) {
        width += 1;
        setWidthPercent(width);
      }
    }, 500);

    return () => {
      clearInterval(timmer);
    };
  }, []);
  return (
    <LoadingWrap>
      <div className="loading-title">Minting</div>
      <div className="loading-wrap">
        <div className="loading-bar" style={{ width: `${widthPercent}%` }}></div>
      </div>
      <div className="loading-percent">{widthPercent}%</div>
    </LoadingWrap>
  );
}
const LoadingWrap = styled.div`
  text-align: center;
  .loading-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #03f5ae;
  }
  .loading-wrap {
    margin: 24px 0;
    width: 375px;
    height: 6px;
    background: #222123;
    .loading-bar {
      height: 100%;
      background: linear-gradient(90deg, #03f5ae 0%, #e3fff7 100%);
    }
  }
  .loading-percent {
    font-size: 74px;
    line-height: 100%;
  }
`;
const SuccessWrap = styled.div`
  .slider-wrapper {
    img {
      border-radius: 16px;

      width: 200px;
      height: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    .item-nft {
    }
    .slick-next {
      right: -12px;
    }
    .slick-prev {
      left: -12px;
    }
    .slick-arrow {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      width: 32px;
      height: 32px;
      display: flex;

      img {
        width: 24px;
        height: 24px;
        margin-top: 4px;
      }
      .ic-next {
        transform: rotate(180deg);
      }
      &:before {
        display: none;
      }
    }
    .slick-dots li button:before {
      color: #fff;
      opacity: 0.6;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
    }
    margin-bottom: 28px;
  }
  .success-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    margin: 0px 9px;
  }
  .nft-avt {
    width: 200px;
    height: 200px;
    margin: 16px auto;
    display: block;
  }
  .success-content {
    margin: 0px 9px;
    width: 272px;
    font-weight: 400;

    line-height: 140%;
    text-align: center;

    .success-desc {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      margin: 16px 0;
      span {
        color: #fff;
      }
    }
    .nft-name {
      font-weight: 700;
      margin-top: 8px;
    }
    .nft-info {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 4px 0 12px 0;
      height: 14px;
    }
    .nft-info-power {
      margin-right: 4px;
      &::after {
        content: "";
        width: 1px;
        height: 14px;
        background: #fff;
        margin-left: 4px;
      }

      display: flex;
      align-items: center;
    }
    .nft-power {
      span {
        color: #03f5ae;
      }
    }
    .nft-action {
      a,
      button {
        text-decoration: none;
        height: 36px;
        width: 100%;
        &:first-child {
          margin-bottom: 12px;
        }
      }
    }
  }
`;
const ModalWrap = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  overflow: hidden;
  gap: 24px;
  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
    gap: 2px;
  }
  .mint-title {
    font-weight: 700;
    font-size: 24px;

    margin-bottom: 8px;
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  .mint-desc {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    @media screen and (max-width: 700px) {
      font-size: 12px;
    }
    a {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      text-decoration: none;
      img {
        margin-left: 4px;
      }
    }
  }
`;

const NFT = styled.div`
  .mum1 {
    height: 300px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .info-wrap {
    .nft-mobile {
      display: none;
    }
    @media screen and (max-width: 767px) {
      padding: 0;
      display: flex;
      align-items: center;
      margin-top: 16px;
      .nft-mobile {
        display: block;
        height: 80px;
      }
      .info-container {
        flex: 1;
        padding: 6px 16px 6px 0;
        > div {
          font-size: 12px !important;
          font-weight: 400;
        }

        .amount {
          font-weight: 400 !important;
          min-width: 100px;
          /* text-align: right; */
          justify-content: flex-end;
          img {
            width: 16px;
          }
        }
      }
    }
    position: relative;
    top: -3px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 16px;
    .info-container > div {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .amount {
        font-weight: 700;
        display: flex;
        align-items: center;
        img {
          margin-right: 4px;
        }
      }
    }
  }
`;
const MintInfoContent = styled.div`
  height: 300px;

  @media screen and (max-width: 767px) {
    height: auto;
    .mint-title,
    .mint-desc {
      display: none;
    }
  }
`;
const TitleMobile = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const MintInfo = styled.div`
  .your-balance {
    margin-top: 14px;
    margin-bottom: 4px;
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    span {
      color: #fff;
    }
    &.number-nft {
      text-align: left;
    }
  }
  .btn-primary {
    width: 100%;
    height: 48px;
    margin-bottom: 12px;
    &:hover {
      background: #04d397;
      color: #080715 !important;
    }
  }
  .mint-stats {
    margin-bottom: 36px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 16px;
    font-weight: 400;

    .first-box {
      margin-bottom: 16px;
    }

    line-height: 140%;
    .mint-stats-label {
      font-size: 12px;
      display: flex;
      .Tooltip {
        margin-left: 4px;
        position: relative;
        top: 2px;
      }
    }
    .mint-stats-amount {
      font-size: 14px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        margin-right: 4px;
      }
    }
    @media screen and (max-width: 767px) {
      > div {
        &:not(:last-child) {
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
      margin-bottom: 16px;
      gap: 8px;
      grid-template-columns: 100%;
      div {
        > div {
          display: flex;
          justify-content: space-between;
        }
      }
      .first-box {
        margin-bottom: 8px;
      }
    }
  }
  .last-mint {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    height: 112px;
    position: relative;

    .last-mint-label {
      position: absolute;
      top: -14px;
      left: 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #ffffff;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      background: #262324;
      padding: 4px 8px;
      width: fit-content;
    }

    .last-mint-images1 {
      padding-top: 16px;
      margin-left: auto;
      margin-right: auto;
      width: 484px;
      @media screen and (max-width: 991px) {
        width: 385px;
      }
      @media screen and (max-width: 700px) {
        width: 280px;
      }
      img {
        border-radius: 12px;
      }
      mask-image: linear-gradient(270deg, rgba(217, 217, 217, 0.2) 0%, #d9d9d9 49.48%, rgba(217, 217, 217, 0.2) 100%);
    }
  }
  .balance-box {
    display: grid;
    grid-template-columns: 160px auto;
    gap: 12px;
  }
`;
const EmptyWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(255, 255, 255, 0.6);
`;
