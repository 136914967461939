import "./Referrals.css";
import React from "react";
import { useLocalStorage } from "react-use";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import SEO from "components/Common/SEO";
import Tab from "components/Tab/Tab";
import Loader from "components/Common/Loader";
import Footer from "components/Footer/Footer";
import { getPageTitle, isHashZero } from "lib/legacy";
import {
  useReferralsData,
  registerReferralCode,
  useCodeOwner,
  useReferrerTier,
  useUserReferralCode,
} from "domain/referrals";
import JoinReferralCode from "components/Referrals/JoinReferralCode";
import AffiliatesStats from "components/Referrals/AffiliatesStats";
import TradersStats from "components/Referrals/TradersStats";
import AddAffiliateCode from "components/Referrals/AddAffiliateCode";
import { deserializeSampleStats, isRecentReferralCodeNotExpired } from "components/Referrals/referralsHelper";
import { ethers } from "ethers";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { REFERRALS_SELECTED_TAB_KEY } from "config/localStorage";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";

import header_ref from "img/header_ref.svg";
import ref_header_cash from "img/ref_header_cash.svg";

import "./Referrals.scss";

const TRADERS = "Traders";
const AFFILIATES = "Affiliates";
const TAB_OPTIONS = [TRADERS, AFFILIATES];

function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { active, account: walletAccount, library } = useWeb3React();
  const { account: queryAccount } = useParams();
  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }
  const { chainId } = useChainId();
  const [activeTab, setActiveTab] = useLocalStorage(REFERRALS_SELECTED_TAB_KEY, TRADERS);
  const { data: referralsData, loading } = useReferralsData(chainId, account);
  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, "REFERRAL", account], [], {
    deserializer: deserializeSampleStats,
  });
  const { userReferralCode, userReferralCodeString } = useUserReferralCode(library, chainId, account);
  const { codeOwner } = useCodeOwner(library, chainId, account, userReferralCode);
  const { referrerTier: traderTier } = useReferrerTier(library, chainId, codeOwner);

  function handleCreateReferralCode(referralCode) {
    return registerReferralCode(chainId, referralCode, library, {
      sentMsg: `Referral code submitted!`,
      failMsg: `Referral code creation failed.`,
      pendingTxns,
    });
  }

  function renderAffiliatesTab() {
    const isReferralCodeAvailable =
      referralsData?.codes?.length > 0 || recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).length > 0;

    if (loading) return <div className="skeleton-box" style={{height: '30px', marginTop: '20px'}}/>;

    return (
      <div className="container-ref">
        <div className="content-ref">
          <AddAffiliateCode
            isReferralCodeAvailable={isReferralCodeAvailable}
            referralsData={referralsData}
            handleCreateReferralCode={handleCreateReferralCode}
            active={active}
            connectWallet={connectWallet}
            recentlyAddedCodes={recentlyAddedCodes}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
          />
          <AffiliatesStats
            referralsData={referralsData}
            handleCreateReferralCode={handleCreateReferralCode}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
            recentlyAddedCodes={recentlyAddedCodes}
            chainId={chainId}
          />
        </div>
      </div>
    );

    // if (loading) return <Loader />;
    // if (isReferralCodeAvailable) {
    //   return (
    //     <AffiliatesStats
    //       referralsData={referralsData}
    //       handleCreateReferralCode={handleCreateReferralCode}
    //       setRecentlyAddedCodes={setRecentlyAddedCodes}
    //       recentlyAddedCodes={recentlyAddedCodes}
    //       chainId={chainId}
    //     />
    //   );
    // } else {
    //   return (
    //     <AddAffiliateCode
    //       handleCreateReferralCode={handleCreateReferralCode}
    //       active={active}
    //       connectWallet={connectWallet}
    //       recentlyAddedCodes={recentlyAddedCodes}
    //       setRecentlyAddedCodes={setRecentlyAddedCodes}
    //     />
    //   );
    // }
  }

  function renderTradersTab() {
    if (loading) return <div className="skeleton-box" style={{height: '30px', marginTop: '20px'}}/>;

    return (
      <div className="container-ref">
        <div className="content-ref">
          <JoinReferralCode
            userReferralCodeString={userReferralCodeString}
            connectWallet={connectWallet}
            active={active}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
          />
          <TradersStats
            userReferralCodeString={userReferralCodeString}
            chainId={chainId}
            referralsData={referralsData}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            traderTier={traderTier}
          />
        </div>
      </div>
    );

    // if (isHashZero(userReferralCode) || !account || !userReferralCode) {
    //   return (
    //     <JoinReferralCode
    //       connectWallet={connectWallet}
    //       active={active}
    //       setPendingTxns={setPendingTxns}
    //       pendingTxns={pendingTxns}
    //     />
    //   );
    // }
    // return (
    //   <TradersStats
    //     userReferralCodeString={userReferralCodeString}
    //     chainId={chainId}
    //     referralsData={referralsData}
    //     setPendingTxns={setPendingTxns}
    //     pendingTxns={pendingTxns}
    //     traderTier={traderTier}
    //   />
    // );
  }
  const TAB_OPTION_LABELS = { [TRADERS]: `Traders`, [AFFILIATES]: `Affiliates` };

  return (
    <SEO title={getPageTitle("Referrals")}>
      <div className="default-container page-layout Referrals">
        <div
          style={{
            backgroundImage: `url(${header_ref})`,
          }}
          className="section-title-block"
        >
          <div className="section-title-content">
            <div className="left">
              <div className="Page-title">
                <span>Referrals</span>
              </div>
              <div className="Page-description">
                <div>
                  Get fee discounts and earn rebates through the Mummy referral program.
                  <br />
                  For more information, please read the{" "}
                  <ExternalLink href="https://docs.mummy.finance/referrals">referral program details</ExternalLink>.
                </div>
              </div>
            </div>

            <img className="right" src={ref_header_cash} alt="ref_header_cash" />
          </div>
        </div>
        <div className="referral-tab-container">
          <div className="container">
            <Tab
              options={TAB_OPTIONS}
              optionLabels={TAB_OPTION_LABELS}
              option={activeTab}
              setOption={setActiveTab}
              onChange={setActiveTab}
            />
          </div>
        </div>
        {activeTab === AFFILIATES ? renderAffiliatesTab() : renderTradersTab()}
      </div>
      <Footer />
    </SEO>
  );
}

export default Referrals;
