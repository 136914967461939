import React, { memo } from "react";
import styled from "styled-components";

import { formatAmount,getDisplayDecimalByAmount } from "lib/numbers";
import { getTokenDecimal, getTokenSymbol } from "config/tokens";

const StyledOrderItem = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 57px;
  padding: 0px 11px;
  padding: 14px;
  border-bottom: 1px dashed hsla(0, 0%, 100%, 0.062745098);
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    height: unset;
    background: #191B2E;
    border-radius: 16px;

    &.headless {
      padding: 0;
      background-color: transparent !important;
      border: none !important;

      .child:first-child {
        display: none;
      }
    }

    & + div {
      margin-top: 12px;
    }
  }

  &.open-position {
    height: 62.5px;

    .view-details {
      text-align: right;
      a {
        text-decoration: none;
        font-weight: 500;
        color: #04d397;
      }
    }
  }

  .child {
    flex: 1;

    &:first-child {
      .icon {
        display: none;
      }
    }

    @media (max-width: 1023px) {
      width: 100%;
      &:first-child {
        padding-bottom: 13px;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.063);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          display: block;
        }
      }
      &:not(:first-child) {
        text-align: right;
        display: flex;
        margin-top: 12px;

        &::before {
          content: attr(data-name);
          margin-right: auto;
          color: hsla(0, 0%, 100%, 0.7);
        }
      }
    }

    &.time {
      flex: 2;

      color: rgb(132, 142, 156);
    }

    &.market {
      .wrapper {
        .coin {
          font-weight: 500;

          color: rgb(255, 227, 169);
        }

        .position {
          font-weight: 600;
          font-size: 12px;

          color: rgb(7, 186, 117);
        }
      }
    }

    &.size {
    }

    &.net-value {
      position: relative;
      top: -2px;
      .Tooltip {
        position: relative;
        top: 2px;
      }
      .wrapper {
        .position {
          margin-top: 8px;

          color: rgb(246, 71, 93);
          font-size: 12px;
        }
      }
    }

    &.collateral {
    }

    &.pnl {
      color: #34f5ae;
    }

    &.liquidation {
      color: rgb(255, 179, 19);
    }

    &.view-details {
      text-align: right;
      a {
        text-decoration: none;
        font-weight: 500;
        color: #04d397;
      }
    }
  }
`;

const formatNumber2 = (num) => {
  return Number(Number(num).toFixed(3)).toLocaleString("en-US", { minimumFractionDigits: 3 });
};

const OrderItem = (props) => {
  const {
    chainId,
    isHeadless,
    isSmallScreen,
    data: {
      indexToken,
      isLong,
      type,
      triggerPrice,
      size,
      amountIn,
      inputToken,
      outputToken,
      marketPrice,
      mintOut,
      triggerRatio,
      triggerAboveThreshold,
      inputTokenMarketPrice,
      outputTokenMarketPrice,
    },
  } = props;
  const triggerPricePrefix = triggerAboveThreshold ? "≥" : "≤";
  return (
    // <StyledOrderItem onClick={setVisibleModal} className={`${isHeadless ? "headless" : ""}`}>
    <StyledOrderItem className={`${isHeadless ? "headless" : ""}`}>
      <div className={`market child`} style={!isSmallScreen ? { width: "10%", flex: "unset" } : {}}>
        <div className="wrapper">
          {type !== "swap" ? (
            <>
              <div className="coin">{getTokenSymbol(chainId, indexToken)}/USD</div>
              <div className="position" style={{ color: isLong ? "#03F5AE" : "#F6475D" }}>
                {isLong ? "LONG" : "SHORT"}
              </div>
            </>
          ) : (
            <>
              <div className="coin">
                {getTokenSymbol(chainId, inputToken)}/{getTokenSymbol(chainId, outputToken)}
              </div>
              <div className="position" style={{ color: "#fff" }}>
                SWAP
              </div>
            </>
          )}
        </div>
      </div>
      <div className="type child" data-name="Type" style={!isSmallScreen ? { width: "10%", flex: "unset" } : {}}>
        {["swap", "increase"].includes(type) ? "LIMIT" : "TRIGGER"}
      </div>
      <div className="size child" data-name="Order" style={!isSmallScreen ? { width: "40%", flex: "unset" } : {}}>
        <span style={isSmallScreen ? { width: "45%" } : {}}>
          {type === "decrease" || type === "increase"
            ? `${type === "decrease" ? "Decrease" : "Increase"} ${getTokenSymbol(chainId, indexToken)} ${
                isLong ? "LONG" : "SHORT"
              } by $${formatAmount(size, 30, 3, true)}`
            : type === "swap"
            ? `Swap ${formatAmount(amountIn, getTokenDecimal(chainId, inputToken), 3, true)}
          ${getTokenSymbol(chainId, inputToken)} for at least ${formatAmount(
                mintOut,
                getTokenDecimal(chainId, outputToken),
                3,
                true
              )} ${getTokenSymbol(chainId, outputToken)}`
            : ""}
        </span>
      </div>
      <div className="collateral child" data-name="Trigger Condition">
        {type === "swap"
          ? !["BTC", "ETH"].includes(getTokenSymbol(chainId, inputToken)) &&
            !["BTC", "ETH"].includes(getTokenSymbol(chainId, outputToken))
            ? `${formatNumber2(1 / Number(formatAmount(triggerRatio, 30, 3)))} ${getTokenSymbol(
                chainId,
                outputToken
              )} / ${getTokenSymbol(chainId, inputToken)}`
            : ["BTC", "ETH"].includes(getTokenSymbol(chainId, inputToken))
            ? `${formatNumber2(1 / (triggerRatio / 1e30))} ${getTokenSymbol(chainId, outputToken)} / ${getTokenSymbol(
                chainId,
                inputToken
              )}`
            : `${formatNumber2(Number(formatAmount(triggerRatio, 30, 3)))} ${getTokenSymbol(
                chainId,
                inputToken
              )} / ${getTokenSymbol(chainId, outputToken)}`
          : `Mark price ${triggerPricePrefix} $${formatAmount(triggerPrice, 30, getDisplayDecimalByAmount(triggerPrice, 30), true)}`}
      </div>
      <div className="child" data-name="Mark Price">
        {type === "swap"
          ? ["BTC", "ETH"].includes(getTokenSymbol(chainId, outputToken))
            ? `${formatNumber2(outputTokenMarketPrice / inputTokenMarketPrice)} ${getTokenSymbol(
                chainId,
                inputToken
              )} / ${getTokenSymbol(chainId, outputToken)}`
            : `${formatNumber2(inputTokenMarketPrice / outputTokenMarketPrice)} ${getTokenSymbol(
                chainId,
                outputToken
              )} / ${getTokenSymbol(chainId, inputToken)}`
          : `$${formatAmount(marketPrice, 30, getDisplayDecimalByAmount(marketPrice, 30), true)}`}
      </div>
    </StyledOrderItem>
  );
};

export default memo(OrderItem);
