import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import gmxBigIcon from "img/icons/MMY.svg";
import glpBigIcon from "img/ic_mlp_custom.svg";
import glp40Icon from "img/ic_mlp_40.svg";
import gmx40Icon from "img/ic_mmy_40.svg";
import arb24Icon from "img/ic_arbitrum_24.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import op24Icon from "img/ic_op_24.svg";
import { isHomeSite } from "lib/legacy";

import { useWeb3React } from "@web3-react/core";

import APRLabel from "../APRLabel/APRLabel";
import { HeaderLink } from "../Header/HeaderLink";
import { ARBITRUM, AVALANCHE, FANTOM, OP } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useGmxPrice, useTotalGmxStaked, useTotalGmxSupplyAll } from "domain/legacy";
import TooltipComponent from "components/Tooltip/Tooltip";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import { BASIS_POINTS_DIVISOR, GLP_DECIMALS, GMX_DECIMALS, USD_DECIMALS } from "lib/legacy";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { t, Trans } from "@lingui/macro";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import GlpManager from "abis/GlpManager.json";
import { getContract } from "config/contracts";
import ReaderV2 from "abis/ReaderV2.json";
import { ethers } from "ethers";

const { AddressZero } = ethers.constants;


export default function TokenCard({ showRedirectModal, redirectPopupTimestamp }) {
  const isHome = isHomeSite();
  const history = useHistory();
  const { chainId } = useChainId();

  // const isHome = history.location.pathname === "/" ? true : false;
  const { active, library } = useWeb3React();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1110px)" });
  const { gmxPrice } = useGmxPrice(chainId, {}, active);
  const { gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum } = useGmxPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );
  const glpManagerAddress = getContract(chainId, "GlpManager");
  const readerAddress = getContract(chainId, "Reader");
  const gmxAddress = getContract(chainId, "GMX");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");

  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });
  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let glpPrice;
  let glpSupply;
  if (aum && totalSupplies && totalSupplies[3]) {
    glpSupply = totalSupplies[3];

    glpPrice =
      aum && aum.gt(0) && glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
        : expandDecimals(1, USD_DECIMALS);
  }
  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    if (isHome && showRedirectModal) {
      return (
        <HeaderLink
          to={to}
          className={className}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {children}
        </HeaderLink>
      );
    }

    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  return (
      <TokenCardContainer>
        <TokenCardCB>
          <CardTitle>
            <TokenImg>
              <img src={gmx40Icon} alt="MMY Token Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">MMY</div>
                <div className="token-price">
                  {" "}
                  {!gmxPrice && <div className="skeleton-box" />}
                  {gmxPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(gmxPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div className="customTooltip">
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(gmxPriceFromFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(gmxPriceFromOP, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Arbitrum`}
                            value={formatAmount(gmxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
              <ExternalLink
                href="https://docs.mummy.finance/tokenomics"
                target={"_blank"}
                className="btn-readmore border-btn"
              >
                Read more
              </ExternalLink>
            </TokenInfo>
          </CardTitle>
          <TokenDepcryption>MMY is the utility and governance token. Accrues 30% of the platform's generated fees.</TokenDepcryption>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                Fantom APR:{" "}
                <span>
                  <APRLabel chainId={FANTOM} label="gmxAprTotal" key="FANTOM" />
                </span>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy default-btn" network={FANTOM}>
                Buy on Fantom
              </BuyLink>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                Optimism APR:{" "}
                <span>
                  <APRLabel chainId={OP} label="gmxAprTotal" key="OP" />
                </span>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy default-btn" network={OP}>
                Buy on Optimism
              </BuyLink>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                Arbitrum APR:{" "}
                <span>
                  <APRLabel chainId={ARBITRUM} label="gmxAprTotal" key="ARBITRUM" />
                </span>
              </div>
              <BuyLink to="/buy_mmy" className="btn-buy default-btn" network={ARBITRUM}>
                Buy on Arbitrum
              </BuyLink>
            </div>
          </TokenAPR>
        </TokenCardCB>
        <TokenCardCB>
          <CardTitle>
            <TokenImg>
              <img src={glp40Icon} alt="mlp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">MLP</div>
                <div className="token-price">{glpPrice ? `$${formatAmount(glpPrice, USD_DECIMALS, 3, true)}` : <div className="skeleton-box" />}</div>
              </div>{" "}
              <ExternalLink href="https://docs.mummy.finance/mlp" target={"_blank"} className="btn-readmore border-btn">
                Read more
              </ExternalLink>
            </TokenInfo>
          </CardTitle>
          <TokenDepcryption>MLP is the liquidity provider token. Accrues 60% of the platform's generated fees.</TokenDepcryption>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={ftm24Icon} alt="ftm-symbol" />
                Fantom APR:{" "}
                <span>
                  <APRLabel chainId={FANTOM} label="glpAprTotal" key="FANTOM" />
                </span>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy default-btn" network={FANTOM}>
                Buy on Fantom
              </BuyLink>
            </div>
            <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                Optimism APR:{" "}
                <span>
                  <APRLabel chainId={OP} label="glpAprTotal" key="OP" />
                </span>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy default-btn" network={OP}>
                Buy on Optimism
              </BuyLink>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                Arbitrum APR:{" "}
                <span>
                  <APRLabel chainId={ARBITRUM} label="glpAprTotal" key="ARBITRUM" />
                </span>
              </div>
              <BuyLink to="/buy_mlp" className="btn-buy default-btn" network={ARBITRUM}>
                Buy on Arbitrum
              </BuyLink>
            </div>
          </TokenAPR>
        </TokenCardCB>
      </TokenCardContainer>
  );
}
const TokenDepcryption = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 76px;
`;
const TokenAPR = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  overflow: hidden;

  > .row {
    width: 100%;
    padding: 18px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-image: linear-gradient(to right, #13b5ec1a, #13b5ec00);

    &.op {
      background-image: linear-gradient(to right, #ff04201a, #ff042000);
      border-top: 1px solid #ffffff1a;
    }

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      margin-right: auto;
      display: flex;
      align-items: center;
      gap: 8px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #03f5ae;
      }
    }

    .btn-buy {
      border-radius: 8px;
      width: 140px;
      /* background-color: #03f5ae; */
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      /* color: #080715; */
      text-decoration: none;
    }

    @media (max-width: 767px) {
      flex-direction: column;

      .btn-buy {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  .token-symbol {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 4px;
    font-weight: 400;
  }
  .token-price {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }
  .btn-readmore {
    height: 40px;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #fff;
  }
`;

const TokenImg = styled.div`
  padding: 16px;
  background-image: linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);
  border-radius: 0px 0px 12px 12px;

  img {
    width: 48px;
    height: 48px;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 24px;
  right: 24px;

  @media (max-width: 767px) {
    left: 16px;
    right: 16px;
  }
`;

const TokenCardCB = styled.div`
  padding: 24px;
  background-color: #191b2e;
  border-radius: 12px;
  position: relative;

  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .content-mlp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

