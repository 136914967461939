import { createContext, useContext, useState } from "react";

const Context = createContext();

const Provider = ({ children }) => {
  const [lastUpdate, setLastUpdate] = useState();
  return <Context.Provider value={{ lastUpdate, setLastUpdate }}>{children}</Context.Provider>;
};

export const useAnalyticsContext = () => {
  const { lastUpdate, setLastUpdate } = useContext(Context);

  return [lastUpdate, setLastUpdate];
};

export default Provider;
