import { useEffect, useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import useSWR from "swr";
import { t } from "@lingui/macro";

import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";
import { useGmxPrice } from "domain/legacy";
import TooltipComponent from "components/Tooltip/Tooltip";
import ftmIcon from "img/ic_ftm_24.svg";
import opIcon from "img/ic_op_24.svg";
import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  USD_DECIMALS,
  GLP_DECIMALS,
} from "lib/legacy";

import { StyledWrapperSection, StyledWrapperSectionIpad, StyledVaultInfoSection, StyledVaultInfo } from "./index.style";

import gmxIcon from "img/ic_mmy_custom.svg";
import MMY_FTM from "img/MMY-FTM.svg";
import MMY_ETH from "img/MMY-ETH.svg";
import tokensIcon from "img/tokens.svg";

import VaultJson from "abis/Vault.json";
import NewVaultJson from "abis/NewVault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import GlpManager from "abis/GlpManager.json";
import VaultStrategyMMY from "abis/VaultStrategyMMY.json";
import VaultStrategyMLP from "abis/VaultStrategyMLP.json";
import VaultStrategyMMY_WFTM_OLD from "abis/VaultStrategyMMY_WFTM_OLD.json";
import VaultStrategyMMY_WFTM from "abis/VaultStrategyMMY_WFTM.json";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { formatAmount, formatKeyAmount, formatNumber, formatAmountFree, expandDecimals } from "lib/numbers";
import VaultAction from "./VaultAction";
import moment, { now } from "moment";
import VaultTab from "./VaultTab";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { useChainId } from "lib/chains";
import { FANTOM } from "config/chains";
import { Trans } from "@lingui/react";
import RouterTab from "components/RouterTab";
import { useMedia } from "react-use";

export const MMY_VAULT = "MMY vault";
export const MLP_VAULT = "MLP vault";
export const MMY_WFTM_OLD_VAULT = "MMY_WFTM_OLD vault";
export const MMY_WFTM_VAULT = "MMY_WFTM vault";
export const MMY_WETH_VAULT = "MMY_WETH vault";

const TAB_ICONS = {
  [MMY_VAULT]: gmxIcon,
  [MLP_VAULT]: tokensIcon,
  [MMY_WFTM_OLD_VAULT]: MMY_FTM,
  [MMY_WFTM_VAULT]: MMY_FTM,
  [MMY_WETH_VAULT]: MMY_ETH,
};

const StyledTab = styled(Tab)`
  background-color: #191b2e !important;
  height: 60px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 22.5px;
  background: #11121b;
  border-radius: 16px;
  width: fit-content;
  display: inline-flex;
  padding: 8px;

  @media (max-width: 768px) {
    width: 100%;

    display: flex !important;
    flex-wrap: wrap;

    height: fit-content;

    .Tab-option {
      width: 50%;

      padding: 8px !important;
    }
  }

  > div {
    height: 45px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;

    img {
      filter: none !important;
      width: auto;
      height: 24px;
    }

    &:nth-child(2) {
      img {
        width: 36px;
      }
    }
  }
`;

const MMY_WFTM_Address = "0xdc935ffe9f9c972b7b304e0b7e61eb774037e394";

export default function Vault(props) {
  const { chainId } = useChainId();

  const [activeTab, setActiveTab] = useState(chainId === FANTOM ? MMY_VAULT : MMY_WETH_VAULT);
  const TAB_OPTION_LABELS = {
    [MMY_VAULT]: `MMY`,
    [MLP_VAULT]: `MLP`,
    [MMY_WFTM_OLD_VAULT]: `MMY-WFTM V1`,
    [MMY_WFTM_VAULT]: `MMY-WFTM V2`,
    [MMY_WETH_VAULT]: `MMY-WETH`,
  };

  const { connectWallet, setPendingTxns } = props;

  const { active, library, account } = useWeb3React();

  const [value, setValue] = useState("");
  const [statsMMYWFTM, setStatsMMYWFTM] = useState({
    price: 0,
    apr: 12.9,
  });

  const fetchStatsMMYWFTM = async () => {
    const res = await axios.get(`https://api.mummy.finance/api/tokens/info?symbols=MMY/FTM&chain=FANTOM`, {});

    if (res && res.data) {
      const { price } = res.data["MMY/FTM"];

      setStatsMMYWFTM({
        ...statsMMYWFTM,
        price,
      });
    }
  };

  useEffect(() => {
    fetchStatsMMYWFTM();

    const interval = setInterval(async () => {
      fetchStatsMMYWFTM();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const TAB_OPTIONS =
    chainId === FANTOM ? [MMY_VAULT, MLP_VAULT, MMY_WFTM_VAULT, MMY_WFTM_OLD_VAULT] : [MMY_WETH_VAULT];

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const vaultMMYAddress = getContract(chainId, "VaultMMY");
  const vaultMLPAddress = getContract(chainId, "VaultMLP");
  const vaultMMY_WFTM_OLDAddress = getContract(chainId, "VaultMMY_WFTM_OLD");
  const vaultMMY_WFTMAddress = getContract(chainId, "VaultMMY_WFTM");

  const currentVaultAddress =
    activeTab === MMY_VAULT
      ? vaultMMYAddress
      : activeTab === MLP_VAULT
      ? vaultMLPAddress
      : activeTab === MMY_WFTM_VAULT
      ? vaultMMY_WFTMAddress
      : vaultMMY_WFTM_OLDAddress; // 3 vault

  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const gmxAddress = getContract(chainId, "GMX");
  const esGmxAddress = getContract(chainId, "ES_GMX");
  const bnGmxAddress = getContract(chainId, "BN_GMX");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");

  const stakedGmxTrackerAddress = getContract(chainId, "StakedGmxTracker");
  const bonusGmxTrackerAddress = getContract(chainId, "BonusGmxTracker");
  const feeGmxTrackerAddress = getContract(chainId, "FeeGmxTracker");

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");

  const glpManagerAddress = getContract(chainId, "GlpManager");

  const gmxVesterAddress = getContract(chainId, "GmxVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  const vesterAddresses = [gmxVesterAddress, glpVesterAddress];

  const walletTokens = [gmxAddress, esGmxAddress, glpAddress, stakedGmxTrackerAddress, MMY_WFTM_Address];
  const depositTokens = [
    gmxAddress,
    esGmxAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    bnGmxAddress,
    glpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmxTrackerAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesESMMY } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0x8a478CB61A7f6E38AC09487e808B3E048709966E" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesMLP } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0xceaE93Bc099E386353B652b7e254DeFe52555883" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedGmxSupply } = useSWR(
    [`StakeV2:stakedGmxSupply:${active}`, chainId, gmxAddress, "balanceOf", stakedGmxTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, VaultJson),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  //MMY
  const { data: strategyAddressMMY } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultMMYAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });
  const { data: lastHarvestMMY } = useSWR(
    [`StakeV2:lastHarvestMMY:${active}`, chainId, strategyAddressMMY, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyMMY),
    }
  );
  const { data: balanceOfMMY } = useSWR(
    () => [`StakeV2:balanceOf:${active}:${account}`, chainId, vaultMMYAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareMMY } = useSWR(
    () => [`StakeV2:getPricePerFullShareMMY:${active}:${account}`, chainId, vaultMMYAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyMMY } = useSWR([`StakeV2:totalSupply:${active}`, chainId, vaultMMYAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  //MLP
  const { data: strategyAddressMLP } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultMLPAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  const { data: lastHarvestMLP } = useSWR(
    [`StakeV2:lastHarvestMLP:${active}`, chainId, strategyAddressMLP, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyMLP),
    }
  );
  const { data: balanceOfMLP } = useSWR(
    [`StakeV2:balanceOf:${active}:${account}`, chainId, vaultMLPAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareMLP } = useSWR(
    () => [`StakeV2:getPricePerFullShareMLP:${active}:${account}`, chainId, vaultMLPAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyMLP } = useSWR([`StakeV2:totalSupply:${active}`, chainId, vaultMLPAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  // MMY_WFTM

  // const { data: strategyAddressMMY_WFTM } = useSWR(
  //   [`StakeV2:strategy:${active}`, chainId, vaultMMY_WFTMAddress, "strategy"],
  //   {
  //     fetcher: contractFetcher(library, NewVaultJson),
  //   }
  // );

  // const { data: lastHarvestMMY_WFTM } = useSWR(
  //   [`StakeV2:lastHarvestMMY:${active}`, chainId, strategyAddressMMY_WFTM, "lastHarvest"],
  //   {
  //     fetcher: contractFetcher(library, VaultStrategyMMY_WFTM),
  //   }
  // );

  const { data: balanceOfMMY_WFTM } = useSWR(
    () => [`StakeV2:balanceOf:${active}:${account}`, chainId, vaultMMY_WFTMAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  console.log("balanceOfMMY_WFTM", balanceOfMMY_WFTM, statsMMYWFTM);

  const { data: pricePerFullShareMMY_WFTM } = useSWR(
    () => [
      `StakeV2:getPricePerFullShareMMY_WFTM:${active}:${account}`,
      chainId,
      vaultMMY_WFTMAddress,
      "getPricePerFullShare",
    ],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyMMY_WFTM } = useSWR(
    [`StakeV2:totalSupply:${active}`, chainId, vaultMMY_WFTMAddress, "balance"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  // MMY_WFTM_OLD

  // const { data: strategyAddressMMY_WFTM_OLD } = useSWR(
  //   [`StakeV2:strategy:${active}`, chainId, vaultMMY_WFTM_OLDAddress, "strategy"],
  //   {
  //     fetcher: contractFetcher(library, NewVaultJson),
  //   }
  // );

  // const { data: lastHarvestMMY_WFTM_OLD } = useSWR(
  //   [`StakeV2:lastHarvestMMY:${active}`, chainId, strategyAddressMMY_WFTM_OLD, "lastHarvest"],
  //   {
  //     fetcher: contractFetcher(library, VaultStrategyMMY_WFTM),
  //   }
  // );

  const { data: balanceOfMMY_WFTM_OLD } = useSWR(
    () => [`StakeV2:balanceOf:${active}:${account}`, chainId, vaultMMY_WFTM_OLDAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareMMY_WFTM_OLD } = useSWR(
    () => [
      `StakeV2:getPricePerFullShareMMY_WFTM:${active}:${account}`,
      chainId,
      vaultMMY_WFTM_OLDAddress,
      "getPricePerFullShare",
    ],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyMMY_WFTM_OLD } = useSWR(
    [`StakeV2:totalSupply:${active}`, chainId, vaultMMY_WFTM_OLDAddress, "balance"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { gmxPrice } = useGmxPrice(chainId, {}, active);

  let gmxSupply;
  if (totalSupplies && totalSupplies[1]) {
    gmxSupply = totalSupplies[1];
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);

  const depositBalanceData = getDepositBalanceData(depositBalances);
  const depositBalanceDataESMMY = getDepositBalanceData(depositBalancesESMMY);
  const depositBalanceDataMLP = getDepositBalanceData(depositBalancesMLP);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const amountTokenDeposit =
    activeTab === MMY_VAULT
      ? formatAmountFree(balanceOfMMY, 18)
      : activeTab === MLP_VAULT
      ? formatAmountFree(balanceOfMLP, 18)
      : activeTab === MMY_WFTM_VAULT
      ? formatAmountFree(balanceOfMMY_WFTM, 18)
      : formatAmountFree(balanceOfMMY_WFTM_OLD, 18); // 3 vault
  const rateWithdraw =
    activeTab === MMY_VAULT
      ? formatAmountFree(pricePerFullShareMMY, 18)
      : activeTab === MLP_VAULT
      ? formatAmountFree(pricePerFullShareMLP, 18)
      : activeTab === MMY_WFTM_VAULT
      ? formatAmountFree(pricePerFullShareMMY_WFTM, 18)
      : formatAmountFree(pricePerFullShareMMY_WFTM_OLD, 18); // 3 vault
  const amountTVL =
    activeTab === MMY_VAULT
      ? formatAmount(totalSupplyMMY, 18)
      : activeTab === MLP_VAULT
      ? formatAmount(totalSupplyMLP, 18)
      : activeTab === MMY_WFTM_VAULT
      ? formatAmount(totalSupplyMMY_WFTM, 18)
      : formatAmount(totalSupplyMMY_WFTM_OLD, 18); // 3 vault
  const amountLastHarvest =
    activeTab === MMY_VAULT
      ? lastHarvestMMY
        ? moment(Number(lastHarvestMMY?.toString() || "") * 1000).fromNow()
        : "0"
      : activeTab === MLP_VAULT
      ? lastHarvestMLP
        ? moment(Number(lastHarvestMLP?.toString() || "") * 1000).fromNow()
        : "0"
      : // : lastHarvestMMY_WFTM ? moment(Number(lastHarvestMMY_WFTM?.toString() || "") * 1000).fromNow() : "0"; // 3 vault
      lastHarvestMLP
      ? moment(Number(lastHarvestMLP?.toString() || "") * 1000).fromNow()
      : "0"; // 3 vault
  const caculateAPYHourly = (apr, time) => {
    const aprTimeFrame = (apr / 4080) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const caculateAPYDaily = (apr, time) => {
    const aprTimeFrame = (apr / 365) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmxSupply,
    gmxPrice,
    gmxSupply
  );

  let nameToken = "gmxBalance";

  switch (activeTab) {
    case MMY_VAULT:
      nameToken = "gmxBalance";
      break;

    case MLP_VAULT:
      nameToken = "glpBalance";
      break;

    case MMY_WFTM_OLD_VAULT:
      nameToken = "mmy_wftmBalance";
      break;

    case MMY_WFTM_VAULT:
      nameToken = "mmy_wftmBalance";
      break;

    case MMY_WETH_VAULT:
      nameToken = "mmy_wethBalance";
      break;

    default:
      nameToken = "gmxBalance";
      break;
  }

  let balance = formatAmountFree(processedData[nameToken], 18);

  if (processedData && vestingData && processedData?.glpBalance) {
    if (activeTab === MLP_VAULT) {
      balance = formatAmountFree(processedData?.glpBalance.sub(vestingData?.glpVesterPairAmount), 18); // watch later
    } else {
    }
  }

  const priceToken =
    activeTab === MMY_VAULT
      ? Number(formatAmount(gmxPrice, USD_DECIMALS))
      : activeTab === MLP_VAULT
      ? Number(formatKeyAmount(processedData, "glpPrice", USD_DECIMALS, 3, true))
      : statsMMYWFTM.price;

  const esGmxInStakedESMMY =
    depositBalanceDataESMMY &&
    gmxPrice &&
    depositBalanceDataESMMY["esGmxInStakedGmx"].mul(gmxPrice).div(expandDecimals(1, GLP_DECIMALS));

  const esGmxInStakedMLP =
    depositBalanceDataMLP &&
    gmxPrice &&
    depositBalanceDataMLP["esGmxInStakedGmx"].mul(gmxPrice).div(expandDecimals(1, GLP_DECIMALS));

  const aprFarm = Number(
    formatKeyAmount(
      processedData,
      activeTab === MMY_VAULT ? "gmxAprForNativeToken" : "glpAprForNativeToken",
      2,
      2,
      true
    )
  );

  const aprFarmEsMMY = Number(formatKeyAmount(processedData, "gmxAprForNativeToken", 2, 2, true));

  const totalStakedWithOutEsMMY = Number(amountTVL * priceToken);
  const totalEsMMY = Number(formatAmount(activeTab === MMY_VAULT ? esGmxInStakedESMMY : esGmxInStakedMLP, 30, 2));

  const aprVault = aprFarm + aprFarmEsMMY * (totalEsMMY / totalStakedWithOutEsMMY);

  const apr = [MMY_WFTM_OLD_VAULT, MMY_WFTM_VAULT].includes(activeTab) ? statsMMYWFTM.apr : aprVault;

  const apy = !isNaN(formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2)
    : "--";

  const totalApr = [MMY_WFTM_OLD_VAULT, MMY_WFTM_VAULT].includes(activeTab)
    ? statsMMYWFTM.apr
    : formatKeyAmount(processedData, activeTab === MMY_VAULT ? "gmxAprTotal" : "glpAprTotal", 2, 2, true); // 3 vault

  const totalApy = !isNaN(formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2)
    : "--";

  const [daily, setDaily] = useState("0.0000");
  const [dailyFTM, setDailyFTM] = useState("0.0000");
  const [dailyEsMMY, setDailyEsMMY] = useState("0.0000");
  useEffect(() => {
    if (!isNaN(totalApy)) {
      setDaily(formatNumber(caculateAPYDaily(Number(totalApr), 1), 4));
      setDailyFTM(formatNumber(caculateAPYDaily(Number(apr), 1), 4));
      setDailyEsMMY(formatNumber(caculateAPYDaily(Number(totalApr), 1) - caculateAPYDaily(Number(apr), 1), 4));
    }
  }, [totalApy, totalApr]);

  function renderHeader() {
    return (
      <StyledVaultInfo>
        <StyledVaultInfoSection>
          <div className="TVL">
            <div>TVL</div>
            {[MMY_WFTM_OLD_VAULT, MMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">${priceToken === 0 ? "--" : formatNumber(amountTVL * priceToken, 2)}</span>
            ) : amountTVL !== "--" && priceToken ? (
              <StyledTooltipComponent
                handle={`$${
                  amountTVL !== "--" && priceToken ? (
                    formatNumber(
                      amountTVL * priceToken +
                        Number(formatAmount(activeTab === MMY_VAULT ? esGmxInStakedESMMY : esGmxInStakedMLP, 30)),
                      2
                    )
                  ) : (
                    <div className="skeleton-box" />
                  )
                }`}
                position="left-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Staked ${activeTab === MMY_VAULT ? "MMY" : "MLP"}`}
                        value={amountTVL !== "--" && priceToken ? formatNumber(amountTVL * priceToken, 2) : "--"}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={`Staked esMMY`}
                        value={formatNumber(
                          Number(formatAmount(activeTab === MMY_VAULT ? esGmxInStakedESMMY : esGmxInStakedMLP, 30, 2)),
                          2
                        )}
                        showDollar={true}
                      />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="APY">
            <div>APY</div>
            {[MMY_WFTM_OLD_VAULT, MMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">{activeTab === MMY_WFTM_OLD_VAULT ? 0 : totalApy}%</span>
            ) : totalApy !== "--" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {totalApy}%
                  </span>
                }
                position="left-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Escrowed MMY APY`}
                        value={`${formatNumber(totalApy - apy, 2)}%`}
                        showDollar={false}
                      />
                      <StatsTooltipRow label={`FTM APY`} value={`${formatNumber(apy, 2)}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="Daily">
            <div>Daily</div>
            {[MMY_WFTM_OLD_VAULT, MMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="Daily-value">{activeTab === MMY_WFTM_OLD_VAULT ? 0 : daily}%</span>
            ) : daily !== "0.0000" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {daily}%
                  </span>
                }
                position="left-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow label={`Daily Escrowed MMY`} value={`${dailyEsMMY}%`} showDollar={false} />
                      <StatsTooltipRow label={`Daily FTM`} value={`${dailyFTM}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
        </StyledVaultInfoSection>
      </StyledVaultInfo>
    );
  }

  const isIpad = useMedia("(max-width: 768px)");

  return (
    <Wrapper className="Vault default-container page-layout">
      <div
        style={{
          maxWidth: "100%",
        }}
        className="section-title-block"
      >
        <div className="section-title-icon"></div>
        <div className="section-title-content">
          <div
            style={{
              marginBottom: 8,
            }}
            className="Page-title"
          >
            <div>
              Vault <img alt="" src={chainId === FANTOM ? ftmIcon : opIcon} />
            </div>
          </div>

          <div
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(255, 255, 255, 0.6)",
            }}
            className="description"
          >
            Our cutting-edge auto-compound Yield Farming strategy, designed to empower investors to earn rewards
            effortlessly.
          </div>
        </div>
      </div>
      <StyledTab
        icons={TAB_ICONS}
        options={TAB_OPTIONS}
        optionLabels={TAB_OPTION_LABELS}
        option={activeTab}
        setOption={setActiveTab}
        onChange={setActiveTab}
        isIconFirst={true}
      />

      {activeTab === MMY_WFTM_OLD_VAULT && (
        <div className="old_vault">
          We have deployed a new contract to V2. Please move ALL of your LP Tokens to the new MMY-WFTM vault.
        </div>
      )}

      {isIpad ? (
        <StyledWrapperSectionIpad>
          <div className="left">
            {renderHeader()}

            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "100px", height: "25px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>
          </div>

          <div className="right">
            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />

            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>
        </StyledWrapperSectionIpad>
      ) : (
        <StyledWrapperSection>
          <div className="left">
            {renderHeader()}
            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>

          <div className="right">
            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>

            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />
          </div>
        </StyledWrapperSection>
      )}
      <Footer />
    </Wrapper>
  );
}

const StyledTooltipComponent = styled(TooltipComponent)`
  .Tooltip-row-value {
    margin-top: 0 !important;
  }
  .Tooltip-row > span {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .Tooltip {
    margin-top: 0 !important;
    margin-bottom: 18px;
  }
  display: inline-block !important;

  .Tooltip-handle {
    display: inline-block;
    margin-top: 0;
  }

  .Tooltip-popup {
    top: 150%;
    left: 100%;
    transform: translateX(-50%);
  }
`;

const Wrapper = styled.div`
  max-width: unset;
  width: fit-content;

  padding: 64px 0 286px 0;

  .Page-title {
    div {
      display: flex;
      align-items: center;

      gap: 6px;

      font-size: 24px;

      img {
        width: 16px;
      }
    }
  }

  @media (max-width: 1023px) {
    padding-bottom: 0 !important;
    min-height: unset !important;
  }

  @media (max-width: 600px) {
    padding: 0 16px 0 16px;
  }

  .old_vault {
    margin-bottom: 24px;

    padding: 12px 24px;
    gap: 10px;

    background: #f6475d;
    border-radius: 12px;
  }
`;
