import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";

import MummyClubStaking from "abis/MummyClubStaking.json";
import NFTClub from "abis/NFTClub.json";

import Multicall from "abis/Multicall.json";
import { getContract } from "config/contracts";
import multicall from "domain/multicall";
import { useChainId } from "lib/chains";

const useGetListStakedNFT = () => {
  const { chainId } = useChainId();
  const { account, library } = useWeb3React();

  const [listMyNFT, setListMyNFT] = useState([]);
  const multicallAddress = getContract(chainId, "Multicall");
  const nftClubAddress = getContract(chainId, "NFTClub");

  const mummyClubStakingAddress = getContract(chainId, "MummyClubStaking");

  const fetchListNFT = useCallback(async () => {
    const nftClubContract = new ethers.Contract(nftClubAddress, NFTClub.abi, library?.getSigner());
    const mummyClubStakingContract = new ethers.Contract(
      mummyClubStakingAddress,
      MummyClubStaking.abi,
      library?.getSigner()
    );
    if (nftClubContract && account && mummyClubStakingContract) {
      const numberStakedNFTData = await mummyClubStakingContract.balanceOf(account);
      await nftClubContract.balanceOf(account).then(async (data) => {
        const numberStakedNFT = Number(numberStakedNFTData || 0);
        const numberMyNFT = Number(data || 0);

        // if(!numberMyNFT) return;
        //Build MyNFT
        const calls = [];
        const callsPower = [];
        for (let index = 0; index < numberMyNFT; index++) {
          calls.push({
            address: nftClubAddress,
            name: "tokenOfOwnerByIndex",
            params: [account, index],
          });
        }
        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, library?.getSigner());

        const dataMultiCall = await multicall(multicallContract, NFTClub.abi, calls);
        const listNFTByTokenId = dataMultiCall.map((item) => item.toString());

        listNFTByTokenId.forEach((element) => {
          callsPower.push({
            address: nftClubAddress,
            name: "getTokenPower",
            params: [element],
          });
        });
        const dataPowerMultiCall = await multicall(multicallContract, NFTClub.abi, callsPower);
        const listPower = dataPowerMultiCall.map((item) => item.toString());
        let dataResponse = [];
        for (let index = 0; index < listNFTByTokenId.length; index++) {
          dataResponse.push({
            nftID: listNFTByTokenId[index],
            power: listPower[index],
            isStaked: false,
          });
        }
        //Build StakedNFT
        const calls2 = [];
        const callsPower2 = [];
        for (let index = 0; index < numberStakedNFT; index++) {
          calls2.push({
            address: mummyClubStakingAddress,
            name: "tokenOfOwnerByIndex",
            params: [account, index],
          });
        }
        const multicallContract2 = new ethers.Contract(multicallAddress, Multicall.abi, library?.getSigner());

        const dataMultiCall2 = await multicall(multicallContract, MummyClubStaking.abi, calls2);
        const listNFTByTokenId2 = dataMultiCall2.map((item) => item.toString());

        listNFTByTokenId2.forEach((element) => {
          callsPower2.push({
            address: nftClubAddress,
            name: "getTokenPower",
            params: [element],
          });
        });
        const dataPowerMultiCall2 = await multicall(multicallContract, NFTClub.abi, callsPower2);
        const listPower2 = dataPowerMultiCall2.map((item) => item.toString());
        let dataResponse2 = [];
        for (let index = 0; index < listNFTByTokenId2.length; index++) {
          dataResponse2.push({
            nftID: listNFTByTokenId2[index],
            power: listPower2[index],
            isStaked: true,
          });
        }

        if (dataResponse.length > 0 || dataResponse2.length > 0) {
          const list = [...dataResponse, ...dataResponse2];
          setListMyNFT(
            list
              .map((item) => [item.nftID, item.power, item.isStaked])
              .sort(function (a, b) {
                var keyA = Number(a[0]),
                  keyB = Number(b[0]);
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
              })
          );
        }
      });
    }
  }, [account]);

  useEffect(() => {
    setListMyNFT([]);

    const clear = setInterval(() => {
      fetchListNFT().catch((err) => console.error(err.stack));
    }, 7000);

    return () => clearInterval(clear);
  }, [account]);

  useEffect(() => {
    setListMyNFT([]);

    fetchListNFT().catch((err) => console.error(err.stack));
  }, [account]);

  return { listMyNFT };
};

export default useGetListStakedNFT;
