import axios from "axios";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import OrderItem from "../OrderItem";
import Pagination from "../Pagination";
import NoItemFound from "../NoItemFound";
import Loader from "components/Common/Loader";
import { getApiAnalytics } from "pages/Analytics/config";

export default function OrderTab({ account, chainId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [row, setRows] = useState(100);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios(
        `${getApiAnalytics(
          chainId
        )}leaderboard/trader-open-order?page=${currentPage}&pageSize=${perPage}&address=${account}`
      );

      setData(response.data.rows);
      setRows(response.data.count);
      setData(response.data.rows);
      setIsLoading(false);
    };

    fetchData();
  }, [account, currentPage, perPage, chainId]);

  return (
    <>
      <div className={isSmallScreen ? "" : "table"}>
        <div className="header" style={{ display: isSmallScreen ? "none" : "flex" }}>
          <div className="market child" style={{ width: "10%", flex: "unset" }}>
            Order Type
          </div>
          <div className="size child" style={{ width: "10%", flex: "unset" }}>
            Type
          </div>
          <div className="order child" style={{ width: "40%", flex: "unset" }}>
            Order
          </div>
          <div className="collateral child">Trigger Condition</div>
          <div className="child">Mark Price</div>
        </div>
        {!isLoading || data.length > 0 ? (
          data.map((item: any) => (
            <OrderItem key={item.id} chainId={chainId} data={item} isSmallScreen={isSmallScreen} />
          ))
        ) : (
          // <Loader />
          <div className="skeleton-box" style={{height: '30px', marginTop: '20px'}}/>
        )}
      </div>
      {data.length > 0 ? (
        <Pagination
          rowsPerPage={perPage}
          setRowsPerPage={setPerPage}
          row={row}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <NoItemFound />
      )}
    </>
  );
}
