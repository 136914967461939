import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { getApi } from "config/chains";
import { useChainId } from "lib/chains";
import { useEffect, useState } from "react";

const useYourNFTInfo = () => {
  const { account } = useWeb3React();

  const [info, setInfo] = useState([]);
  const { chainId } = useChainId();
  const fetchPrice = async () => {
    const res = await axios.get(`${getApi(chainId)}/nft?account=${account}`, {});

    if (res && res.data) {
      const prices = res.data || [];
      setInfo(prices);
    }
  };
  useEffect(() => {
    fetchPrice();
    const interval = setInterval(async () => {
      fetchPrice();
    }, 30000);
    return () => clearInterval(interval);
  }, [account]);
  return info;
};
export default useYourNFTInfo;
