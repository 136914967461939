import styled from "styled-components";

import backIc from "img/ic_arrowleft16.svg";
import { useHistory } from "react-router-dom";

const StyledBackUrl = styled.span`
  text-decoration: none;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: #34f5ae;
  transition: 500ms;
  cursor: pointer;

  img {
    filter: invert(66%) sepia(41%) saturate(684%) hue-rotate(105deg) brightness(114%) contrast(92%);
  }

  &:hover {
    opacity: 0.7;
  }
`;

const BackUrl = ({ defaultUrl = "" }) => {
  let history = useHistory();
  return (
    <StyledBackUrl
      onClick={() => {
        console.log(history, defaultUrl);
        history.length >= 3 ? history.goBack() : history.push(defaultUrl);
      }}
    >
      <img src={backIc} alt="" />
      Back
    </StyledBackUrl>
  );
};

export default BackUrl;
