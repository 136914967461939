import React from "react";

import cx from "classnames";

import "./Tab.css";
const LONG = "Long";
const SHORT = "Short";
export default function Tab(props) {
  const { options, option, setOption, onChange, type = "block", className, optionLabels, icons, isIconFirst, isUpperCase, isBoldFontWeight } = props;
  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <div className={cx("Tab", type, className)}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
        return (
          <div className={cx("Tab-option", "", { active: opt === option, long: opt === LONG, short: opt === SHORT, tabFontWetght: isBoldFontWeight === true })} onClick={() => onClick(opt)} key={opt}>
            {isIconFirst && icons && icons[opt] && <img className="Tab-option-icon tab-option-icon-first" src={icons[opt]} alt={option} />}
            {isUpperCase ? label?.toUpperCase() : label}
            {!isIconFirst && icons && icons[opt] && <img className="Tab-option-icon tab-option-icon-last" src={icons[opt]} alt={option} />}

          </div>
        );
      })}
    </div>
  );
}
