import React, { memo, useEffect } from "react";
import styled from "styled-components";

import arrowRightIc from "img/ic_arrowright16.svg";
import { getDecimalTokenByAddress, getNameTokenByAddress } from "utils/tokenHelper";
import { formatAmount, getDisplayDecimalByAmount } from "lib/numbers";
import {
  DECREASE,
  getExchangeRate,
  getExchangeRateDisplay,
  getExchangeRateNormalizeDisplay,
  INCREASE,
} from "lib/legacy";
import { getTokenInfo } from "domain/tokens";
import { getContract } from "config/contracts";
import { CHAIN_ID } from "config/chains";
import { getNormalizedTokenSymbol, getToken, getTokenLowerCase, TOKENS } from "config/tokens";

const StyledOrderItem = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 57px;
  padding: 0px 11px;
  padding: 14px;
  color: white;
  /* border-bottom: 1px dashed hsla(0, 0%, 100%, 0.062745098);
  &:last-child{
    border-bottom: none !important;
  } */
  background: #212333;
  border-radius: 12px;
  margin-top: 8px;
  /* @media (max-width: 1110px) {
    &:first-child
    margin-top: 0 !important;
  } */
  &:first-child {
    margin-top: 0 !important;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    height: unset;
    background: #191B2E;
    border-radius: 16px;

    &.headless {
      padding: 0;
      background-color: transparent !important;
      border: none !important;

      .child:first-child {
        display: none;
      }
    }

    & + div {
      margin-top: 12px;
    }
  }

  &.open-position {
    height: 62.5px;

    .view-details {
      text-align: right;
      a {
        text-decoration: none;
        font-weight: 500;
        color: #04d397;
      }
    }
  }

  .child {
    flex: 1;

    &:first-child {
      .icon {
        display: none;
      }
    }

    @media (max-width: 1023px) {
      width: 100%;
      &:first-child {
        padding-bottom: 13px;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.063);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          display: block;
        }
      }
      &:not(:first-child) {
        text-align: right;
        display: flex;
        margin-top: 12px;

        &::before {
          content: attr(data-name);
          margin-right: auto;
          color: hsla(0, 0%, 100%, 0.7);
        }
      }
    }

    &.time {
      flex: 2;

      color: rgb(132, 142, 156);
    }

    &.market {
      .wrapper {
        .coin {
          font-weight: 500;

          color: rgb(255, 227, 169);
        }

        .position {
          font-weight: 600;
          font-size: 12px;

          color: rgb(7, 186, 117);
        }
      }
    }

    &.size {
    }

    &.net-value {
      position: relative;
      top: -2px;
      .Tooltip {
        position: relative;
        top: 2px;
      }
      .wrapper {
        .position {
          margin-top: 8px;

          color: rgb(246, 71, 93);
          font-size: 12px;
        }
      }
    }

    &.collateral {
    }

    &.pnl {
      color: #34f5ae;
    }

    &.liquidation {
      color: rgb(255, 179, 19);
    }

    &.view-details {
      text-align: right;
      a {
        text-decoration: none;
        font-weight: 500;
        color: #04d397;
      }
    }
  }
  .action-child {
    color: #03F5AE;
  }
`;

const formatNumber2 = (num, decimals = 3) => {
  return Number(Number(num).toFixed(3)).toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

const OrderItemTradeTab = (props) => {
  const {
    setVisibleModal,
    isHeadless,
    isSmallScreen,
    infoTokens,
    onEditClick,
    onCancelClick,
    chainId,
    data: {
      indexToken,
      isLong,
      type,
      triggerPrice,
      sizeDelta,
      amountIn,
      inputToken,
      outputToken,
      marketPrice,
      minOut,
      triggerRatio,
      triggerAboveThreshold,
      inputTokenMarketPrice,
      outputTokenMarketPrice,
      path,
      shouldUnwrap,
    },
  } = props;
  const triggerPricePrefix = triggerAboveThreshold ? "≥" : "≤";
  const nativeTokenAddress = getContract(CHAIN_ID, "NATIVE_TOKEN");
  const fromTokenInfo = getTokenInfo(infoTokens, path?.[0], true, nativeTokenAddress);
  const toTokenInfo = getTokenInfo(infoTokens, path?.[path?.length - 1], shouldUnwrap, nativeTokenAddress);
  const indexTokenMi = getTokenInfo(infoTokens, indexToken, true, nativeTokenAddress);
  const maximisePrice = (type === INCREASE && isLong) || (type === DECREASE && !isLong);
  const markPriceMaximise = maximisePrice ? indexTokenMi?.maxPrice : indexTokenMi?.minPrice;
  const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);

  return (
    <StyledOrderItem className={`${isHeadless ? "headless" : ""}`}>
      <div className={`market child`} style={!isSmallScreen ? { width: "10%", flex: "unset" } : {}}>
        <div className="wrapper">
          {type !== "Swap" ? (
            <>
              <div className="coin">{getNormalizedTokenSymbol(getTokenLowerCase(chainId, indexToken).symbol)}/USD</div>
              <div className="position" style={{ color: isLong ? "#03F5AE" : "#F6475D" }}>
                {isLong ? "LONG" : "SHORT"}
              </div>
            </>
          ) : (
            <>
              <div className="coin">
                {getNormalizedTokenSymbol(getTokenLowerCase(chainId, path[0]).symbol)}/
                {getNormalizedTokenSymbol(getTokenLowerCase(chainId, path[1]).symbol)}
              </div>
              <div className="position" style={{ color: "#fff" }}>
                SWAP
              </div>
            </>
          )}
        </div>
      </div>
      <div className="type child" data-name="Type" style={!isSmallScreen ? { width: "10%", flex: "unset" } : {}}>
        {["Swap", "Increase"].includes(type) ? "LIMIT" : "TRIGGER"}
      </div>
      <div className="size child" data-name="Order" style={!isSmallScreen ? { width: "30%", flex: "unset" } : {}}>
        <span style={isSmallScreen ? { width: "30%" } : {}}>
          {type === "Decrease" || type === "Increase"
            ? `${type === "Decrease" ? "Decrease" : "Increase"} ${getNormalizedTokenSymbol(
                getTokenLowerCase(chainId, indexToken).symbol
              )} ${isLong ? "LONG" : "SHORT"} by $${formatAmount(sizeDelta, 30, 2, true)}`
            : type === "Swap"
            ? `Swap ${formatAmount(amountIn, getTokenLowerCase(chainId, path[0]).decimals, 3, true)}
          ${getTokenLowerCase(chainId, path[0]).symbol} for at least ${formatAmount(
                minOut,
                getTokenLowerCase(chainId, path[1]).decimals,
                3,
                true
              )} ${getNormalizedTokenSymbol(getTokenLowerCase(chainId, path[1]).symbol)}`
            : ""}
        </span>
      </div>
      <div
        className="collateral child"
        data-name="Trigger Condition"
        style={!isSmallScreen ? { width: "20%", flex: "unset" } : {}}
      >
        {type === "Swap"
          ? !["BTC", "ETH"].includes(getTokenLowerCase(chainId, path[0]).symbol) &&
            !["BTC", "ETH"].includes(getTokenLowerCase(chainId, path[1]).symbol)
            ? `${formatNumber2(1 / Number(formatAmount(triggerRatio, 30, 10)), 2)} ${getNormalizedTokenSymbol(
                getTokenLowerCase(chainId, path[1]).symbol
              )} / ${getNormalizedTokenSymbol(getTokenLowerCase(chainId, path[0]).symbol)}`
            : ["BTC", "ETH"].includes(getTokenLowerCase(chainId, path[0]).symbol)
            ? `${formatNumber2(1 / (triggerRatio / 1e30), 2)} ${getTokenLowerCase(chainId, path[1]).symbol} / ${
                getTokenLowerCase(chainId, path[0]).symbol
              }`
            : `${formatNumber2(Number(formatAmount(triggerRatio, 30, 10)), 2)} ${
                getTokenLowerCase(chainId, path[0]).symbol
              } / ${getTokenLowerCase(chainId, path[1]).symbol}`
          : `Mark price ${triggerPricePrefix} $${formatAmount(triggerPrice, 30, getDisplayDecimalByAmount(triggerPrice, 30), true)}`}
      </div>
      <div className="child" data-name="Mark Price">
        {type === "Swap"
          ? getExchangeRateNormalizeDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, true)
          : `$${formatAmount(markPriceMaximise, 30, getDisplayDecimalByAmount(markPriceMaximise, 30), true)}`}
      </div>
      <div className="child action" data-name="Action">
        <div className="action-child" onClick={() => onEditClick(props.data)}>
          Edit
        </div>
        <div className="action-child" style={{ marginLeft: "15px" }} onClick={() => onCancelClick(props.data)}>
          Cancel
        </div>
      </div>
    </StyledOrderItem>
  );
};

export default memo(OrderItemTradeTab);
