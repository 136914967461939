import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import { Table, TDCell, THead, TRow } from "../Account.styled";
import share from "img/share.svg";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useParams } from "react-router-dom";

import "../../styles/datePicker.scss";
import Button from "components/Common/Button";
import MobileTradeHistoryItem from "../MobileTradeHistoryItem";
import LongShortTag, { StyledLongShortTag } from "../LongShortTag";
import { useMedia } from "react-use";
import { formatAmount } from "lib/numbers";
import NoItemFound from "../NoItemFound";
import Loader from "components/Common/Loader";
import DateFilter from "components/DateFilter/DateFilter";
import { getApiAnalytics } from "pages/Analytics/config";
import { getTokenDecimal, getTokenSymbol } from "config/tokens";
import { getExplorerUrl } from "config/chains";

const LoadmoreBtn = styled(Button)`
  padding: 5px 10px;
  font-weight: 500;
  display: block;
  margin: 25px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    font-size: 14px;
    margin: 0;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  &:hover {
    img {
      filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
    }
  }
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background: #1c1c1e; */
  padding: 12px;
  /* border-bottom: 1px solid #29292c; */
  background: #191b2e;
  border-radius: 16px;
  align-items: center;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    .time {
      margin-top: 12px;
      font-size: 13px;
      flex-wrap: wrap;
      gap: 10px;
      > *:not(.react-datepicker__tab-loop) {
        margin-right: 0px !important;
      }
      .react-datepicker-wrapper {
        &:nth-child(4) {
          margin-right: 25px !important;
        }
      }
      input {
        font-size: 13px;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }

  .duration {
    display: flex;
    gap: 20px;

    > *:hover {
      color: rgb(4, 211, 151);
    }
  }

  .time {
    display: flex;
    align-items: center;
    /* gap: 10px; */

    > *:not(.react-datepicker__tab-loop) {
      margin-right: 10px;
    }

    span {
      cursor: pointer;
    }

    img {
      width: 16px;
      height: 16px;
    }

    .title {
      margin-right: 8px;
    }

    .reload {
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(110%) contrast(110%);
      }
      &:hover {
        img {
          filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
        }
      }
    }
  }
`;

const Duration = styled.span`
  cursor: pointer;
  font-size: 13px;

  &:hover {
    color: rgb(255, 179, 19);
  }
`;

const StyledTable = styled(Table)`
  width: 100%;
  /* @media screen {
    display: none;
  } */
`;

const StyledMobileHistoryContainer = styled.div`
  display: none;

  /* background: #ffffff10; */
  padding: 0;

  @media (max-width: 1023px) {
    display: block;
  }

  .table-mobile {
    padding: 0;
    margin-bottom: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    background: #212333 !important;
    border-radius: 16px !important;
  }
`;

// const DURATIONS = ["1 Day", "1 Week", "1 Month", "3 Months"];
const DURATIONS = [
  {
    title: "1 Day",
    milisecons: 86400000,
  },
  { title: "1 Week", milisecons: 604800000 },
  { title: "1 Month", milisecons: 2629800000 },
  { title: "3 Months", milisecons: 7889400000 },
];

const TradeHistoryAcc = ({ chainId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showNumber, setShowNumber] = useState(10);
  const { account } = useParams();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const isMobile = useMedia("(max-width: 500px)");
  const [duration, setDuration] = useState();

  const fetchData = async (account) => {
    const { data } = await axios(`${getApiAnalytics(chainId)}api/trades?address=${account.toLowerCase()}`);

    setData(data);
    setFilteredData(data);
    setIsLoading(false);
  };

  const handleRefresh = () => {
    setIsLoading(true);
    setStartDate();
    setEndDate();
    fetchData(account);
  };

  const handleSearch = () => {
    setIsLoading(true);
    if (endDate < startDate) {
      setFilteredData([]);
      setIsLoading(false);
      return;
    }
    setFilteredData(() =>
      data.filter((item) => item.timestamp >= startDate / 1000 && item.timestamp <= endDate / 1000)
    );
    setDuration(null);
    setIsLoading(false);
  };

  const handleFilterByDuration = (milisecons) => {
    const currentDate = +new Date();
    setFilteredData([]);
    setIsLoading(true);
    setTimeout(() => {
      setFilteredData(() =>
        data.filter((item) => item.timestamp * 1000 >= currentDate - milisecons && item.timestamp * 1000 <= currentDate)
      );
      setIsLoading(false);
    }, 200);
    setDuration(milisecons);
  };

  const handleStartDate = (date) => {
    // const unixTimestamp = Date.parse(date);
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData(account);
  }, [account]);

  const renderData = (data) => {
    return data.map((item, index) => {
      // const indexToken = getNameTokenByAddress(item.indexToken);
      const indexToken = getTokenSymbol(chainId, item.indexToken);
      const isLongOrShort = item.isLong ? "LONG" : "SHORT";
      // const isPlusOrMinus = item.action === "DecreasePosition" ? "-" : "+";
      const CollateralDelta = formatAmount(item.collateralDelta, 30, 3, true);
      return (
        <>
          {index < showNumber && (
            <TRow key={item.address} className="row-data-history">
              <TDCell style={{ width: "20%", color: "#848E9C" }}>
                {moment(item.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}
              </TDCell>
              <TDCell
                style={{
                  width: "20%",
                  color: "rgb(255, 227, 169)",
                  fontWeight: "500",
                }}
              >
                {item.action === "Swap" ? (
                  <>
                    <StyledLongShortTag>
                      <span style={{ color: "#fff" }}>SWAP</span>
                    </StyledLongShortTag>
                    <span style={{ marginLeft: "8px" }}>
                      {getTokenSymbol(chainId, item.tokenIn)}/{getTokenSymbol(chainId, item.tokenOut)}
                    </span>
                  </>
                ) : (
                  <>
                    <LongShortTag
                      isLong={item.isLong}
                      style={{ "margin-right": "8px", fontWeight: "500", background: "#ffffff10" }}
                    />
                    {indexToken}/USD
                  </>
                )}
              </TDCell>
              <TDCell>
                {/* Sizedelta == 0 && type == IncreasePosition -> deposit */}
                {/* Sizedelta == 0 && collateralValue != 0 && type == DecreasePosition -> withdraw */}
                {item.action === "DecreasePosition" || item.action === "IncreasePosition"
                  ? item.sizeDelta == 0
                    ? item.action == "IncreasePosition"
                      ? `Deposited $${CollateralDelta} to ${indexToken} ${isLongOrShort}`
                      : item.collateralValue !== 0
                      ? `Withdrew  $${CollateralDelta} from ${indexToken} ${isLongOrShort}`
                      : ""
                    : `${indexToken} ${isLongOrShort} ${String(item.action)
                        .replace("Position", "d")
                        .toLowerCase()} by $${formatAmount(item.sizeDelta, 30, 3, true)} (price = $${formatAmount(
                        item.price,
                        30,
                        3,
                        true
                      )})`
                  : item.action === "Swap"
                  ? `Swapped ${formatAmount(
                      item.amountIn,
                      getTokenDecimal(chainId, item.tokenIn),
                      3,
                      true
                    )} ${getTokenSymbol(chainId, item.tokenIn)} for ${formatAmount(
                      item.amountOutAfterFees,
                      getTokenDecimal(chainId, item.tokenOut),
                      3,
                      true
                    )} ${getTokenSymbol(chainId, item.tokenOut)}`
                  : `${getTokenSymbol(chainId, item.indexToken)} ${isLongOrShort} of ${formatAmount(
                      item.size,
                      30,
                      3,
                      true
                    )} has been liquidated (price = $${formatAmount(item.markPrice, 30, 3, true)})`}
              </TDCell>
              <TDCell style={{ width: "45px" }}>
                <StyledExternalLink href={`${getExplorerUrl(chainId)}tx/${item.txhash}`}>
                  <img src={share} alt="share" />
                </StyledExternalLink>
              </TDCell>
            </TRow>
          )}
        </>
      );
    });
  };

  return (
    <>
      <FilterSection>
        <div className="duration">
          {DURATIONS.map(({ title, milisecons }) => (
            <Duration
              key={title}
              style={{ color: duration === milisecons ? "#34f5ae" : "#fff" }}
              onClick={() => handleFilterByDuration(milisecons)}
            >
              {title}
            </Duration>
          ))}
        </div>
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          handleEndDate={handleEndDate}
          handleStartDate={handleStartDate}
          handleSearch={handleSearch}
          handleRefresh={handleRefresh}
        />
      </FilterSection>
      {!isMobile && (
        <>
          {!isLoading ? (
            filteredData.length > 0 ? (
              <StyledTable className="account-trade-history">
                <TRow>
                  <THead style={{ fontWeight: 500 }}>Time</THead>
                  <THead style={{ fontWeight: 500 }}>Market</THead>
                  <THead style={{ fontWeight: 500 }}>Action Value</THead>
                  <THead></THead>
                </TRow>
                {renderData(filteredData)}
              </StyledTable>
            ) : (
              <NoItemFound />
            )
          ) : (
            // <Loader />
            <div className="skeleton-box" style={{height: '30px', marginTop: '20px'}}/>
          )}
        </>
      )}
      {isMobile && (
        <StyledMobileHistoryContainer>
          {filteredData.length > 0 ? (
            filteredData.map((item) => <MobileTradeHistoryItem data={item} />)
          ) : (
            <NoItemFound />
          )}
        </StyledMobileHistoryContainer>
      )}
      {showNumber < filteredData.length && (
        <LoadmoreBtn onClick={() => setShowNumber((prev) => prev + 10)}>Load more</LoadmoreBtn>
      )}
    </>
  );
};

export default TradeHistoryAcc;
