import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, OP } from "./chains";

export const GMX_STATS_API_URL = "https://api.mummy.finance/api";

const BACKEND_URLS = {
  default: "https://api.mummy.finance/api",
  [OP]: "https://api.mummy.finance/optimism/api",
  [MAINNET]: "https://gambit-server-staging.uc.r.appspot.com",
  [ARBITRUM_TESTNET]: "https://gambit-server-devnet.uc.r.appspot.com",
  [ARBITRUM]: "https://api.mummy.finance/arbitrum/api",
  [AVALANCHE]: "https://gmx-avax-server.uc.r.appspot.com",
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }
  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
