import { t, Trans } from "@lingui/macro";
import cx from "classnames";
import { useAffiliateCodes } from "domain/referrals";
import { toJpeg } from "html-to-image";
import gmxLogo from "img/gmx-logo-with-name.svg";
import { default as copyIc } from "img/icons/copy-ic.svg";

import tweetIc from "img/icons/tweet-ic.svg";

import downloadIc from "img/icons/download-ic.svg";
import icCheck from "img/icons/checkbox-ic.svg";
import icChecked from "img/icons/checkbox-ic1.svg";
import shareBgImg from "img/position-share-bg.png";
import { useChainId } from "lib/chains";
import downloadImage from "lib/downloadImage";
import { helperToast } from "lib/helperToast";
import { getHomeUrl, getRootShareApiUrl, getTwitterIntentURL, USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import useLoadImage from "lib/useLoadImage";
import { TXT } from "pages/NFT/multichainTxt";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useCopyToClipboard, useMedia } from "react-use";
import SpinningLoader from "../Common/SpinningLoader";
import Modal from "../Modal/Modal";
import "./PositionShare.scss";
const ROOT_SHARE_URL = getRootShareApiUrl();
const UPLOAD_URL = ROOT_SHARE_URL + "/api/upload";
const UPLOAD_SHARE = ROOT_SHARE_URL + "/api/s";
const config = { quality: 0.95, canvasWidth: 518, canvasHeight: 292, type: "image/jpeg" };

function getShareURL(imageInfo, ref) {
  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref.success && ref.code) {
    url = url + `&ref=${ref.code}`;
  }
  return url;
}

function PositionShare({ setIsPositionShareModalOpen, isPositionShareModalOpen, positionToShare, account, chainId }) {
  const userAffiliateCode = useAffiliateCodes(chainId, account);
  const [uploadedImageInfo, setUploadedImageInfo] = useState();
  const [uploadedImageError, setUploadedImageError] = useState();
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImg);
  const positionRef = useRef();
  const tweetLink = getTwitterIntentURL(
    `Latest $${positionToShare?.indexToken?.symbol} trade on @mummyftm`,
    getShareURL(uploadedImageInfo, userAffiliateCode)
  );
  const isMobile = useMedia("(max-width: 800px)");
  useEffect(() => {
    (async function () {
      const element = positionRef.current;
      if (element && userAffiliateCode.success && sharePositionBgImg && positionToShare) {
        // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
        // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
        const image = await toJpeg(element, config)
          .then(() => toJpeg(element, config))
          .then(() => toJpeg(element, config));
        try {
          const imageInfo = await fetch(UPLOAD_URL, { method: "POST", body: image }).then((res) => res.json());
          setUploadedImageInfo(imageInfo);
        } catch {
          setUploadedImageInfo(null);
          setUploadedImageError(`Image generation error, please refresh and try again.`);
        }
      }
    })();
  }, [userAffiliateCode, sharePositionBgImg, positionToShare]);

  async function handleDownload() {
    const element = positionRef.current;
    if (!element) return;
    const imgBlob = await toJpeg(element, config)
      .then(() => toJpeg(element, config))
      .then(() => toJpeg(element, config));
    downloadImage(imgBlob, "share.jpeg");
  }

  function handleCopy() {
    if (!uploadedImageInfo) return;
    const url = getShareURL(uploadedImageInfo, userAffiliateCode);
    copyToClipboard(url);
    helperToast.success(`Link copied to clipboard.`);
  }

  const LIST = [
    {
      name: "Leverage",
      checked: true,
      value: "leverage",
    },
    {
      name: "PNL Amount",
      checked: true,
      value: "pnl",
    },
    {
      name: "Price",
      checked: true,
      value: "price",
    },
  ];
  const [checkList, setCheckList] = useState(LIST);

  const handleCheck = (item) => {
    const newList = [...checkList];
    newList.map((checkbox) => {
      if (checkbox.value === item.value) {
        checkbox.checked = !item.checked;
      }
      return item;
    });

    setCheckList(newList);
  };

  return (
    <Modal
      className="position-share-modal"
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={`Share Position`}
    >
      <PositionShareCard
        userAffiliateCode={userAffiliateCode}
        positionRef={positionRef}
        position={positionToShare}
        chainId={chainId}
        account={account}
        uploadedImageInfo={uploadedImageInfo}
        uploadedImageError={uploadedImageError}
        sharePositionBgImg={sharePositionBgImg}
        checkList={checkList}
      />
      {uploadedImageError && <span className="error">{uploadedImageError}</span>}
      <div className="checkbox-list">
        {checkList.map((item) => {
          return (
            <div className="checkbox-item">
              {item.checked ? (
                <img className="img-check" alt="img" src={icChecked} onClick={() => handleCheck(item)} />
              ) : (
                <img className="img-check" src={icCheck} alt="img" onClick={() => handleCheck(item)} />
              )}
              {item.name}
            </div>
          );
        })}
      </div>

      <div className="actions">
        <button disabled={!uploadedImageInfo} className="mr-base App-button-option" onClick={handleCopy}>
          <img src={copyIc} alt="img" className="icon" />
          <span>Copy</span>
        </button>
        {!isMobile && (
          <button disabled={!uploadedImageInfo} className="mr-base App-button-option" onClick={handleDownload}>
            <img src={downloadIc} alt="img" className="icon" />
            <span>Download</span>
          </button>
        )}

        <div className={cx("tweet-link-container", { disabled: !uploadedImageInfo })}>
          <a
            target="_blank"
            className={cx("tweet-link App-button-option", { disabled: !uploadedImageInfo })}
            rel="noreferrer"
            href={tweetLink}
          >
            <img src={tweetIc} alt="img" className="icon" />
            <span>Tweet</span>
          </a>
        </div>
      </div>
    </Modal>
  );
}

function PositionShareCard({
  positionRef,
  position,
  userAffiliateCode,
  uploadedImageInfo,
  uploadedImageError,
  sharePositionBgImg,
  checkList,
}) {
  const { chainId } = useChainId();
  const isMobile = useMedia("(max-width: 400px)");
  const { code, success } = userAffiliateCode;

  const { deltaPercentageStr = "-", deltaStr, isLong, leverage, indexToken, averagePrice, markPrice } = position;

  const homeURL = getHomeUrl();
  return (
    <div className="relative">
      <div ref={positionRef} className="position-share" style={{ backgroundImage: `url(${sharePositionBgImg})` }}>
        <div className="logo_wrapper">
          <img className="logo" src={gmxLogo} alt="MUMMY Logo" />
          <img className="chain_img" src={TXT[chainId].chainLogoShare} alt="img" />
        </div>
        <ul className="info">
          <li className={`side ${isLong ? "long" : "short"}`}>{isLong ? "Long" : "Short"}</li>
          {checkList[0].checked && <li>{formatAmount(leverage, 4, 2, true)}x&nbsp;</li>}

          <li>{indexToken?.symbol} USD</li>
        </ul>
        {checkList[1].checked && (
          <h3 className={`pnl ${deltaPercentageStr.includes("-") ? "negative" : "positive"}`}>
            {deltaPercentageStr}
            <div className="change-dollar">({deltaStr})</div>
          </h3>
        )}

        {checkList[2].checked && (
          <div className="prices">
            <div>
              <p>Entry price:</p>
              <p className="price">${formatAmount(averagePrice, USD_DECIMALS, 4, true)}</p>
            </div>
            <div>
              <p>Mark price:</p>
              <p className="price">${formatAmount(markPrice, USD_DECIMALS, 4, true)}</p>
            </div>
          </div>
        )}

        <div className="referral-code">
          <div>
            <QRCodeSVG size={isMobile ? 36 : 56} value={success && code ? `${homeURL}/#/?ref=${code}` : `${homeURL}`} />
          </div>
          <div className="referral-code-info">
            {success && code ? (
              <>
                <p className="label">Referral code</p>
                <p className="code">{code}</p>
              </>
            ) : (
              <>
                <p className="label">Trade now on</p>
                <p className="code">mummy.finance</p>
              </>
            )}
          </div>
        </div>
      </div>
      {!uploadedImageInfo && !uploadedImageError && (
        <div className="image-overlay-wrapper">
          <div className="image-overlay">
            <SpinningLoader />
            <p className="loading-text">
              <span>Generating shareable image...</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionShare;
