import React, { useMemo } from "react";
import styled from "styled-components";
import { getServerUrl } from "config/backend";
import { formatAmount, formatNumber } from "lib/numbers";
import moment from "moment";
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import useSWR from "swr";

import TooltipComponent from "components/Tooltip/Tooltip";

const VolumeChart = ({ chainId }) => {
  const { data } = useSWR(getServerUrl(chainId, "/volume-chart"), {
    fetcher: (url) => fetch(url).then((r) => r.json()),
    refreshInterval: 10000,
  });

  const tradingVolumeData = useMemo(() => {
    const handledData = data?.daily?.map(({ volume, timestamp }, index) => {
      return {
        daily: parseFloat(formatAmount(volume, 30)),
        total: parseFloat(formatAmount(data?.total?.[index]?.volume, 30)),
        date: moment(timestamp * 1000).format("MM-DD"),
      };
    });
    return handledData?.reverse();
  }, [data]);

  const formatAxis = (tickItem) => {
    return `$${formatNumber(tickItem, 1)}`;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label" style={{ backgroundColor: "#11121B", padding: "16px", borderRadius: "8px" }}>
          <div
            className="stats-row"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div
              className="stats-label-color"
              style={{ width: "8px", height: "8px", borderRadius: "999px", backgroundColor: payload[0].color }}
            ></div>
            <div>24H Trading Volume: ${formatNumber(payload[0].payload.daily, 1)}</div>
          </div>
          <div
            className="stats-row"
            style={{
              marginTop: "8px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div
              className="stats-label-color"
              style={{ width: "8px", height: "8px", borderRadius: "999px", backgroundColor: payload[1].color }}
            ></div>
            <div>Total Trading Volume: ${formatNumber(payload[0].payload.total, 1)}</div>
          </div>
          <div style={{ marginTop: "8px", color: "#FFFFFFB2" }}>{payload[0].payload.date}</div>
        </div>
      );
    }

    return null;
  };
  return (
    <Wrapper>
      <div className="chart-title">
        <TooltipComponent
          position="left-bottom"
          handle={`Trading Vol.`}
          renderContent={() => (
            <div style={{ fontWeight: 400 }}>
              The 24H trading volume is calculated from 0:00 UTC to 23:59 UTC each day.
            </div>
          )}
        />
      </div>
      <div className="chart-content">
        <ResponsiveContainer width="100%" height={240}>
          {tradingVolumeData ? <ComposedChart width={150} height={40} data={tradingVolumeData} cursor="pointer">
            <Tooltip
              cursor={{ fill: "#FFFFFF1A", cursor: "pointer" }}
              contentStyle={{ borderRadius: "8px", border: "none" }}
              itemStyle={{ color: "#34f5ae" }}
              content={<CustomTooltip />}
            />
            <CartesianGrid strokeDasharray="4" stroke={"#FFFFFF1A"} vertical={false} />
            <XAxis stroke="#FFFFFF66" dataKey="date" height={12} minTickGap={15} fontSize={12} />
            <YAxis
              yAxisId="left"
              axisLine={false}
              tickLine={false}
              stroke="#FFFFFF66"
              width={45}
              tickFormatter={formatAxis}
              fontSize={12}
            />
            <YAxis
              yAxisId="right"
              axisLine={false}
              tickLine={false}
              stroke="#FFFFFF66"
              width={40}
              tickFormatter={formatAxis}
              orientation="right"
              fontSize={12}
            />
            <Bar yAxisId="left" maxBarSize={18} dataKey="daily" width={20} fill="#03F5AE" />
            <Line yAxisId="right" type="monotone" dataKey="total" stroke="#F3FF74" dot={false} strokeWidth={2} />
          </ComposedChart> : <div className="skeleton-box"/>}

        </ResponsiveContainer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 16px;

  .chart-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
  }

  .chart-content {
    min-height: 240px;
  }
`;

export default VolumeChart;
