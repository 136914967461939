import { Select } from "antd";
import styled from "styled-components";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import TraderItem from "./TraderItem";

import upIc from "img/upArrow.svg";
import upIcWhite from "img/upArrowWhite.svg";
import downIc from "img/downArrow.svg";
import inInfo from "img/ic_info.svg";
import "../styles/index.scss";

import { TabWrapper } from "../styles/Analytics.styled";

import Pagination from "./Pagination";
import { EXTERNAL_LINK, getApiAnalytics } from "../config";
import Tab from "components/Tab/Tab";
import { OP } from "config/chains";
import { getUnixDate, getUnixDateHour } from "./TopReferrals";
import moment from "moment";
import Countdown from "react-countdown";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Tooltip from "components/Tooltip/Tooltip";
import Loader from "components/Common/Loader";
import NoItemFound from "./NoItemFound";
import useAccountsNFT from "hooks/useAccountsNFT";
import { useAnalyticsContext } from "../context";

export const ALL_STATS = "All Stats";
export const TOP_PROFIT = "Top Profit";
export const TOP_ROI = "Top ROI";

const TAB_OPTIONS = [ALL_STATS, TOP_PROFIT, TOP_ROI];
const TAB_OPTIONS_LABELS = {
  [ALL_STATS]: `All Stats`,
  [TOP_PROFIT]: `Top Profit`,
  [TOP_ROI]: `Top ROI`,
};

const CONTEST_TIMESTAMP = {
  // [FANTOM]: ["2023-02-19", "2023-03-19"],
  [OP]: {
    [TOP_PROFIT]: ["2023-03-19", "2023-04-09", "~$5,000"],
    [TOP_ROI]: ["2023-03-19", "2023-04-09", "~$5,000"],
  },
};

const PNL_DESC = "totalPnl DESC";
const PNL_ASC = "totalPnl ASC";
const VOLUME_DESC = "tradingVolume DESC";
const VOLUME_ASC = "tradingVolume ASC";
const ROI_DESC = "roi DESC";
const ROI_ASC = "roi ASC";

const ORDERS_OPTIONS = {
  PNL_DESC,
  PNL_ASC,
  VOLUME_DESC,
  VOLUME_ASC,
  ROI_DESC,
  ROI_ASC,
};

const ORDER_BY_OPTIONS = [
  {
    value: PNL_DESC,
    label: "Highest to lowest in PnL",
  },
  {
    value: PNL_ASC,
    label: "Lowest to highest in PnL",
  },
  {
    value: VOLUME_DESC,
    label: "Highest to lowest in Trading Volume",
  },
  {
    value: VOLUME_ASC,
    label: "Lowest to highest in Trading volume",
  },
];

const MILLISECOND_A_DAY = 60 * 60 * 24;
const MIN_COLLATERAL = 500;

const TopTraderTab = ({ chainId }) => {
  const controller = new AbortController();
  const [data, setData] = useState([]);
  const [row, setRows] = useState(100);
  const [activeTab, setActiveTab] = useState(ALL_STATS);
  const MAX_NUMBER_PAGE = 100; //HARD FIXED
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });
  const startContestDate = useRef();
  const endContestDate = useRef();
  const [_, setLastUpdate] = useAnalyticsContext();

  const [order, setOrder] = useState(PNL_DESC);
  const [from, setFrom] = useState(1652288400);
  const [to, setTo] = useState(moment().startOf("minute").unix()); //now
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const NFTs = useAccountsNFT(accounts);

  const FILTER_BY_OPTIONS = useMemo(() => {
    return [
      {
        value: "24",
        label: "24 Hours",
        from: moment().unix() - MILLISECOND_A_DAY,
      },
      {
        value: "7",
        label: "7 Days",
        from: moment().unix() - MILLISECOND_A_DAY * 7,
      },
      {
        value: "1",
        label: "1 Month",
        from: moment().unix() - MILLISECOND_A_DAY * 30,
      },
      {
        value: "all",
        label: "All time",
        from: 1652288400,
      },
    ];
  }, []);

  const headerTable = useMemo(() => {
    const raw = [
      { children: [{ title: "Top" }, { title: "Wallet" }] },
      { title: "Win/Loss" },
      { title: "Trading Volume", canClick: true, type: "VOLUME", name: "tradingVolume" },
      { title: "PnL", canClick: true, type: "PNL", name: "totalPnl" },
    ];
    if (activeTab === TOP_ROI) {
      raw[raw.length - 1] = { title: "ROI (%)", canClick: true, hasTooltip: true, type: "ROI", name: "roi" };
    }

    return raw;
  }, [activeTab]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { data: trades } = await axios.get(`${getApiAnalytics(chainId)}leaderboard/top-trader`, {
        signal: controller.signal,
        params: {
          order,
          from,
          to,
          page,
          pageSize,
          // minCollateral: MIN_COLLATERAL,
        },
      });
      const accounts = Array.from(new Set(trades.rows.map((row) => row.account)));
      setAccounts(accounts);
      setData(trades.rows);
      setRows(trades.count);
      setLastUpdate(trades.lastDataUpdateTime);
      setIsLoading(false);
    })();
  }, [order, from, to, page, pageSize, chainId]);

  const countdownRenderer = (countdownProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const d = String(days);
    const h = String(hours);
    const m = String(minutes);
    const s = String(seconds);
    return (
      <div className="time-countdown">
        <div className="time-left">
          {d.padStart(2, "0")}
          <span>d </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {h.padStart(2, "0")}
          <span>h </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {m.padStart(2, "0")}
          <span>m</span>{" "}
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {s.padStart(2, "0")}
          <span>s</span>
        </div>
      </div>
    );
  };

  const handleSortVolume = (type) => {
    setOrder((prevOrder) => {
      const key = Object.keys(ORDERS_OPTIONS)
        .filter((option) => option.startsWith(type))
        .find((_option) => {
          return ORDERS_OPTIONS[_option] !== prevOrder;
        });

      return ORDERS_OPTIONS[key];
    });
  };

  useEffect(() => {
    controller.abort();
    if (activeTab !== ALL_STATS) {
      startContestDate.current = getUnixDate(CONTEST_TIMESTAMP[chainId][activeTab][0]);
      endContestDate.current = getUnixDate(CONTEST_TIMESTAMP[chainId][activeTab][1]);

      setFrom(startContestDate.current);
      setTo(endContestDate.current);
      if (activeTab === TOP_ROI) {
        setOrder(ROI_DESC);
      } else {
        setOrder(PNL_DESC);
      }
    } else {
      setFrom(1652288400);
      setTo(moment().startOf("minute").unix());
    }

    setPage(1);
  }, [chainId, activeTab]);
  const renderTop = () => {
    if (order.includes("tradingVolume")) return "Sort by Trading Volume";
    return "Sort by PnL";
  };
  const renderTopIndex = (index) => {
    let isDesc = true;
    if (order.includes("ASC")) isDesc = false;
    if (isDesc) return index + 1 + pageSize * (page - 1);
    else {
      return row - (index + pageSize * (page - 1));
    }
  };
  return (
    <TabWrapper>
      {chainId === 10 && (
        <StyledTab>
          <div className="tab-wrapper">
            <TabInner
              options={TAB_OPTIONS}
              optionLabels={TAB_OPTIONS_LABELS}
              option={activeTab}
              setOption={setActiveTab}
              onChange={setActiveTab}
            />
          </div>
        </StyledTab>
      )}
      {activeTab === ALL_STATS && !isSmallScreen && (
        <div className="time-wrapper">
          {FILTER_BY_OPTIONS.map((option) => (
            <div
              className={from === option.from ? "active" : ""}
              onClick={() => {
                setFrom(option.from);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}

      {activeTab !== ALL_STATS && (
        <StyledTimestamp>
          The competition runs{" "}
          {activeTab === ALL_STATS
            ? "All time"
            : `from ${getUnixDateHour(startContestDate.current * 1000)} UTC on ${moment(
                startContestDate.current * 1000
              ).format("LL")}, to ${getUnixDateHour(endContestDate.current * 1000)} UTC on ${moment(
                endContestDate.current * 1000
              ).format("LL")}`}
          .{" "}
          {Math.round(new Date().getTime() / 1000) > startContestDate.current ? (
            <>
              Ends in <Countdown date={new Date(endContestDate.current * 1000)} renderer={countdownRenderer} />
            </>
          ) : (
            <>
              Starts in <Countdown date={new Date(startContestDate.current * 1000)} renderer={countdownRenderer} />
            </>
          )}
          . <StyledExternalLink href={EXTERNAL_LINK[chainId].moreDetail}>More details</StyledExternalLink>
        </StyledTimestamp>
      )}

      {activeTab !== ALL_STATS && (
        <StyledText>
          <div>
            {activeTab === TOP_PROFIT ? (
              <>
                <h3>Highest Notional P&L</h3>
                Sum of all realized and unrealized profits and losses, including open positions at the deadline.
              </>
            ) : (
              <>
                <h3>Highest ROI (%)</h3>
                <div>ROI (%) is defined as: Profits / Max Collateral (min $500) * 100</div>
                <div>
                  Max collateral is defined as: Total collateral used in ALL positions (open or closed) during the
                  contest at once, deducting any profits earned.
                </div>
              </>
            )}
          </div>
          <p>{CONTEST_TIMESTAMP[chainId][activeTab][2]}</p>
        </StyledText>
      )}

      {isSmallScreen && (
        <>
          <div className="filter-block">
            Ordered by:{" "}
            <StyledSelect
              showArrow
              suffixIcon={<img className="downIc" src={downIc} alt="" />}
              onChange={(value) => {
                setOrder(value);
              }}
              defaultValue={ORDER_BY_OPTIONS[0]}
              bordered={false}
              options={ORDER_BY_OPTIONS}
            />
          </div>

          <div className="filter-block">
            Filtered by:{" "}
            <StyledSelect
              showArrow
              suffixIcon={<img className="downIc" src={downIc} alt="" />}
              onChange={(value) => {
                setFrom(FILTER_BY_OPTIONS.find((item) => item.value === value).from);
                setTo(moment().startOf("minute").unix());
              }}
              defaultValue={FILTER_BY_OPTIONS[FILTER_BY_OPTIONS.length - 1]}
              bordered={false}
              options={FILTER_BY_OPTIONS}
            />
          </div>
        </>
      )}
      <div className="header-table">
        {headerTable.map((header) => {
          return header.children ? (
            <div>
              {header.children.map((_children, index) => (
                <span>
                  {index === 0 ? (
                    <Tooltip
                      handle={`Top`}
                      position="left-bottom"
                      renderContent={() => {
                        return <p className="text-white">{renderTop()}</p>;
                      }}
                    />
                  ) : (
                    _children.title
                  )}
                </span>
              ))}
            </div>
          ) : header.canClick ? (
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => handleSortVolume(header.type)}
            >
              {header.hasTooltip ? (
                <Tooltip
                  handle={<span>{header.title}</span>}
                  position="left-top"
                  renderContent={() => "Profits / Max Collateral (min $500) * 100"}
                />
              ) : (
                <span>{header.title}</span>
              )}
              <div className="group-arrow">
                <span>
                  <img
                    src={order.indexOf(header.name) !== -1 && order.indexOf("ASC") !== -1 ? upIcWhite : upIc}
                    alt=""
                    className="up"
                  />
                </span>

                <span>
                  <img
                    src={order.indexOf(header.name) !== -1 && order.indexOf("DESC") !== -1 ? upIcWhite : upIc}
                    alt=""
                    className="down"
                  />
                </span>
              </div>
            </span>
          ) : (
            <span>{header.title}</span>
          );
        })}
      </div>
      <div className="body-table">
        {!isLoading ? (
          <>
            {data.length > 0 ? (
              <>
                {data.map((item, index) => {
                  const rank = page * pageSize - (pageSize - 1);
                  return activeTab === TOP_ROI ? (
                    <TraderItem
                      haveRibbon={order === ROI_DESC}
                      chainId={chainId}
                      key={index}
                      data={item}
                      rank={renderTopIndex(index)}
                      type="ROI"
                      NFTid={NFTs?.[item.account]}
                    />
                  ) : activeTab === TOP_PROFIT ? (
                    <TraderItem
                      haveRibbon={order === PNL_DESC}
                      chainId={chainId}
                      key={index}
                      data={item}
                      rank={renderTopIndex(index)}
                      type="PROFIT"
                      NFTid={NFTs?.[item.account]}
                    />
                  ) : (
                    <TraderItem
                      chainId={chainId}
                      key={index}
                      data={item}
                      rank={renderTopIndex(index)}
                      NFTid={NFTs?.[item.account]}
                    />
                  );
                })}
                <Pagination
                  rowsPerPage={pageSize}
                  setRowsPerPage={setPageSize}
                  row={row}
                  currentPage={page}
                  setCurrentPage={setPage}
                  maxNumberOfPage={MAX_NUMBER_PAGE}
                />
              </>
            ) : (
              <NoItemFound />
            )}
          </>
        ) : (
          // <Loader />
          <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
        )}
      </div>
    </TabWrapper>
  );
};

const StyledSelect = styled(Select)`
  width: fit-content;
`;

const StyledTab = styled.div`
  margin-bottom: 22.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Tab-option {
    margin: auto;
    height: 45px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledTimestamp = styled.div`
  color: #b7b7bd;
  font-size: 14px;
  line-height: 2.15rem;
  margin-bottom: 2.325rem;

  .time-countdown {
    display: inline-flex;
  }

  .space {
    margin: 0 2.5px;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: #34f5ae;
`;

const StyledText = styled.div`
  margin: 10px 0 30px;
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    display: inline-block;
    margin: 0;
    font-size: 1.75em;
    font-weight: 600;
    color: #34f5ae;
    text-shadow: rgb(56 229 103) 1px 1px 20px, rgb(56 229 103) 0px 0px 20px;
  }
`;

const TabInner = styled(Tab)`
  padding: 8px;
  height: 60px;
  background-color: #191b2e;
`;

export default TopTraderTab;
