import { getServerUrl } from "config/backend";
import { getContract } from "config/contracts";
import { getTokenBySymbol, getWrappedToken } from "config/tokens";
import { getChainlinkChartPricesFromGraph, getChartPricesFromStats } from "domain/prices";
import { ethers } from "ethers";

export const getTokenChartPrice = async (chainId: number, symbol: string, period: string) => {
  let prices;
  try {
    prices = await getChartPricesFromStats(chainId, symbol, period);
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex, "Switching to graph chainlink data");
    try {
      prices = await getChainlinkChartPricesFromGraph(symbol, period);
    } catch (ex2) {
      // eslint-disable-next-line no-console
      console.warn("getChainlinkChartPricesFromGraph failed", ex2);
      prices = [];
    }
  }
  return prices;
};

export async function getCurrentPriceOfToken(chainId: number, symbol: string) {
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  try {
    const indexPricesUrl = getServerUrl(chainId, "/prices");
    const response = await fetch(indexPricesUrl);
    const indexPrices = await response.json();
    let symbolInfo = getTokenBySymbol(chainId, symbol);
    if (!response.ok) {
      const indexPricesInError = JSON.parse(localStorage.getItem("CHART_PRICES") as string);
      return indexPricesInError[
        symbolInfo.address.toLowerCase() === ethers.constants.AddressZero
          ? nativeTokenAddress.toLowerCase()
          : symbolInfo.address.toLowerCase()
      ];
      // throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (symbolInfo.isNative) {
      symbolInfo = getWrappedToken(chainId);
    }
    localStorage.setItem("CHART_PRICES", JSON.stringify(indexPrices));
    return indexPrices[symbolInfo.address.toLowerCase()];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return;
  }
}
