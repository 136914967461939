import bg from "img/home/bg.png";
import MintIc from "img/home/ic-mint.svg";
import icLink from "img/icons/link-green.svg";
import styled from "styled-components";

import { useWeb3React } from "@web3-react/core";
import useYourNFTInfo from "hooks/useYourNFTInfo";

import useGetListStakedNFT from "hooks/useGetListStakedNFT";
import { useChainId } from "lib/chains";
import { Link } from "react-router-dom";

import { commify } from "@ethersproject/units";
import { getLinkCDN } from "config/chains";
import { isEmpty } from "lodash";
import { TXT } from "../multichainTxt";

function NFTInfo() {
  const { active, activate, account, library } = useWeb3React();
  const { chainId } = useChainId();

  const info = useYourNFTInfo();

  const { listMyNFT } = useGetListStakedNFT();
  // const listMyNFT = info?.nfts || []

  return (
    <StyledHowTo>
      <div className="container">
        <SectionTitle>Your Mummy NFT</SectionTitle>
        <Desc>Earn ${TXT[chainId].nativeToken} from platform's collected fees with your NFT</Desc>
        <NFTWrap>
          <div className="nft-avt-box">
            <div className="avt-wrapper">
              <img
                alt="mint"
                className="mum1"
                style={{ filter: listMyNFT && listMyNFT[0] ? "" : "grayscale(1)" }}
                src={listMyNFT && listMyNFT[0] ? `${getLinkCDN(chainId)}${listMyNFT[0][0]}.png` : TXT[chainId].noNFTImg}
              />{" "}
            </div>

            <div className="total-nft">
              <div className="nft-label">Total NFT </div>
              <div className="nft-amount">{info?.totalNFT ? commify(info?.totalNFT) : "--"}</div>
            </div>
            <div className="total-nft">
              <div className="nft-label">Your esMMY </div>
              <div className="nft-amount">{info?.totalBonus ? commify(info?.totalBonus) : "--"}</div>
            </div>
          </div>
          <div className="actions">
            <a className="btn-outline" href="#/earn">
              Earn with esMMY
            </a>
            <Link className="btn-primary" to="/nft">
              {" "}
              <img alt="mint" src={MintIc} />
              Mint more NFT
            </Link>
          </div>
        </NFTWrap>
        {isEmpty(listMyNFT) ? (
          <EmptyContent>
            <img src={TXT[chainId].noNFTImg} className="img-avt" alt="img" />
            <div>
              <div className="txt-asset">You have no asset</div>

              <Link to={"/nft"} className="txt-link">
                Get Your Mummy NFT <img src={icLink} alt="" />
              </Link>
            </div>
          </EmptyContent>
        ) : (
          <ListNFT>
            {listMyNFT.map((item) => {
              return (
                <Item>
                  <img className="avt-nft" alt="mint" src={`${getLinkCDN(chainId)}${item[0]}.png`} />
                  <div className="nft-name">Mummy #{item[0]}</div>
                  <div className="nft-power">
                    Power: <span>{item[1]}</span>{" "}
                  </div>
                </Item>
              );
            })}
          </ListNFT>
        )}
      </div>
    </StyledHowTo>
  );
}

const EmptyContent = styled.div`
  img {
  }
  .img-avt {
    filter: grayscale(1);
    margin-bottom: 16px;
    @media screen and (max-width: 700px) {
      width: 200px;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: #03f5ae;
    margin-top: 8px;
    img {
      margin-left: 4px;
    }
  }
  text-align: center;
  border-radius: 24px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 24px;
  height: 472px;
  margin-bottom: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 700px) {
    height: fit-content;
    padding: 16px;
  }
`;
export default NFTInfo;

const StyledHowTo = styled.section`
  color: #ffffff;
  a {
    text-decoration: none;
  }
`;

const ListNFT = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  margin-bottom: 144px;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 82px;
  }
`;
const NFTWrap = styled.div`
  margin-bottom: 48px;
  height: 168px;
  padding: 24px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  /* border: 2px solid rgba(255, 255, 255, 0.1); */

  border-radius: 24px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
  @media screen and (max-width: 700px) {
    padding: 16px;
  }
  .actions {
    display: flex;
    a {
      padding: 8px 16px;
      height: 40px;
      text-decoration: none;
      img {
        margin-right: 4px;
      }
    }
    a:first-child {
      margin-right: 12px;
    }
    @media screen and (max-width: 991px) {
      margin-top: 16px;
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: 100%;
      a {
        width: 100%;
      }
      a:first-child {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
  .nft-avt-box {
    display: flex;
    align-items: center;
    .mum1 {
      border-radius: 16px;
    }
    img {
      width: 120px;
      height: 120px;
      margin-right: 24px;
    }
    .total-nft {
      margin-right: 32px;
    }
    .nft-label {
      font-weight: 400;
      font-size: 14px;

      margin-bottom: 4px;
    }
    .nft-amount {
      font-weight: 700;
      font-size: 36px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      .nft-amount {
        font-size: 24px;
      }
      .total-nft {
        margin-right: 4px;
        flex: 1;
      }
      .avt-wrapper img {
        width: 80px;
        height: 80px;
        margin-right: 16px;
      }
      /* flex-wrap: wrap;
      .avt-wrapper {
        width: 100%;
        margin-bottom: 8px;
        display: flex;
      }
      .total-nft {
        width: calc(50% - 16px);
      } */
    }
  }
`;
const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  text-align: center;
  margin-top: 50px;
  @media screen and (max-width: 700px) {
    font-size: 24px;
    padding: 0 24px;
  }
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 4px 0 48px 0;
  text-align: center;
`;
const Item = styled.div`
  .avt-nft {
    border-radius: 16px;
  }

  background: #1e1f35;
  border-radius: 16px;
  padding: 16px;
  img {
    width: 100%;
    height: 168px;
    margin-bottom: 12px;
    @media screen and (max-width: 700px) {
      height: auto;
    }
  }
  .nft-name {
    font-weight: 700;
    margin-bottom: 4px;
  }
  .nft-power {
    font-size: 14px;
    span {
      color: #03f5ae;
    }
  }
`;
