import WFTM from "img/ic_wftm_16.svg";
import BTC from "img/ic_btc_24.svg";
import ETH from "img/ic_eth_24.svg";
import USDC from "img/ic_usdc_24.svg";
import DAI from "img/ic_dai_24.svg";
import USDT from "img/ic_usdt_24.svg";
import BNB from 'img/ic_binance.svg'

const TOKEN_LISTS = {
  "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83": {
    name: "FTM",
    icon: WFTM,
    decimal: 18,
  },
  "0x321162cd933e2be498cd2267a90534a804051b11": {
    name: "BTC",
    icon: BTC,
    decimal: 8,
  },
  "0x04068da6c83afcfa0e13ba15a6696662335d5b75": {
    name: "USDC",
    icon: USDC,
    decimal: 6,
  },
  "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e": {
    name: "DAI",
    icon: DAI,
    decimal: 18,
  },
  "0x049d68029688eabf473097a2fc38ef61633a3c7a": {
    name: "USDT",
    icon: USDT,
    decimal: 6,
  },
  "0x74b23882a30290451a17c44f4f05243b6b58c76d": {
    name: "ETH",
    icon: ETH,
    decimal: 18,
  },
  "0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454": {
    name: "BNB",
    icon: BNB,
    decimal: 18,
  },
};

export const getNameTokenByAddress = (address: string) => {
  return TOKEN_LISTS[address?.toLowerCase()]?.name || "ETH";
};

export const getIconTokenByAddress = (address: string) => {
  return TOKEN_LISTS[address?.toLocaleLowerCase()]?.icon || ETH;
};

export const getDecimalTokenByAddress = (address: string) => {
  return TOKEN_LISTS[address?.toLocaleLowerCase()]?.decimal || 18;
};
