import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import useScrollToTop from "lib/useScrollToTop";
import { useCallback, useEffect, useRef, useState } from "react";
import { SWRConfig } from "swr";
import "antd/dist/reset.css";

import { HashRouter as Router, Route, Switch, useHistory, useLocation } from "react-router-dom";

import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_SLIPPAGE_AMOUNT,
  getAppBaseUrl,
  isDevelopment,
  isHomeSite,
  isMobileDevice,
  REFERRAL_CODE_QUERY_PARAM,
} from "lib/legacy";

import Actions from "pages/Actions/Actions";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import Buy from "pages/Buy/Buy";
import BuyGlp from "pages/BuyGlp/BuyGlp";
import ClaimEsGmx from "pages/ClaimEsGmx/ClaimEsGmx";
import CompleteAccountTransfer from "pages/CompleteAccountTransfer/CompleteAccountTransfer";
import Dashboard from "pages/Dashboard/Dashboard";
import Ecosystem from "pages/Ecosystem/Ecosystem";

import BuyGMX from "pages/BuyGMX/BuyGMX";
import { Exchange } from "pages/Exchange/Exchange";
import Home from "pages/Home/Home";
import NftWallet from "pages/NftWallet/NftWallet";
import OrdersOverview from "pages/OrdersOverview/OrdersOverview";
import PositionsOverview from "pages/PositionsOverview/PositionsOverview";
import Referrals from "pages/Referrals/Referrals";
import Stake from "pages/Stake/Stake";
import VaultFantom from "pages/Vault";
import VaultOP from "pages/Vault/indexOP";
import TopTraderTab from "pages/Analytics/components/TopTraderTab";
import TopPositionTab from "pages/Analytics/components/TopPositionTab";

import Checkbox from "components/Checkbox/Checkbox";
import Modal from "components/Modal/Modal";
import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "styles/Font.css";
import "styles/Input.css";
import "styles/Shared.css";
import "./App.scss";

import SEO from "components/Common/SEO";
import EventToastContainer from "components/EventToast/EventToastContainer";
import useEventToast from "components/EventToast/useEventToast";
import { decodeReferralCode, encodeReferralCode } from "domain/referrals";
import coinbaseImg from "img/coinbaseWallet.png";
import metamaskImg from "img/metamask.png";
import walletConnectImg from "img/walletconnect-circle-blue.svg";
import useRouteQuery from "lib/useRouteQuery";

import PositionRouter from "abis/PositionRouter.json";
import VaultV2 from "abis/VaultV2.json";
import VaultV2b from "abis/VaultV2b.json";
import { RedirectPopupModal } from "components/ModalViews/RedirectModal";
import { getContract } from "config/contracts";
import { REDIRECT_POPUP_TIMESTAMP_KEY } from "config/ui";
import Jobs from "pages/Jobs/Jobs";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import { useLocalStorage } from "react-use";

import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { I18nProvider } from "@lingui/react";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { Header } from "components/Header/Header";
import { ARBITRUM, AVALANCHE, MUMBAI, FANTOM, getExplorerUrl, OP } from "config/chains";
import {
  CURRENT_PROVIDER_LOCALSTORAGE_KEY,
  DISABLE_ORDER_VALIDATION_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  LANGUAGE_LOCALSTORAGE_KEY,
  REFERRAL_CODE_KEY,
  SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
} from "config/localStorage";
import { useChainId } from "lib/chains";
import { helperToast } from "lib/helperToast";
import { defaultLocale, dynamicActivate } from "lib/i18n";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import {
  activateInjectedProvider,
  clearWalletConnectData,
  clearWalletLinkData,
  getInjectedHandler,
  getWalletConnectV2Handler,
  hasCoinBaseWalletExtension,
  hasMetaMaskWalletExtension,
  useEagerConnect,
  useHandleUnsupportedNetwork,
  useInactiveListener,
} from "lib/wallets";
import NFT from "pages/NFT/NFT";
import YourNFT from "pages/NFT/YourNFT";
import StakeNFT from "pages/NFT/StakeNFT";
import Lock from "pages/LiquidityLock/Lock";
import Analytics from "pages/Analytics/Analytics";
import RouterTab from "components/RouterTab";
import HomeV3 from "pages/Home/HomeV3";
import StatsProvider from "contexts/StatsProvider";
import AnalyticsProvider from "pages/Analytics/context";
import News from "pages/News";
import Bridge from "pages/Bridge/Bridge";
import styled from "styled-components";
import { MdClose } from "react-icons/md";

// if ("ethereum" in window) {
//   window.ethereum.autoRefreshOnNetworkChange = false;
// }
if (window.ethereum !== undefined) {
  (window.ethereum || {}).autoRefreshOnNetworkChange = false;
}

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  return library;
}

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

const Notice = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  .Modal-close-icon {
    min-width: 20px;
    position: absolute;
    right: 16px;
    top: 8px;
  }
  .noti-container {
    display: flex;
    align-items: center;
    .noti-title {
      font-weight: 700;
    }
  }

  a {
    color: #231f20;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    text-decoration-line: underline;
  }
  padding: 10px 24px;

  gap: 10px;

  background: var(--main-red, #f6475d);;
  color: #fff;

  @media screen and (max-width: 1024px) {
    padding: 10px 16px;
    justify-content: flex-start;
    .noti-container {
      align-items: flex-start;
      flex-direction: column;
      .noti-title {
        margin-bottom: 8px;
      }
    }
  }
`;

// const arbWsProvider = new ethers.providers.WebSocketProvider(getAlchemyWsUrl());

// const avaxWsProvider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");
// avaxWsProvider.pollingInterval = 2000;
const ftmWsProvider = new ethers.providers.JsonRpcProvider("https://rpc.ankr.com/fantom");
const mumbaisProvider = new ethers.providers.JsonRpcProvider("https://rpc-mumbai.maticvigil.com");

const avaxsProvider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");

const opProvider = new ethers.providers.JsonRpcProvider("https://rpc.ankr.com/optimism");

function getWsProvider(active, chainId) {
  if (!active) {
    return;
  }
  // if (chainId === ARBITRUM) {
  //   return arbWsProvider;
  // }

  if (chainId === MUMBAI) {
    return mumbaisProvider;
  }
  if (chainId === AVALANCHE) {
    return avaxsProvider;
  }
  if (chainId === OP) {
    return opProvider;
  }
  if (chainId === FANTOM) {
    return ftmWsProvider;
  }
}

function FullApp() {
  const isHome = isHomeSite();
  const exchangeRef = useRef();
  const { connector, library, deactivate, activate, active } = useWeb3React();
  const { chainId } = useChainId();
  const location = useLocation();
  const history = useHistory();
  useEventToast();
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector, chainId]);
  const triedEager = useEagerConnect(setActivatingConnector);
  useInactiveListener(!triedEager || !!activatingConnector);

  useHandleUnsupportedNetwork();

  const query = useRouteQuery();

  useEffect(() => {
    let referralCode = query.get(REFERRAL_CODE_QUERY_PARAM);
    if (!referralCode || referralCode.length === 0) {
      const params = new URLSearchParams(window.location.search);
      referralCode = params.get(REFERRAL_CODE_QUERY_PARAM);
    }

    if (referralCode && referralCode.length <= 20) {
      const encodedReferralCode = encodeReferralCode(referralCode);
      if (encodeReferralCode !== ethers.constants.HashZero) {
        localStorage.setItem(REFERRAL_CODE_KEY, encodedReferralCode);
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has(REFERRAL_CODE_QUERY_PARAM)) {
          queryParams.delete(REFERRAL_CODE_QUERY_PARAM);
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [query, history, location]);

  const disconnectAccount = useCallback(() => {
    // only works with WalletConnect
    clearWalletConnectData();
    // force clear localStorage connection for MM/CB Wallet (Brave legacy)
    clearWalletLinkData();
    deactivate();
  }, [deactivate]);

  const disconnectAccountAndCloseSettings = () => {
    disconnectAccount();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
    setIsSettingsVisible(false);
  };

  const connectInjectedWallet = getInjectedHandler(activate, deactivate);
  const activateWalletConnectV2 = () => {
    getWalletConnectV2Handler(activate, deactivate, setActivatingConnector)();
    setWalletModalVisible(false);
  };

  const userOnMobileDevice = "navigator" in window && isMobileDevice(window.navigator);

  const activateMetaMask = () => {
    if (!hasMetaMaskWalletExtension()) {
      helperToast.error(
        <div>
          <span>MetaMask not detected.</span>
          <br />
          <br />
          {userOnMobileDevice ? (
            <span>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink>, and use MUMMY with its built-in
              browser
            </span>
          ) : (
            <span>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink> to start using MUMMY
            </span>
          )}
        </div>
      );
      return false;
    }
    attemptActivateWallet("MetaMask");
  };
  const activateCoinBase = () => {
    if (!hasCoinBaseWalletExtension()) {
      helperToast.error(
        <div>
          <span>Coinbase Wallet not detected.</span>
          <br />
          <br />
          {userOnMobileDevice ? (
            <span>
              <ExternalLink href="https://www.coinbase.com/wallet">Install Coinbase Wallet</ExternalLink>, and use MUMMY
              with its built-in browser
            </span>
          ) : (
            <span>
              <ExternalLink href="https://www.coinbase.com/wallet">Install Coinbase Wallet</ExternalLink> to start using
              MUMMY
            </span>
          )}
        </div>
      );
      return false;
    }
    attemptActivateWallet("CoinBase");
  };

  const attemptActivateWallet = (providerName) => {
    localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, true);
    localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, providerName);
    activateInjectedProvider(providerName);
    connectInjectedWallet();
  };

  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [redirectModalVisible, setRedirectModalVisible] = useState(false);
  const [shouldHideRedirectModal, setShouldHideRedirectModal] = useState(false);
  const [redirectPopupTimestamp, setRedirectPopupTimestamp] = useLocalStorage(REDIRECT_POPUP_TIMESTAMP_KEY);
  const [selectedToPage, setSelectedToPage] = useState("");
  const connectWallet = () => setWalletModalVisible(true);

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [savedSlippageAmount, setSavedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );
  const [slippageAmount, setSlippageAmount] = useState(0);
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false);
  const [shouldDisableValidationForTesting, setShouldDisableValidationForTesting] = useState(false);
  const [showPnlAfterFees, setShowPnlAfterFees] = useState(false);

  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false
  );

  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    false
  );
  const [savedShouldDisableValidationForTesting, setSavedShouldDisableValidationForTesting] =
    useLocalStorageSerializeKey([chainId, DISABLE_ORDER_VALIDATION_KEY], false);

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useLocalStorageSerializeKey(
    [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
    false
  );

  const openSettings = () => {
    const slippage = parseInt(savedSlippageAmount);
    setSlippageAmount((slippage / BASIS_POINTS_DIVISOR) * 100);
    setIsPnlInLeverage(savedIsPnlInLeverage);
    setShowPnlAfterFees(savedShowPnlAfterFees);
    setShouldDisableValidationForTesting(savedShouldDisableValidationForTesting);
    setIsSettingsVisible(true);
  };

  const saveAndCloseSettings = () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(`Slippage should be less than 5%`);
      return;
    }

    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(`Max slippage precision is 0.01%`);
      return;
    }

    setSavedIsPnlInLeverage(isPnlInLeverage);
    setSavedShowPnlAfterFees(showPnlAfterFees);
    setSavedShouldDisableValidationForTesting(shouldDisableValidationForTesting);
    setSavedSlippageAmount(basisPoints);
    setIsSettingsVisible(false);
  };

  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);
  const baseUrl = getAppBaseUrl();
  let appRedirectUrl = baseUrl + selectedToPage;
  if (localStorageCode && localStorageCode.length > 0 && localStorageCode !== ethers.constants.HashZero) {
    const decodedRefCode = decodeReferralCode(localStorageCode);
    if (decodedRefCode) {
      appRedirectUrl = `${appRedirectUrl}?ref=${decodedRefCode}`;
    }
  }

  const [pendingTxns, setPendingTxns] = useState([]);

  const showRedirectModal = (to) => {
    // setRedirectModalVisible(true);
    setSelectedToPage(to);
  };

  useEffect(() => {
    const checkPendingTxns = async () => {
      const updatedPendingTxns = [];
      for (let i = 0; i < pendingTxns.length; i++) {
        const pendingTxn = pendingTxns[i];
        const receipt = await library.getTransactionReceipt(pendingTxn.hash);
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.error(
              <div>
                <span>
                  Txn failed. <ExternalLink href={txUrl}>View</ExternalLink>
                </span>
                <br />
              </div>
            );
          }
          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.success(
              <div>
                {pendingTxn.message}{" "}
                <ExternalLink href={txUrl}>
                  <span>View</span>
                </ExternalLink>
                <br />
              </div>
            );
          }
          continue;
        }
        updatedPendingTxns.push(pendingTxn);
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns);
      }
    };

    const interval = setInterval(() => {
      checkPendingTxns();
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [library, pendingTxns, chainId]);

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");

  useEffect(() => {
    const wsVaultAbi = chainId === ARBITRUM ? VaultV2.abi : VaultV2b.abi;
    const wsProvider = getWsProvider(active, chainId);
    if (!wsProvider) {
      return;
    }

    const wsVault = new ethers.Contract(vaultAddress, wsVaultAbi, wsProvider);
    const wsPositionRouter = new ethers.Contract(positionRouterAddress, PositionRouter.abi, wsProvider);

    const callExchangeRef = (method, ...args) => {
      if (!exchangeRef || !exchangeRef.current) {
        return;
      }

      exchangeRef.current[method](...args);
    };

    // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
    // each time the Exchange components re-renders, which happens on every data update
    const onUpdatePosition = (...args) => callExchangeRef("onUpdatePosition", ...args);
    const onClosePosition = (...args) => callExchangeRef("onClosePosition", ...args);
    const onIncreasePosition = (...args) => callExchangeRef("onIncreasePosition", ...args);
    const onDecreasePosition = (...args) => callExchangeRef("onDecreasePosition", ...args);
    const onCancelIncreasePosition = (...args) => callExchangeRef("onCancelIncreasePosition", ...args);
    const onCancelDecreasePosition = (...args) => callExchangeRef("onCancelDecreasePosition", ...args);

    wsVault.on("UpdatePosition", onUpdatePosition);
    wsVault.on("ClosePosition", onClosePosition);
    wsVault.on("IncreasePosition", onIncreasePosition);
    wsVault.on("DecreasePosition", onDecreasePosition);
    wsPositionRouter.on("CancelIncreasePosition", onCancelIncreasePosition);
    wsPositionRouter.on("CancelDecreasePosition", onCancelDecreasePosition);

    return function cleanup() {
      wsVault.off("UpdatePosition", onUpdatePosition);
      wsVault.off("ClosePosition", onClosePosition);
      wsVault.off("IncreasePosition", onIncreasePosition);
      wsVault.off("DecreasePosition", onDecreasePosition);
      wsPositionRouter.off("CancelIncreasePosition", onCancelIncreasePosition);
      wsPositionRouter.off("CancelDecreasePosition", onCancelDecreasePosition);
    };
  }, [active, chainId, vaultAddress, positionRouterAddress]);
  let notiTitle = "";
  let notiDes = "";
  switch (chainId) {
    case FANTOM: {
      notiTitle = "Withdraw your esMMY in v1!";
      notiDes = (
        <div>
          After August 1st 12:00 PM (UTC), ALL esMMY vesting on v1 will become unwithdrawable. Please{" "}
          <a target="_self" href="https://legacy.mummy.finance/#/earn-v2/vest">
            withdraw
          </a>{" "}
          all esMMY and transfer them to v2 now.
        </div>
      );
      break;
    }
    case OP: {
      notiTitle = "Migrate your MMY now!";
      notiDes = (
        <div>
          The{" "}
          <a target="_self" href="https://optimistic.etherscan.io/address/0x47536f17f4ff30e64a96a7555826b8f9e66ec468">
            multiMMY{" "}
          </a>{" "}
          will be abandoned, and migration support will not be available from August 9th, 00:00 AM UTC. Please complete
          your{" "}
          <a target="_self" href=" https://app.mummy.finance/#/migrate">
            migration
          </a>{" "}
          before the deadline.
        </div>
      );
      break;
    }

    default:
      break;
  }

  const [hiddenNotiState, setHiddenNotiState] = useState(false);

  const handleCloseNoti = () => {
    setHiddenNotiState(true);
  };

  return (
    <>
      <div className="App">
        <div className="App-content">
          {/* {notiTitle && notiDes && !hiddenNotiState && location?.pathname !== "/" ? (
            <Notice>
              <div className="noti-container">
                <div className="noti-title">{notiTitle} &nbsp; </div>
                <div className="noti-des">{notiDes}</div>
              </div>
              <MdClose fontSize={20} className="Modal-close-icon" onClick={handleCloseNoti} />
            </Notice>
          ) : null} */}
          {true && <Notice>
            <span>We will be shutting down this website starting from January 30. Please ensure that all of your assets are unstaked before the deadline.
            </span>
            {/* <MdClose fontSize={20} className="Modal-close-icon" onClick={handleCloseNoti} /> */}
          </Notice>}
          {Date.now() < 1692180000000 && <Notice>
            {/* This is Mummy Legacy version, please withdraw your assets and switch to v2.
            <ExternalLink href="https://app.mummy.finance/#/trade">
              <span>Go to Mummy V2</span>
            </ExternalLink> */}
            <span><strong>EsMMY migration period:</strong> August 2nd - August 16th (14 days). Complete 
            {" "}<ExternalLink href="https://app.mummy.finance/#/earn-v2/vest">
              <span>migration</span>
            </ExternalLink>{" "}
             before the deadline.
            Affects rewarded esMMY on Fantom V1 and bonus esMMY from minting NFTs on Optimism.
            </span>
            <MdClose fontSize={20} className="Modal-close-icon" onClick={handleCloseNoti} />
          </Notice>}
          <Header
            disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            openSettings={openSettings}
            setWalletModalVisible={setWalletModalVisible}
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          />
          {isHome && (
            <Switch>
              <Route exact path="/">
                {/* <Home showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} /> */}
                <HomeV3 showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
              </Route>
              {/* <Route exact path="/referral-terms">
                <ReferralTerms />
              </Route>
              <Route exact path="/terms-and-conditions">
                <TermsAndConditions />
              </Route> */}
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          )}
          {!isHome && (
            <StatsProvider>
              <Switch>
                {/* <RouterTab /> */}
                <Route exact path="/">
                  {/* <Home showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} /> */}
                  {/* <HomeV3 showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} /> */}
                  <Dashboard />
                </Route>
                <Route exact path="/trade">
                  <Exchange
                    ref={exchangeRef}
                    savedShowPnlAfterFees={savedShowPnlAfterFees}
                    savedIsPnlInLeverage={savedIsPnlInLeverage}
                    setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    savedShouldShowPositionLines={savedShouldShowPositionLines}
                    setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                    connectWallet={connectWallet}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                </Route>
                <Route exact path="/dashboard">
                  <Dashboard />
                </Route>
                <Route exact path="/proof-of-locked-liquidity">
                  <Lock />
                </Route>

                <Route exact path="/nft">
                  <NFT connectWallet={connectWallet} setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
                </Route>
                <Route exact path="/stake-nft">
                  <StakeNFT connectWallet={connectWallet} setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
                </Route>
                <Route exact path="/your-nft">
                  <YourNFT />
                </Route>
                <Route exact path="/bridge">
                  <Bridge setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} connectWallet={connectWallet} />
                </Route>
                <Route exact path="/earn">
                  <Stake setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
                </Route>
                <Route path="/analytic">
                  <AnalyticsProvider>
                    <Analytics setPendingTxns={setPendingTxns} />
                  </AnalyticsProvider>
                </Route>
                <Route exact path="/buy">
                  <Buy
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    connectWallet={connectWallet}
                  />
                </Route>
                <Route exact path="/buy_mlp">
                  <BuyGlp
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    connectWallet={connectWallet}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                </Route>
                <Route exact path="/jobs">
                  <Jobs />
                </Route>
                <Route exact path="/buy_mmy">
                  <BuyGMX />
                </Route>
                <Route exact path="/ecosystem">
                  <Ecosystem />
                </Route>
                <Route exact path="/referrals">
                  <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
                </Route>
                <Route exact path="/referrals/:account">
                  <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
                </Route>
                <Route exact path="/nft_wallet">
                  <NftWallet />
                </Route>
                <Route exact path="/claim_es_gmx">
                  <ClaimEsGmx setPendingTxns={setPendingTxns} />
                </Route>
                <Route exact path="/actions">
                  <Actions />
                </Route>
                <Route exact path="/actions/:account">
                  <Actions />
                </Route>
                <Route exact path="/news">
                  <News />
                </Route>
                <Route exact path="/orders_overview">
                  <OrdersOverview />
                </Route>
                <Route exact path="/positions_overview">
                  <PositionsOverview />
                </Route>
                <Route exact path="/begin_account_transfer">
                  <BeginAccountTransfer setPendingTxns={setPendingTxns} />
                </Route>
                <Route exact path="/complete_account_transfer/:sender/:receiver">
                  <CompleteAccountTransfer setPendingTxns={setPendingTxns} />
                </Route>

                <Route path="/earn-v2">
                  <RouterTab setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            </StatsProvider>
          )}
        </div>
      </div>

      <ToastContainer
        limit={1}
        transition={Zoom}
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        draggable={false}
        pauseOnHover
      />
      <EventToastContainer />
      <RedirectPopupModal
        redirectModalVisible={redirectModalVisible}
        setRedirectModalVisible={setRedirectModalVisible}
        appRedirectUrl={appRedirectUrl}
        setRedirectPopupTimestamp={setRedirectPopupTimestamp}
        setShouldHideRedirectModal={setShouldHideRedirectModal}
        shouldHideRedirectModal={shouldHideRedirectModal}
      />
      <Modal
        className="Connect-wallet-modal"
        isVisible={walletModalVisible}
        setIsVisible={setWalletModalVisible}
        label={`Connect Wallet`}
      >
        <button className="Wallet-btn MetaMask-btn" onClick={activateMetaMask}>
          <img src={metamaskImg} alt="MetaMask" />
          <div>
            <span>MetaMask</span>
          </div>
        </button>
        <button className="Wallet-btn CoinbaseWallet-btn" onClick={activateCoinBase}>
          <img src={coinbaseImg} alt="Coinbase Wallet" />
          <div>
            <span>Coinbase Wallet</span>
          </div>
        </button>
        <button className="Wallet-btn WalletConnect-btn" onClick={activateWalletConnectV2}>
          <img src={walletConnectImg} alt="WalletConnect" />
          <div>
            <span>WalletConnect</span>
          </div>
        </button>
      </Modal>
      <Modal
        className="App-settings"
        isVisible={isSettingsVisible}
        setIsVisible={setIsSettingsVisible}
        label={`Settings`}
      >
        <div className="App-settings-row">
          <div>
            <span>Allowed Slippage</span>
          </div>
          <div className="App-slippage-tolerance-input-container">
            <input
              type="number"
              className="App-slippage-tolerance-input"
              min="0"
              value={slippageAmount}
              onChange={(e) => setSlippageAmount(e.target.value)}
            />
            <div className="App-slippage-tolerance-input-percent">%</div>
          </div>
        </div>
        <div className="Exchange-settings-row">
          <Checkbox isChecked={showPnlAfterFees} setIsChecked={setShowPnlAfterFees}>
            <span>Display PnL after fees</span>
          </Checkbox>
        </div>
        <div className="Exchange-settings-row">
          <Checkbox isChecked={isPnlInLeverage} setIsChecked={setIsPnlInLeverage}>
            <span>Include PnL in leverage display</span>
          </Checkbox>
        </div>
        {isDevelopment() && (
          <div className="Exchange-settings-row">
            <Checkbox isChecked={shouldDisableValidationForTesting} setIsChecked={setShouldDisableValidationForTesting}>
              <span>Disable order validations</span>
            </Checkbox>
          </div>
        )}

        <button className="App-cta Exchange-swap-button" onClick={saveAndCloseSettings}>
          <span>Save</span>
        </button>
      </Modal>
    </>
  );
}

function App() {
  useScrollToTop();
  useEffect(() => {
    const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale;
    dynamicActivate(defaultLanguage);
  }, []);
  return (
    <SWRConfig value={{ refreshInterval: 5000 }}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <SEO>
          <Router>
            <I18nProvider i18n={i18n}>
              <FullApp />
            </I18nProvider>
          </Router>
        </SEO>
      </Web3ReactProvider>
    </SWRConfig>
  );
}

export default App;
