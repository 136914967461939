import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import fantomImg from "img/ic_ftm_40.svg";
import opImg from "img/ic_op_40.svg";
import arbImg from "img/ic_arbitrum_24.svg";
import useSwitchChain from "hooks/useSwitchChain";
import chainPattern from "img/home/chain_pattern.svg";

import { FANTOM, OP, ARBITRUM } from "config/chains";
import { StatsContext } from "contexts/StatsProvider";
import { formatAmount } from "lib/numbers";
import { USD_DECIMALS } from "lib/legacy";
import { getSkeletonClassName } from "utils/skeletonHelper";

const AvailableNetworks = (chainId) => {
  const [switchChain] = useSwitchChain(chainId);

  const { totalValueLocked, totalFees, totalTradingVolume } = useContext(StatsContext);

  return (
    <Wrapper>
      <div className="container">
        <Title>Available on your preferred network</Title>
        <Description>Mummy is currently live on Fantom, Optimism, and Arbitrum.</Description>
        <Content>
          <Fade left>
            <Chain>
              <img src={fantomImg} alt="fantom-icon" />
              <div className="chain-name">Fantom</div>
              <div className="chain-info">
                <div className="row">
                  <span>Total Volume</span>
                  <div className={`value ${getSkeletonClassName(totalTradingVolume[FANTOM])}`}>
                    ${formatAmount(totalTradingVolume[FANTOM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Value Locked</span>
                  <div className={`value ${getSkeletonClassName(totalValueLocked[FANTOM])}`}>
                    ${formatAmount(totalValueLocked[FANTOM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Fees</span>
                  <div className={`value ${getSkeletonClassName(totalFees[FANTOM])}`}>
                    ${formatAmount(totalFees[FANTOM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
              </div>
              <div className="action-btn">
                <LauchButton to="/trade" onClick={() => switchChain(FANTOM)} className="fantom">
                  Launch App
                </LauchButton>
              </div>
            </Chain>
          </Fade>
          <Fade right>
            <Chain>
              <img src={opImg} alt="op-icon" />
              <div className="chain-name">Optimism</div>
              <div className="chain-info">
                <div className="row">
                  <span>Total Volume</span>
                  <div className={`value ${getSkeletonClassName(totalTradingVolume[OP])}`}>
                    ${formatAmount(totalTradingVolume[OP], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Value Locked</span>
                  <div className={`value ${getSkeletonClassName(totalValueLocked[OP])}`}>
                    ${formatAmount(totalValueLocked[OP], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Fees</span>
                  <div className={`value ${getSkeletonClassName(totalFees[OP])}`}>
                    ${formatAmount(totalFees[OP], USD_DECIMALS, 0, true)}
                  </div>
                </div>
              </div>
              <div className="action-btn">
                <LauchButton to="/trade" onClick={() => switchChain(OP)} className="optimism">
                  Launch App
                </LauchButton>
              </div>
            </Chain>
          </Fade>
          <Fade right style={{
          background: "#0077e51a",
        }}>
            <Chain>
              <img src={arbImg} alt="arb-icon" />
              <div className="chain-name">Arbitrum</div>
              <div className="chain-info">
                <div className="row">
                  <span>Total Volume</span>
                  <div className={`value ${getSkeletonClassName(totalTradingVolume[ARBITRUM])}`}>
                    ${formatAmount(totalTradingVolume[ARBITRUM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Value Locked</span>
                  <div className={`value ${getSkeletonClassName(totalValueLocked[ARBITRUM])}`}>
                    ${formatAmount(totalValueLocked[ARBITRUM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
                <div className="row">
                  <span>Total Fees</span>
                  <div className={`value ${getSkeletonClassName(totalFees[ARBITRUM])}`}>
                    ${formatAmount(totalFees[ARBITRUM], USD_DECIMALS, 0, true)}
                  </div>
                </div>
              </div>
              <div className="action-btn">
                <LauchButton to="/trade" onClick={() => switchChain(ARBITRUM)} className="arb">
                  Launch App
                </LauchButton>
              </div>
            </Chain>
          </Fade>
        </Content>
      </div>
    </Wrapper>
  );
};

const Content = styled.div`
  display: flex;
  align-items: stretch;
  gap: 24px;
  width: 100%;
  margin-top: 20px;
  padding: 0 112px;

  > * {
    flex: 1;
  }

  @media (max-width: 1023px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Chain = styled.div`
  flex: 1;
  position: relative;
  padding: 24px;
  border-radius: 24px;
  background-color: #13b5ec1a;
  overflow: hidden;

  &:has(img[alt="op-icon"]) {
    background-color: #ff04201a;
  }

  > img {
    width: 80px;
    height: 80px;
    position: absolute;
    top: -12px;
    right: -12px;
  }

  .chain-name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }

  .chain-info {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: column;
    margin: 24px 0;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > span {
        color: #ffffff99;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
      }

      .value {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #fff;

        &.skeleton-box {
          width: 100px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 16px;

    > img {
      width: 64px;
      height: 64px;
    }

    .chain-name {
      font-size: 16px;
    }

    .chain-info {
      margin: 16px 0;
      gap: 12px;

      .row {
        > span {
          font-size: 14px;
        }

        .value {
          font-size: 14px;
        }
      }
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (max-width: 1023px) {
    font-size: 24px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
  color: #ffffff99;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const LauchButton = styled(Link)`
  display: flex;
  flex: 1;
  height: 40px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  border: none;
  outline: none;
  padding: 10px;
  background-color: #13b5ec;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  &:hover {
    filter: brightness(80%);
    transition: 0.3s;
  }

  &.optimism {
    background-color: #ff2d44;
  }

  &.arb {
    background-color: #0077E5;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  padding: 64px;
  height: 520px;
  background-image: linear-gradient(
    90deg,
    rgba(0, 190, 255, 0.2) 0.49%,
    rgba(255, 255, 255, 0) 50.76%,
    rgba(255, 4, 32, 0.2) 100%
  );
  position: relative;

  &::before {
    content: "";
    background-image: url(${chainPattern});
    background-position: center;
    position: absolute;
    opacity: 0.15;
    inset: 0;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  .action-btns {
    width: 424px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
  }

  @media (max-width: 1023px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 767px) {
    height: fit-content;
    padding: 64px 16px;

    .action-btns {
      grid-template-columns: repeat(1, 171px);
      padding: 0 102px;
    }
  }
`;

export default AvailableNetworks;
