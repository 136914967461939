import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Fade from "react-reveal/Fade";

import ftm24Icon from "img/ic_ftm_24.svg";
import glp40Icon from "img/ic_mlp_40.svg";
import gmx40Icon from "img/ic_mmy_40.svg";
import op24Icon from "img/ic_op_24.svg";
import arb24Icon from "img/ic_arbitrum_24.svg";
import ExternalLink from "components/ExternalLink/ExternalLink";
import APRLabel from "components/APRLabel/APRLabel";
import useSWR from "swr";
import { StatsContext } from "contexts/StatsProvider";

import { ARBITRUM, FANTOM, OP } from "config/chains";
import { expandDecimals, formatAmount } from "lib/numbers";
import { GLP_DECIMALS, USD_DECIMALS } from "lib/legacy";
import { getSkeletonClassName } from "utils/skeletonHelper";
import { useGmxPrice } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import GlpManager from "abis/GlpManager.json";
import { ethers } from "ethers";
import ReaderV2 from "abis/ReaderV2.json";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import TooltipComponent from "components/Tooltip/Tooltip";
const { AddressZero } = ethers.constants;
const EcosystemTokens = () => {
  // const { gmxPrice, glpPrice } = useContext(StatsContext);
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { gmxPrice } = useGmxPrice(chainId || FANTOM, {}, active);

  const { gmxPriceFromFantom, gmxPriceFromOP, gmxPriceFromArbitrum } = useGmxPrice(
    chainId || FANTOM,
    {
      arbitrum: (chainId || FANTOM) === ARBITRUM ? library : undefined,
      op: (chainId || FANTOM) === OP ? library : undefined,
    },
    active
  );
  const readerAddress = getContract(chainId || FANTOM, "Reader");
  const readerAddressFantom = getContract(FANTOM, "Reader");
  const readerAddressOp = getContract(OP, "Reader");
  const readerAddressArbitrum = getContract(ARBITRUM, "Reader");
  const glpManagerAddress = getContract(chainId || FANTOM, "GlpManager");
  const glpManagerAddressFANTOM = getContract(FANTOM, "GlpManager");
  const glpManagerAddressOP = getContract(OP, "GlpManager");
  const glpManagerAddressARB = getContract(ARBITRUM, "GlpManager");

  const gmxAddress = getContract(chainId || FANTOM, "GMX");
  const glpAddress = getContract(chainId || FANTOM, "GLP");
  const usdgAddress = getContract(chainId || FANTOM, "USDG");

  const gmxAddressFantom = getContract(FANTOM, "GMX");
  const glpAddressFantom = getContract(FANTOM, "GLP");
  const usdgAddressFantom = getContract(FANTOM, "USDG");

  const gmxAddressOp = getContract(OP, "GMX");
  const glpAddressOp = getContract(OP, "GLP");
  const usdgAddressOp = getContract(OP, "USDG");

  const gmxAddressArbitrum = getContract(ARBITRUM, "GMX");
  const glpAddressArbitrum = getContract(ARBITRUM, "GLP");
  const usdgAddressArbitrum = getContract(ARBITRUM, "USDG");

  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];
  const tokensForSupplyQueryFantom = [gmxAddressFantom, glpAddressFantom, usdgAddressFantom];
  const tokensForSupplyQueryOp = [gmxAddressOp, glpAddressOp, usdgAddressOp];
  const tokensForSupplyQueryArbitrum = [gmxAddressArbitrum, glpAddressArbitrum, usdgAddressArbitrum];

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });
  const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, glpManagerAddressOP, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });
  // console.log("?????", {glpManagerAddressOP, aumsOP});
  const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, glpManagerAddressFANTOM, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });

  const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, glpManagerAddressARB, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 5000,
  });

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { data: totalSuppliesFantom } = useSWR(
    [
      `Dashboard:totalSuppliesFantom:${active}`,
      FANTOM,
      readerAddressFantom,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQueryFantom]),
    }
  );
  const { data: totalSuppliesOP } = useSWR(
    [`Dashboard:totalSuppliesOP:${active}`, OP, readerAddressOp, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOp]),
    }
  );
  // console.log("?????", {readerAddressOp, tokensForSupplyQueryOp,totalSuppliesOP });
  const { data: totalSuppliesArbitrum } = useSWR(
    [
      `Dashboard:totalSuppliesArb:${active}`,
      ARBITRUM,
      readerAddressArbitrum,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQueryArbitrum]),
    }
  );
  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }
  let aumOP;
  if (aumsOP && aumsOP.length > 0) {
    aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  }

  let aumFANTOM;
  if (aumsFANTOM && aumsFANTOM.length > 0) {
    aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  }

  let aumArb;
  if (aumsArb && aumsArb.length > 0) {
    aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  let glpPriceFantom;
  let glpPriceOp;
  let glpPriceArb;
  let glpSupplyFantom;
  let glpSupplyOp;
  let glpSupplyArbitrum;
  let glpMarketCapFantom;
  let glpMarketCapOp;
  let glpMarketCapArbitrum;
  let totalGlpSupply;
  if (aum && totalSupplies && totalSupplies[3]) {
    glpSupply = totalSupplies[3];

    glpPrice =
      aum && aum.gt(0) && glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (aumFANTOM && totalSuppliesFantom && totalSuppliesFantom[3]) {
    glpSupplyFantom = totalSuppliesFantom[3];

    glpPriceFantom =
      aumFANTOM && aumFANTOM.gt(0) && glpSupplyFantom.gt(0)
        ? aumFANTOM.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupplyFantom)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCapFantom = glpPriceFantom.mul(glpSupplyFantom).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (aumOP && totalSuppliesOP && totalSuppliesOP[3]) {
    glpSupplyOp = totalSuppliesOP[3];

    glpPriceOp =
      aumOP && aumOP.gt(0) && glpSupplyOp.gt(0)
        ? aumOP.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupplyOp)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCapOp = glpPriceOp.mul(glpSupplyOp).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (aumArb && totalSuppliesArbitrum && totalSuppliesArbitrum[3]) {
    glpSupplyArbitrum = totalSuppliesArbitrum[3];

    glpPriceArb =
      aumArb && aumArb.gt(0) && glpSupplyArbitrum.gt(0)
        ? aumArb.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupplyArbitrum)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCapArbitrum = glpPriceArb.mul(glpSupplyArbitrum).div(expandDecimals(1, GLP_DECIMALS));
  }
  return (
    <Wrapper className="container">
      <Fade bottom>
        <Title>Ecosystem tokens</Title>
      </Fade>
      <TokenCardContainer>
        <Fade left>
          <TokenCard>
            <CardTitle>
              <TokenImg>
                <img src={gmx40Icon} alt="MMY Token Icon" />
              </TokenImg>
              <TokenInfo>
                <div className="token-info">
                  <div className="token-symbol">MMY</div>
                  <span className={`price ${getSkeletonClassName(gmxPrice)}`}>
                    {!gmxPrice && <div className="skeleton-box" />}
                    {gmxPrice && (
                      <TooltipComponent
                        position="left-bottom"
                        className="nowrap"
                        handle={"$" + formatAmount(gmxPrice, USD_DECIMALS, 2, true)}
                        renderContent={() => (
                          <div className="customTooltip">
                            <StatsTooltipRow
                              label={`Price on Fantom`}
                              value={formatAmount(gmxPriceFromFantom, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Optimism`}
                              value={formatAmount(gmxPriceFromOP, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Arbitrum`}
                              value={formatAmount(gmxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                          </div>
                        )}
                      />
                    )}
                  </span>
                </div>
                {!isMobile && (
                  <>
                    <ExternalLink
                      href="https://docs.mummy.finance/tokenomics"
                      target={"_blank"}
                      className="btn-readmore"
                    >
                      Read more
                    </ExternalLink>
                    <Link to="/buy_mmy" className="buy-btn">
                      Buy
                    </Link>
                  </>
                )}
              </TokenInfo>
            </CardTitle>

            <TokenCardDescription>
              MMY is the utility and governance token. Accrues 30% of the platform's generated fees.
            </TokenCardDescription>
            <TokenAPR>
              <div className="row ftm">
                <div className="title">
                  <img src={ftm24Icon} alt="ftm-symbol" />
                  Fantom APR:{" "}
                  <span>
                    <APRLabel chainId={FANTOM} label="gmxAprTotal" key="FANTOM" />
                  </span>
                </div>
              </div>
              <div className="row op">
                <div className="title">
                  <img src={op24Icon} alt="op-symbol" />
                  Optimism APR:{" "}
                  <span>
                    <APRLabel chainId={OP} label="gmxAprTotal" key="OP" />
                  </span>
                </div>
              </div>
              <div className="row arb">
                <div className="title">
                  <img src={arb24Icon} alt="arb-symbol" />
                  Arbitrum APR:{" "}
                  <span>
                    <APRLabel chainId={ARBITRUM} label="gmxAprTotal" key="ARBITRUM" />
                  </span>
                </div>
              </div>
            </TokenAPR>
            {isMobile && (
              <div className="mobile-actions">
                <ExternalLink href="https://docs.mummy.finance/tokenomics" target={"_blank"} className="btn-readmore">
                  Read more
                </ExternalLink>
                <Link to="/buy_mmy" className="buy-btn">
                  Buy
                </Link>
              </div>
            )}
          </TokenCard>
        </Fade>
        <Fade right>
          <TokenCard>
            <CardTitle>
              <TokenImg>
                <img src={glp40Icon} alt="mlp40Icon" />
              </TokenImg>
              <TokenInfo>
                <div className="token-info">
                  <div className="token-symbol">MLP</div>
                  <span className={`price ${getSkeletonClassName(glpPrice)}`}>
                    {!glpPrice && <div className="skeleton-box" />}
                    {glpPrice && (
                      <TooltipComponent
                        position="left-bottom"
                        className="nowrap"
                        handle={"$" + formatAmount(glpPrice, USD_DECIMALS, 2, true)}
                        renderContent={() => (
                          <div className="customTooltip">
                            <StatsTooltipRow
                              label={`Price on Fantom`}
                              value={formatAmount(glpPriceFantom, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Optimism`}
                              value={formatAmount(glpPriceOp, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                            <StatsTooltipRow
                              label={`Price on Arbitrum`}
                              value={formatAmount(glpPriceArb, USD_DECIMALS, 2, true)}
                              showDollar={true}
                            />
                          </div>
                        )}
                      />
                    )}
                  </span>
                </div>
                {!isMobile && (
                  <>
                    <ExternalLink href="https://docs.mummy.finance/mlp" target={"_blank"} className="btn-readmore">
                      Read more
                    </ExternalLink>
                    <Link to="/buy_mlp" className="buy-btn">
                      Buy
                    </Link>
                  </>
                )}
              </TokenInfo>
            </CardTitle>

            <TokenCardDescription>
              MLP is the liquidity provider token. Accrues 60% of the platform's generated fees.
            </TokenCardDescription>
            <TokenAPR>
              <div className="row ftm">
                <div className="title">
                  <img src={ftm24Icon} alt="ftm-symbol" />
                  Fantom APR:{" "}
                  <span>
                    <APRLabel chainId={FANTOM} label="glpAprTotal" key="FANTOM" />
                  </span>
                </div>
              </div>
              <div className="row op">
                <div className="title">
                  <img src={op24Icon} alt="op-symbol" />
                  Optimism APR:{" "}
                  <span>
                    <APRLabel chainId={OP} label="glpAprTotal" key="OP" />
                  </span>
                </div>
              </div>
              <div className="row arb">
                <div className="title">
                  <img src={arb24Icon} alt="arb-symbol" />
                  Arbitrum APR:{" "}
                  <span>
                    <APRLabel chainId={ARBITRUM} label="glpAprTotal" key="ARBITRUM" />
                  </span>
                </div>
              </div>
            </TokenAPR>
            {isMobile && (
              <div className="mobile-actions">
                <ExternalLink href="https://docs.mummy.finance/mlp" target={"_blank"} className="btn-readmore">
                  Read more
                </ExternalLink>
                <Link to="/buy_mlp" className="buy-btn">
                  Buy
                </Link>
              </div>
            )}
          </TokenCard>
        </Fade>
      </TokenCardContainer>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  overflow: hidden;

  > .row {
    width: 100%;
    padding: 18px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-image: linear-gradient(to right, #13b5ec1a, #13b5ec00);

    &.op {
      background-image: linear-gradient(to right, #ff04201a, #ff042000);
      border-top: 1px solid #ffffff1a;
    }

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      margin-right: auto;
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        width: 36px;
        height: 36px;
      }

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #03f5ae;
      }
    }

    .btn-buy {
      border-radius: 8px;
      width: 140px;
      background-color: #03f5ae;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #080715;
      text-decoration: none;
    }

    @media (max-width: 767px) {
      flex-direction: column;

      .btn-buy {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 16px;

  .token-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .token-symbol {
    color: white;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }

  .price {
    color: #03f5ae;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    .Tooltip-handle {
      text-decoration-color: #03f5ae;
    }
  }

  @media screen and (max-width: 768px) {
    .token-symbol {
      font-size: 16px;
    }
  }
`;

const TokenImg = styled.div`
  padding: 16px;
  background-image: linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);
  border-radius: 0px 0px 12px 12px;

  img {
    width: 48px;
    height: 48px;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 36px;
      height: 36px;
    }
  }
`;

const TokenCardDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff99;
  margin-top: 72px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-top: 68px;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 24px;
  right: 24px;

  @media screen and (max-width: 768px) {
    left: 16px;
    right: 16px;
  }
`;

const TokenCard = styled.div`
  padding: 24px;
  background-color: #151928;
  border-radius: 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .btn-readmore {
    height: 40px;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
    margin-right: 16px;
    margin-left: auto;
    transition: 0.3s;
    &:hover {
      color: #03f5ae;
      border: 1px solid #03f5ae;
    }
  }

  .buy-btn {
    background-color: #03f5ae;
    color: #080715;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    width: 120px;
    height: 40px;
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    text-align: center;
    &:hover {
      background: #04d397;
      color: #080715 !important;
    }
  }

  .mobile-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    > * {
      flex: 1;
    }

    .btn-readmore {
      margin: 0;
      justify-content: center;
      padding: 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 16px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 56px;
  line-height: 140%;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 40px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    text-align: left;
  }
`;

const Wrapper = styled.div`
  margin-top: 120px !important;
  padding: 0 24px;

  @media (max-width: 1023px) {
    padding: 0 24px;
  }

  @media (max-width: 767px) {
    padding: 0 16px;
    .Tooltip-popup {
      width: 250px !important;
    }
    .Tooltip-handle {
      color: #03f5ae !important;
    }
  }
`;

export default EcosystemTokens;
