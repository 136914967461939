import { Menu } from "@headlessui/react";
import IcDown from "img/ic_down_white.svg";
import "./Bridge.scss";
import styled from "styled-components";
import LayerZeroIcon from "img/layer0.svg";
import icFantom from "img/ic_ftm_24.svg";
import icOp from "img/ic_op_24.svg";
import icArb from "img/ic_arbitrum_24.svg";
import icScan from "img/ic_scan.svg";
import icScanWhite from "img/ic_scan_white.svg";
import { IoMdSwap } from "react-icons/io";
import { FANTOM, getConstant } from "config/chains";
import { OP } from "config/chains";
import { ARBITRUM } from "config/chains";
import { useEffect, useState } from "react";
import Footer from "components/Footer/Footer";
import bg from "img/earn/bg.svg";
import Tooltip from "components/Tooltip/Tooltip";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import { useWeb3React } from "@web3-react/core";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";
import BridgedReader from "abis/Bridge.json";
import { expandDecimals, formatAmount } from "lib/numbers";
import { BigNumber, ethers } from "ethers";
import { numberWithCommas } from "lib/numbers";
import { getBalanceAndSupplyData } from "lib/legacy";
import ReaderV2 from "abis/ReaderV2.json";
import { useBridgeApi } from "domain/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Token from "abis/Token.json";
import { approveTokens } from "domain/tokens";
import { parseValue } from "graphql";
import { switchNetwork } from "lib/wallets";
import Pagination from "pages/Analytics/components/Pagination";
const FANTOMBRIDGE = 112;
const ARBITRUMBRIDGE = 110;
const OPBRIDGE = 111;
const chains = [
  { chainId: FANTOMBRIDGE, name: "Fantom", img: icFantom, id: FANTOM },
  { chainId: OPBRIDGE, name: "Optimism", img: icOp, id: OP },
  { chainId: ARBITRUMBRIDGE, name: "Arbitrum", img: icArb, id: ARBITRUM },
];
function getChainL0(chainId) {
  switch (chainId) {
    case FANTOM:
      return FANTOMBRIDGE;
    case OP:
      return OPBRIDGE;
    case ARBITRUM:
      return ARBITRUMBRIDGE;
    default:
      return FANTOMBRIDGE;
  }
}
function getChainId(chainLzId) {
  switch (chainLzId) {
    case FANTOMBRIDGE:
      return FANTOM;
    case OPBRIDGE:
      return OP;
    case ARBITRUMBRIDGE:
      return ARBITRUM;
    default:
      return FANTOM;
  }
}
const Bridge = ({ setPendingTxns, pendingTxns, connectWallet }) => {
  const { active, library, account, chainId: waletChainId } = useWeb3React();
  // const account = "0xaa305f47225f9c49a51786012448f9928e94b936";
  const { chainId } = useChainId();
  const [isApproving, setIsApproving] = useState(false);
  const [fromChain, setFromChain] = useState(getChainL0(chainId));
  const [toChain, setToChain] = useState(
    chainId === OP ? FANTOMBRIDGE : chainId === ARBITRUM ? OPBRIDGE : ARBITRUMBRIDGE
  );
  const [historyData, setHistoryData] = useState([]);
  const [amount, setAmount] = useState("");
  const selectedFromChain = chains.find((x) => x.chainId === fromChain) || chains[0];
  const selectedToChain = chains.find((x) => x.chainId === toChain) || chains[2];
  const bridgeReaderAddress = getContract(chainId, "Bridge");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const MAX_NUMBER_PAGE = 70;
  const histories = useBridgeApi(account);

  const toChainList = chains.filter((item) => item.chainId !== fromChain);
  useEffect(() => {
    if (histories) {
      setHistoryData([...histories?.data?.rows]);
    } else setHistoryData([]);
  }, [histories]);
  useEffect(() => {
    let length = historyData.length;
    setRows(length);
  }, [historyData]);
  useEffect(() => {
    const oldFrom = fromChain;
    setFromChain(getChainL0(chainId));
    setToChain(
      oldFrom === getChainL0(chainId) ? (toChain === getChainL0(chainId) ? toChainList[0].chainId : toChain) : oldFrom
    );

    // setToChain(toChainTmp === toChainList[0].chainId ? toChainList[0].chainId : toChainTmp);
  }, [chainId, fromChain]);
  const getAmountValue = () => {
    if (!amount) return expandDecimals(0, 18);
    else {
      if (!amount.includes(".")) return expandDecimals(amount, 18);
      else {
        let after = amount.split(".")[1];
        return expandDecimals(amount.replace(".", ""), 18 - after.length);
      }
    }
  };
  const { data: estimateFee } = useSWR(
    [`Bridge:estSendFee:${[active, toChain]}`, chainId, bridgeReaderAddress, "estimateSendFee"],
    {
      fetcher: contractFetcher(library, BridgedReader, [toChain, account, getAmountValue(), false, "0x"]),
    }
  );

  const readerAddress = getContract(chainId, "Reader");
  const gmxAddress = getContract(chainId, "GMX");
  const walletTokens = [gmxAddress];
  const { data: walletBalances } = useSWR(
    [
      `Bridge:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );
  const { data: lzGMXBalances } = useSWR(
    [`Bridge:lzGMXBalances:${active}`, chainId, bridgeReaderAddress, "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Token, []),
    }
  );
  const { balanceData } = getBalanceAndSupplyData(walletBalances);
  let gmxBalance = balanceData?.gmx;
  if (chainId === OP) {
    gmxBalance = lzGMXBalances;
  }

  const switchChains = () => {
    switchNetwork(getChainId(toChain), active);
  };
  const onInputChange = (e) => {
    const value = e.target.value;
    if (value.includes(".")) {
      let after = value.split(".")[1];
      if (after.length <= 18) setAmount(value);
    } else {
      setAmount(value);
    }
  };
  const { data: tokenAllowance } = useSWR(
    active &&
      gmxAddress && [
        active,
        chainId,
        chainId === OP ? bridgeReaderAddress : gmxAddress,
        "allowance",
        account,
        bridgeReaderAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  const { AddressZero } = ethers.constants;

  let checkAmount = getAmountValue();
  const needApproval = gmxAddress !== AddressZero && tokenAllowance && checkAmount && checkAmount.gt(tokenAllowance);
  const ApproveTokens = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: chainId === OP ? bridgeReaderAddress : gmxAddress,
      spender: bridgeReaderAddress,
      chainId,
    });
  };
  const BridgeToken = () => {
    const contract = new ethers.Contract(bridgeReaderAddress, BridgedReader.abi, library.getSigner());
    // console.log("????", [account, toChain, account, getAmountValue(), AddressZero, account, "0x"]);
    callContract(
      chainId,
      contract,
      "sendFrom",
      [account, toChain, account, getAmountValue(), account, AddressZero, "0x"],
      {
        value: estimateFee?.nativeFee,
        successMsg: `Bridge completed`,
        sentMsg: `Bridge submitted!`,
        failMsg: `Bridge failed.`,
        setPendingTxns,
        pendingTxns,
      }
    );
  };
  const handleSelectDropDownFrom = (chainId) => {
    if (fromChain !== chainId) switchNetwork(getChainId(chainId), active);
  };
  const handleSelectDropDownTo = (chainId) => {
    setToChain(chainId);
  };
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const getBridgeContract = (selectedChainId) => {
    return getContract(chains.find((x) => x.chainId === selectedChainId).id, "Bridge");
  };
  const isBridgeDisabled = () => {
    if (!amount || !account) return true;
    const valueAmount = getAmountValue();
    if (valueAmount.gt(gmxBalance || 0)) return true;
    return false;
  };
  const handleMaxBalance = () => {
    if (gmxBalance && gmxBalance.gt(0)) setAmount(formatAmount(gmxBalance || 0, 18, 18, false));
    else setAmount("0.0000");
  };
  const getRecieveToken = () => {
    let result = amount;
    if (!amount) return "--";
    if (amount.includes(".")) {
      const after = amount.split(".")[1];
      if (after.length <= 4) {
        const gap = 4 - after.length;
        for (let i = 0; i < gap; i++) {
          result += "0";
        }
        return numberWithCommas(result);
      } else {
        const result = after.slice(0, 4);
        return numberWithCommas(amount.split(".")[0] + "." + result);
      }
    }
    return numberWithCommas(amount + ".0000");
  };

  const showData =
    historyData.length > 0
      ? historyData.slice((currentPage - 1) * rowsPerPage, Math.min(currentPage * rowsPerPage, historyData.length))
      : [];
  return (
    <Wrapper>
      <div className="bridge-wrapper">
        <div className="page-title">Bridge</div>
        <div className="page-desc">
          Bridging $MMY seamlessly and securely between Fantom Opera, Arbitrum and Optimism.
        </div>
        <div className="power-by">
          Powered by <img alt="" src={LayerZeroIcon} />
        </div>
        <div className="form-bridge">
          <div className="chains">
            <div className="chain-select-dropdown">
              <div className="dropdown-name">From</div>
              <Menu>
                <Menu.Button as="div">
                  <div className="selected-chain">
                    <div className="chain-info">
                      <img alt="" src={selectedFromChain.img} className="token-chain" />{" "}
                      <div className="chain-name">{selectedFromChain.name}</div>
                    </div>
                    <img alt="" src={IcDown} className="ic_down" />
                  </div>
                </Menu.Button>
                <div>
                  <Menu.Items as="div" className="menu-items">
                    {chains.map((item) => (
                      <Menu.Item key={item.chainId} onClick={() => handleSelectDropDownFrom(item.chainId)}>
                        <div className={`menu-item ${item.chainId === fromChain ? "menu-item--active" : ""}`}>
                          <img alt="" src={item.img} /> <div className="chain-name">{item.name}</div>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </div>
              </Menu>
            </div>
            <div className={`swap-ball`} onClick={switchChains}>
              <IoMdSwap className="swap-ball-icon" />
            </div>
            <div className="chain-select-dropdown">
              <div className="dropdown-name">To</div>
              <Menu>
                <Menu.Button as="div">
                  <div className="selected-chain">
                    <div className="chain-info">
                      <img alt="" src={selectedToChain.img} className="token-chain" />{" "}
                      <div className="chain-name">{selectedToChain.name}</div>
                    </div>
                    <img alt="" src={IcDown} className="ic_down" />
                  </div>
                </Menu.Button>
                <div>
                  <Menu.Items as="div" className="menu-items">
                    {toChainList.map((item) => (
                      <Menu.Item key={item.chainId} onClick={() => handleSelectDropDownTo(item.chainId)}>
                        <div className={`menu-item ${item.chainId === toChain ? "menu-item--active" : ""}`}>
                          <img alt="" src={item.img} /> <div className="chain-name">{item.name}</div>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </div>
              </Menu>
            </div>
          </div>
          <div className="transfer-amount">
            <div className="balance-info">
              <div>I want to transfer</div>
              <div className="balance" onClick={handleMaxBalance}>
                Balance: <span>{formatAmount(gmxBalance, 18, 4, true)}</span>
              </div>
            </div>
            <input placeholder="0.00" className="amount" type="number" value={amount} onChange={onInputChange} />
          </div>
          <div className="recieve-info">
            <div>
              You will receive: <span className="white-text">{getRecieveToken()} MMY</span>
            </div>
            <div>
              <Tooltip
                handle="Bridge fee:"
                position="left-bottom"
                renderContent={() => <div>Transaction Fees charged by LayerZero</div>}
              />{" "}
              <span className="white-text">
                {estimateFee ? formatAmount(estimateFee?.nativeFee, 18, 4, true) : "--"} {nativeTokenSymbol}
              </span>
            </div>
            {account && (
              <div className="reciever">
                <Tooltip
                  handle="Receiver:"
                  position="left-bottom"
                  renderContent={() => <div>This is the destination address of the network</div>}
                />{" "}
                <span>
                  <img alt="" src={selectedToChain.img} className="reciver-chain-name" style={{ marginRight: "4px" }} />
                  <ExternalLink
                    href={
                      toChain === ARBITRUMBRIDGE
                        ? `https://arbiscan.io/address/${account}`
                        : `https://ftmscan.com/address/${account}`
                    }
                  >
                    <span className="address-text">
                      {account} <img alt="" src={icScan} />
                    </span>
                  </ExternalLink>
                </span>
              </div>
            )}
          </div>
          <div className="actions">
            {!active ? (
              <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                Connect Wallet
              </button>
            ) : chainId !== FANTOM && chainId !== ARBITRUM && chainId !== OP ? (
              <button
                className="App-button-option App-card-option"
                onClick={() => switchNetwork(getChainId(selectedFromChain.chainId), active)}
              >
                Switch to {selectedFromChain.name}
              </button>
            ) : needApproval ? (
              <button className="App-button-option App-card-option" onClick={() => ApproveTokens()}>
                Approve
              </button>
            ) : (
              <button
                className="App-button-option App-card-option"
                disabled={isBridgeDisabled()}
                onClick={() => BridgeToken()}
              >
                Bridge
              </button>
            )}
          </div>
        </div>
        <div className="down-content">
          <div className="page-title">Transaction History</div>
          <div className="transaction-table">
            <div className="transaction-line transaction-header">
              <div className="table-column">From</div>
              <div className="table-column">To</div>
              <div className="table-column">Amount</div>
              <div className="table-column">Status</div>
              <div className="table-column column-view">View</div>
            </div>
            <div className="transaction-content">
              {!historyData || (historyData.length === 0 && <div className="no-data">No transactions data</div>)}
              {historyData &&
                showData.map((item) => (
                  <div className="transaction-line transaction-row" key={item.fromTx}>
                    <div className="table-column">
                      {/* <img alt="" src={item?.fromChainId === FANTOMBRIDGE ? icFantom : icArb} />
                      <div>{item?.fromChainId === FANTOMBRIDGE ? "Fantom" : "Arbitrum"}</div> */}
                      {item?.fromChainId === FANTOMBRIDGE && (
                        <>
                          <img alt="" src={icFantom} />
                          <div>{"Fantom"}</div>
                        </>
                      )}
                      {item?.fromChainId === OPBRIDGE && (
                        <>
                          <img alt="" src={icOp} />
                          <div>{"Optimism"}</div>
                        </>
                      )}
                      {item?.fromChainId === ARBITRUMBRIDGE && (
                        <>
                          <img alt="" src={icArb} />
                          <div>{"Arbitrum"}</div>
                        </>
                      )}
                    </div>
                    <div className="table-column">
                      {item?.toChainId === FANTOMBRIDGE && (
                        <>
                          <img alt="" src={icFantom} />
                          <div>{"Fantom"}</div>
                        </>
                      )}
                      {item?.toChainId === OPBRIDGE && (
                        <>
                          <img alt="" src={icOp} />
                          <div>{"Optimism"}</div>
                        </>
                      )}
                      {item?.toChainId === ARBITRUMBRIDGE && (
                        <>
                          <img alt="" src={icArb} />
                          <div>{"Arbitrum"}</div>
                        </>
                      )}
                    </div>
                    <div className="table-column">{formatAmount(item?.amount, 18, 4, true)} MMY</div>
                    <div className={`table-column ${item?.status.toLowerCase()}`}>{item?.status.toLowerCase()}</div>
                    <div className="table-column column-view">
                      <ExternalLink
                        href={`https://layerzeroscan.com/${item.fromChainId}/address/${getBridgeContract(
                          item.fromChainId
                        ).toLowerCase()}/message/${item.toChainId}/address/${getBridgeContract(
                          item.toChainId
                        ).toLowerCase()}/nonce/${item?.nonce}`}
                      >
                        <img alt="" src={icScanWhite} className="icon-view" />
                      </ExternalLink>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="transaction-table-mobile">
            {!historyData || (historyData.length === 0 && <div className="no-data">No transactions data</div>)}
            {historyData &&
              showData.map((item) => (
                <div className="table-mobile-content" key={item.fromTx}>
                  <div className="content-line">
                    <div className="title">From</div>
                    <div className="value">
                      {/* <img alt="" src={item?.fromChainId === FANTOMBRIDGE ? icFantom : icArb} />
                      <div>{item?.fromChainId === FANTOMBRIDGE ? "Fantom" : "Arbitrum"}</div> */}
                      {item?.fromChainId === FANTOMBRIDGE && (
                        <>
                          <img alt="" src={icFantom} />
                          <div>{"Fantom"}</div>
                        </>
                      )}
                      {item?.fromChainId === OPBRIDGE && (
                        <>
                          <img alt="" src={icOp} />
                          <div>{"Optimism"}</div>
                        </>
                      )}
                      {item?.fromChainId === ARBITRUMBRIDGE && (
                        <>
                          <img alt="" src={icArb} />
                          <div>{"Arbitrum"}</div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="content-line">
                    <div className="title">To</div>
                    <div className="value">
                      {/* <img alt="" src={item?.toChainId === FANTOMBRIDGE ? icFantom : icArb} />
                      <div>{item?.toChainId === FANTOMBRIDGE ? "Fantom" : "Arbitrum"}</div> */}
                      {item?.toChainId === FANTOMBRIDGE && (
                        <>
                          <img alt="" src={icFantom} />
                          <div>{"Fantom"}</div>
                        </>
                      )}
                      {item?.toChainId === OPBRIDGE && (
                        <>
                          <img alt="" src={icOp} />
                          <div>{"Optimism"}</div>
                        </>
                      )}
                      {item?.toChainId === ARBITRUMBRIDGE && (
                        <>
                          <img alt="" src={icArb} />
                          <div>{"Arbitrum"}</div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="content-line">
                    <div className="title">Amount</div>
                    <div className="value">{formatAmount(item?.amount, 18, 4, true)} MMY</div>
                  </div>
                  <div className="content-line">
                    <div className="title">Status</div>
                    <div className={`value ${item?.status.toLowerCase()}`}>{item?.status.toLowerCase()}</div>
                  </div>
                  <div className="content-line">
                    <div className="title">View</div>
                    <div className="value">
                      <ExternalLink
                        href={`https://layerzeroscan.com/${item.fromChainId}/address/${getBridgeContract(
                          item.fromChainId
                        ).toLowerCase()}/message/${item.toChainId}/address/${getBridgeContract(
                          item.toChainId
                        ).toLowerCase()}/nonce/${item?.nonce}`}
                      >
                        <img alt="" src={icScanWhite} className="icon-view" />
                      </ExternalLink>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {historyData && historyData.length > 0 && (
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
                maxNumberOfPage={MAX_NUMBER_PAGE}
                row={row}
              />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </Wrapper>
  );
};
export default Bridge;
const Wrapper = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;
`;
