import { useState, useEffect, useCallback } from "react";
import useSWR from "swr";
import { Trans, t } from "@lingui/macro";
import { ethers } from "ethers";
import styled from "styled-components";
import { callContract } from "lib/contracts";

import ExternalLink from "components/ExternalLink/ExternalLink";
import Tab from "components/Tab/Tab";
import { approveTokens } from "domain/tokens";
import { formatNumber, parseValue } from "lib/numbers";
import { helperToast } from "lib/helperToast";
import { getExplorerUrl } from "config/chains";

import { StyledVaultAction } from "./index.style";

import { MMY_VAULT, MLP_VAULT, MMY_WFTM_OLD_VAULT, MMY_WFTM_VAULT, MMY_WETH_VAULT } from "./index";

import gmxIcon from "img/ic_mmy_custom.svg";
import mlpIcon from "img/ic_mlp_40.svg";
import MMY_FTM from "img/MMY-FTM.svg";
import MMY_ETH from "img/MMY-ETH.svg";
import icLink from "img/icons/link-green.svg";
import Modal from "components/Modal/Modal";
import getImageChainMini from "./getImageChainMini";

export const DEPOSIT = "Deposit";
export const WITHDRAW = "Withdraw";

const INVALID_LIST_INPUT = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ".",
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
];

const TAB_OPTIONS = [DEPOSIT, WITHDRAW];

const StyledTab = styled(Tab)`
  display: flex;
  height: 45px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;

  > div {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 1;
  }
`;

const VaultAction = ({
  active,
  library,
  balance,
  value,
  setValue,
  account,
  connectWallet,
  currentActiveTab,
  chainId,
  Token,
  currentVaultAddress,
  NewVaultJson,
  contractFetcher,
  amountTokenDeposit,
  rateWithdraw,
  setPendingTxns,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [flagValue, setFlagValue] = useState(balance);
  const TAB_OPTION_LABELS = { [DEPOSIT]: `Deposit`, [WITHDRAW]: `Withdraw` };
  const [activeTab, setActiveTab] = useState(DEPOSIT);
  const [isApproving, setIsApproving] = useState(false);

  let currentTokenName = "";
  let imgToken = <img src={currentActiveTab === MMY_VAULT ? gmxIcon : mlpIcon} alt="mummy" />;

  switch (currentActiveTab) {
    case MMY_VAULT:
      currentTokenName = "MMY";
      break;
    case MLP_VAULT:
      currentTokenName = "MLP";
      break;

    case MMY_WFTM_OLD_VAULT:
      currentTokenName = "MMY-WFTM";
      imgToken = <img src={MMY_FTM} alt="mummy" />;
      break;

    case MMY_WFTM_VAULT:
      currentTokenName = "MMY-WFTM";
      imgToken = <img src={MMY_FTM} alt="mummy" />;
      break;

    case MMY_WETH_VAULT:
      currentTokenName = "MMY-WETH";
      imgToken = <img src={MMY_ETH} alt="mummy" />;
      break;

    default:
      currentTokenName = "MMY";
      imgToken = <img src={currentActiveTab === MMY_VAULT ? gmxIcon : mlpIcon} alt="mummy" />;
      break;
  }

  const { data: addressTokenStake } = useSWR(
    [`NewVault:addressTokenStake:${currentTokenName}`, chainId, currentVaultAddress, "want"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const stakingTokenAddress = addressTokenStake || "0x104d0312f95a1b3056435d19668f2272ab1e7df2"; // FANTOM || OP

  const { data: tokenAllowance, mutate: updateTokenAllowance } = useSWR(
    [active, chainId, stakingTokenAddress, "allowance", account, currentVaultAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  useEffect(() => {
    setFlagValue(activeTab === DEPOSIT ? balance : amountTokenDeposit);
  }, [balance, amountTokenDeposit, activeTab]);

  useEffect(() => {
    if (active) {
      library.on("block", () => {
        updateTokenAllowance(undefined, true);
      });
      return () => {
        library.removeAllListeners("block");
      };
    }
  }, [active, library, updateTokenAllowance]);

  const needApproval = (tokenAllowance || 0).toString() === "0";

  // console.log("test", tokenAllowance, addressTokenStake);

  const getPrimaryText = useCallback(() => {
    if (flagValue === "...") {
      return `Loading ...`;
    }

    if (flagValue === "0") {
      if (activeTab === DEPOSIT) {
        return `Deposit`;
      }

      return `Withdraw`;
    }

    if (isApproving && activeTab === DEPOSIT) {
      return `Approving ${currentTokenName}...`;
    }

    if (needApproval && activeTab === DEPOSIT) {
      return `Approve ${currentTokenName}`;
    }

    if (activeTab === DEPOSIT) {
      return `Deposit`;
    }

    return `Withdraw`;
  }, [flagValue, isApproving, needApproval, activeTab, currentTokenName]);

  const handleChangeValue = (event) => {
    const eventValue = event.target.value;
    if (Number(eventValue) < 0) return;

    if (eventValue.includes(".") && eventValue.split(".")[1].length > 18) {
      return;
    }

    setValue(eventValue);
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: currentVaultAddress,
        chainId,
      });
      return;
    }

    if (activeTab === DEPOSIT) {
      if (currentActiveTab === MMY_VAULT || currentActiveTab === MLP_VAULT) {
        setIsVisible(true);
      } else {
        handleDeposit();
      }
    } else {
      let amount = parseValue(value, 18);

      const contract = new ethers.Contract(currentVaultAddress, NewVaultJson.abi, library.getSigner());

      callContract(chainId, contract, "withdraw", [amount], {
        sentMsg: `Withdraw submitted!`,
        failMsg: `Withdraw failed.`,
        setPendingTxns,
      }).then(async (res) => {
        setValue("");
      });
    }
  };

  function renderCurrentTab() {
    return (
      <>
        <div className="your-balance">
          <div className="left">Avail: {formatNumber(flagValue, 4)}</div>
        </div>
      </>
    );
  }

  useEffect(() => {
    setValue("");
  }, [currentActiveTab, account, setValue]);

  const handleChangeTab = (currentActiveTab) => {
    setValue("");
    setFlagValue(currentActiveTab === DEPOSIT ? balance : amountTokenDeposit);
  };

  const handleDeposit = () => {
    let amount = parseValue(value, 18);
    const contract = new ethers.Contract(currentVaultAddress, NewVaultJson.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed.`,
      setPendingTxns,
    }).then(async (res) => {
      setValue("");
      setIsVisible(false);
    });
  };

  return (
    <StyledVaultAction>
      <ConfirmModal isVisible={isVisible} setIsVisible={setIsVisible} label={"Confirm deposit"} allowContentTouchMove>
        <div className="centent">
          Disclaimer: The esMMY APY reflects the rewards used to compound for more FTM, esMMY, and MP and will not be
          directly calculated when withdrawing tokens.
        </div>
        <div className="footer">
          <button className="cancel" onClick={() => setIsVisible(false)}>
            Cancel
          </button>
          <button className="btn-deposit" onClick={handleDeposit}>
            Deposit
          </button>
        </div>
      </ConfirmModal>
      <div className="Exchange-swap-box-inner App-box-highlight vault-v2">
        <StyledTab
          options={TAB_OPTIONS}
          optionLabels={TAB_OPTION_LABELS}
          option={activeTab}
          setOption={setActiveTab}
          onChange={handleChangeTab}
          isBoldFontWeight={true}
        />

        <div className="total-wrapper-action">
          <div className="Available">
            <span
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(255, 255, 255, 0.6)",
              }}
            >
              Amount
            </span>
            {renderCurrentTab()}
          </div>

          <div className="wrapper-input">
            <div className="input-balance">
              <input
                onKeyDown={(e) => {
                  !INVALID_LIST_INPUT.includes(e.key) && e.preventDefault();

                  (String(value) || "").includes(".") && e.key === "." && e.preventDefault();
                }}
                placeholder="0.00"
                value={value}
                onChange={handleChangeValue}
              />
            </div>

            <div
              style={{
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}
            >
              <div className="max-btn" onClick={() => setValue(activeTab === DEPOSIT ? balance : amountTokenDeposit)}>
                MAX
              </div>

              <div
                style={{
                  marginRight: 0,
                }}
                className="App-card-title-mark-icon"
              >
                {imgToken}
                {[MMY_VAULT, MLP_VAULT].includes(currentActiveTab) && getImageChainMini(chainId)}
              </div>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: 140,

                  color: "#FFFFFF",
                }}
              />
              {currentTokenName}
              <span />
            </div>
          </div>
        </div>

        {currentActiveTab === MMY_VAULT ? (
          <StyledExternalLink href="https://app.mummy.finance/#/buy_mmy">
            Buy MMY <img src={icLink} alt="exportIcon" />
          </StyledExternalLink>
        ) : currentActiveTab === MLP_VAULT ? (
          <StyledExternalLink href="https://app.mummy.finance/#/buy_mlp">
            Buy MLP <img src={icLink} alt="exportIcon" />
          </StyledExternalLink>
        ) : [MMY_WFTM_OLD_VAULT, MMY_WFTM_VAULT].includes(currentActiveTab) ? (
          <StyledExternalLink href="https://equalizer.exchange/liquidity/0xdc935ffe9f9c972b7b304e0b7e61eb774037e394">
            Add liquidity <img src={icLink} alt="exportIcon" />
          </StyledExternalLink>
        ) : (
          <StyledExternalLink href="https://app.velodrome.finance/liquidity/manage?address=0x104d0312f95a1b3056435d19668f2272ab1e7df2">
            Add liquidity <img src={icLink} alt="exportIcon" />
          </StyledExternalLink>
        )}

        {activeTab === WITHDRAW ? (
          <div
            style={{
              color: "rgba(255,255,255,0.6)",
              marginBottom: 20,
            }}
          >
            Est. receive:{" "}
            <span
              style={{
                color: "#ffffff",
              }}
            >
              {formatNumber((Number(value) - Number(value) * (0.1 / 100)) * rateWithdraw, 4)} {currentTokenName}
            </span>
          </div>
        ) : null}

        {account ? (
          <button
            // disabled={["", "0", "0.0", "0.00"].includes(value) && !needApproval}
            disabled={
              flagValue === "0" ||
              flagValue === "..." ||
              (!needApproval && !value) ||
              (!needApproval && Number(value) > Number(flagValue)) ||
              (!needApproval && ["", "0", "0.0", "0.00"].includes(value)) ||
              (currentActiveTab === MMY_WFTM_OLD_VAULT && activeTab === DEPOSIT)
            }
            className="btn-deposit default-btn"
            onClick={onClickPrimary}
          >
            {getPrimaryText()}
          </button>
        ) : (
          <button className="btn-deposit default-btn" onClick={() => connectWallet()}>
            Connect Wallet
          </button>
        )}
      </div>

      <div className="perfomance-fee">
        <div className="deposit-withdraw-fee">
          <div>
            Deposit fee
            <span>0%</span>
          </div>

          <div>
            Withdraw fee
            <span>0.1%</span>
          </div>
        </div>
        <div className="label">Performance fee</div>

        <div className="percent">4%</div>

        <div className="description">Performance fee is already subtracted from the displayed APY.</div>
      </div>
    </StyledVaultAction>
  );
};

const ConfirmModal = styled(Modal)`
  width: 500px;
  margin: 0 auto;

  @media (max-width: 600px) {
    width: calc(100% - 32px);
  }

  .footer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      border: none;

      padding: 8px 16px;
      gap: 4px;

      width: 376px;
      height: 40px;

      background: #03f5ae;
      border-radius: 4px;

      font-weight: 500;
      font-size: 14px;
      line-height: 140%;

      color: #080715;

      &.cancel {
        background-color: transparent;
        border: 1px solid #03f5ae;
        color: #03f5ae;
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #03f5ae;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
`;

export default VaultAction;
